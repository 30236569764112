<section class="settings-main settings-account">
  <div class="settings-wrapper">
    <form class="settings-form">
      <ng-template [ngIf]="status == 'form'">
        <div class="personalia-item personalia-item--error" *ngIf="new_user.errors.length > 0">
          <ul>
            <li *ngFor="let error of new_user.errors">{{ error }}</li>
          </ul>
        </div>
        <div class="personalia-item">
          <div class="settings-form__item">
            <h2>Account opzetten</h2>
            <h3>Vul uw account hier aan</h3>
          </div>
          <div class="settings-form__item settings-form__item--locked">
            <p>E-mailadres</p>
            <input type="text" placeholder="{{ new_user.user.email }}" class="login-input" disabled>
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_user.error_fields.includes('password')">
            <p>Wachtwoord*</p>
            <input type="password" placeholder="Vul hier uw nieuwe wachtwoord in" class="login-input" #password>
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_user.error_fields.includes('password_confirmation')">
            <p>Herhaal wachtwoord*</p>
            <input type="password" placeholder="Vul hier opnieuw uw nieuwe wachtwoord in" class="login-input" #password_confirmation>
          </div>
        </div>
        <div class="personalia-item">
          <div class="settings-form__item">
            <h2>Persoonlijke Gegevens</h2>
            <h3>Onderstaande gegevens worden gebruikt om uw studiepunten aan te melden en uw diploma te genereren.</h3>
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_user.error_fields.includes('title')">
            <p>Aanhef*</p>
            <select name="title" [(ngModel)]="new_user.user.title">
              <option value="" disabled>Kies uw aanhef</option>
              <option value="Dhr.">Dhr.</option>
              <option value="Mevr.">Mevr.</option>
            </select>
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_user.error_fields.includes('initials')">
            <p>Voorletters*</p>
            <input type="text" name="initials" placeholder="{{ new_user.user.initials }}" class="login-input" [(ngModel)]="new_user.user.initials">
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_user.error_fields.includes('last_name')">
            <p>Achternaam*</p>
            <input type="text" name="last_name" placeholder="{{ new_user.user.last_name }}" class="login-input" [(ngModel)]="new_user.user.last_name">
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_user.error_fields.includes('birthday')">
            <p>Geboortedatum*</p>
            <ngx-datepicker name="birthday" [(ngModel)]="new_user.user.birthday" [options]="datepicker_options"></ngx-datepicker>
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_user.error_fields.includes('registration_kind')">
            <p>V&VN of ADAP accreditatie*</p>
            <select name="registration_kind" [(ngModel)]="new_user.user.registration_kind">
              <option value="unregistered">Ik wil geen studiepunten ontvangen</option>
              <option value="vnvn">Ik wil studiepunten ontvangen van V&VN</option>
              <option value="adap">Ik wil studiepunten ontvangen van ADAP</option>
            </select>
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_user.error_fields.includes('registration_number')" *ngIf="new_user.user.registration_kind == 'vnvn'">
            <p>BIG of V&VN Registratienummer</p>
            <input type="text" name="registration_number" placeholder="Vul uw BIG of V&VN registratienummer in" class="login-input" [(ngModel)]="new_user.user.registration_number">
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_user.error_fields.includes('registration_number')" *ngIf="new_user.user.registration_kind == 'adap'">
            <p>KP Registratienummer</p>
            <input type="text" name="registration_number" placeholder="Vul uw KP registratienummer in" class="login-input" [(ngModel)]="new_user.user.registration_number">
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_user.error_fields.includes('terms')">
            <div class="settings-form__checkbox">
              <input type="checkbox" id="terms" name="terms" [(ngModel)]="new_user.user.terms">
              <label for="terms"></label>
              <p>Gaat u akkoord met de <a (click)="goToConditions()">algemene voorwaarden?</a></p>
            </div>
          </div>
          <div class="settings-form__item">
            <input type="submit" value="opslaan" (click)="newUser(password.value, password_confirmation.value)">
          </div>
        </div>
      </ng-template>
      <div class="personalia-item" *ngIf="status == 'success'">
        <div class="settings-form__item">
          <h2>Account opgezet</h2>
          <h3>Uw account is succesvol opgezet. Klik <a (click)="goToDashboard()">hier</a> om naar uw dashboard te gaan.</h3>
        </div>
      </div>
    </form>
  </div>
</section>
