import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfirmModalState } from './confirm-modal.state';
import { ConfirmModalStore } from './confirm-modal.store';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();
  public state: ConfirmModalState = new ConfirmModalState();

  constructor(private confirmModalStore: ConfirmModalStore) { }

  ngOnInit(): void {

    this.subscriptions.add(

      this.confirmModalStore.state$
        .subscribe(
          state => {
            this.state = state;
          }
        )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  closeModal() {
    this.confirmModalStore.close();
  }
}
