export enum DiplomaStatus {

  UNAVAILABLE = 'unavailable',
  PENDING = 'pending',
  AVAILABLE = 'available'
};

export class AccountSubscription {

  id: number;
  course: string;
  percentage: number;
  diploma: DiplomaStatus;
  done: boolean;
  remaining_days: number = 0;
}

export class AccountState {

  action: string;
  errors: string[];
  error_fields: string[] = [];
  subscriptions: AccountSubscription[];
};
