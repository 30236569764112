// CORE ANGULAR
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// locale
import '@angular/common/locales/global/nl';

// HOTKEYS
import { HotkeyModule } from 'angular2-hotkeys';

// DATEPICKER
import { DatepickerModule } from 'ng2-datepicker';

// CONNECTION MONITOR
import { ConnectionServiceModule, ConnectionServiceOptions, ConnectionServiceOptionsToken } from 'ngx-connection-service';

// APP MODULES
import { AppRoutingModule } from './app-routing.module';
import { AppSharedModule } from './shared/app-shared.module';

// APP COMPONENTS
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TreatmentsComponent } from './treatments/treatments.component';
import { TreatmentComponent } from './treatment/treatment.component';
import { ReaderComponent } from './reader/reader.component';
import { QuizComponent } from './quiz/quiz.component';
import { ExamComponent } from './exam/exam.component';
import { CaseComponent } from './case/case.component';
import { TimerComponent } from './timer/timer.component';
import { AccountComponent } from './account/account.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SettingsComponent } from './settings/settings.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { StaticPageComponent } from './static-page/static-page.component';
import { NewOwnerComponent } from './new-owner/new-owner.component';
import { NewUserComponent } from './new-user/new-user.component';
import { CompanyComponent } from './company/company.component';
import { CompanyNewUserComponent } from './company-new-user/company-new-user.component';
import { CompanyEditUserComponent } from './company-edit-user/company-edit-user.component';
import { CompanyAccountComponent } from './company-account/company-account.component';
import { LoadingComponent } from './loading/loading.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ConnectionLostComponent } from './connection-lost/connection-lost.component';
import { ActiveExamModalComponent } from './active-exam-modal/active-exam-modal.component';

// APP STORES
import { DashboardStore } from './dashboard/dashboard.store';
import { ReaderStore } from './reader/reader.store';
import { SlidersStore } from './shared/stores/sliders.store';
import { SplashStore } from './shared/stores/splash.store';
import { UserStore } from './shared/stores/user.store';
import { CaseStore } from './case/case.store';
import { QuizStore } from './quiz/quiz.store';
import { TreatmentStore } from './treatment/treatment.store';
import { ResetPasswordStore } from './reset-password/reset-password.store';
import { SetPasswordStore } from './set-password/set-password.store';
import { StaticPageStore } from './static-page/static-page.store';
import { AccountStore } from './account/account.store';
import { NewOwnerStore } from './new-owner/new-owner.store';
import { NewUserStore } from './new-user/new-user.store';
import { SettingsStore } from './settings/settings.store';
import { CompanyStore } from './company/company.store';
import { CompanyNewUserStore } from './company-new-user/company-new-user.store';
import { NotificationStore } from './shared/stores/notification.store';
import { CompanyEditUserStore } from './company-edit-user/company-edit-user.store';
import { CompanyAccountStore } from './company-account/company-account.store';
import { TreatmentsStore } from './treatments/treatments.store';
import { LoadingStore } from './loading/loading.store';
import { ModalStore } from './shared/stores/modal.store';
import { ActiveExamModalStore } from './active-exam-modal/active-exam-modal.store';
import { ConnectionStore } from './connection-lost/connection.store';

// APP PIPES
import { SafeHtmlPipe } from './shared/pipes/safe_html.pipe';
import { FormatTimePipe } from './shared/pipes/format-time.pipe';

// APP GUARDS
import { CompanyAuthenticatedGuard } from './shared/guards/company-authenticated.guard';
import { AppAuthenticatedGuard } from './shared/guards/app-authenticated.guard';
import { GeneralAuthenticatedGuard } from './shared/guards/general-authenticated.guard';
import { NotAuthenticatedGuard } from './shared/guards/not-authenticated.guard';
import { ForceNotAuthenticatedGuard } from './shared/guards/force-not-authenticated.guard';
import { RouteNotFoundGuard } from './shared/guards/route-not-found.guard';

// APP SERVICES
import { NotificationService } from './shared/services/notification.service';

// ENV
import { environment } from 'src/environments/environment';
import { SocketService } from './shared/services/socket.service';
import { ApmAppService } from './shared/services/apm_app.service';
import { ApmErrorHandler } from './shared/services/apm_error_handler';
import { FormatDatePipe } from './shared/pipes/format-date.pipe';
import { UnsupportedBrowserComponent } from './unsupported-browser/unsupported-browser.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ConfirmModalStore } from './confirm-modal/confirm-modal.store';
import { Nl2brPipe } from './shared/pipes/nl2br.pipe';
import { ExpiredSubscriptionsModalComponent } from './expired-subscriptions-modal/expired-subscriptions-modal.component';
import { ExpiredSubscriptionsModalStore } from './expired-subscriptions-modal/expired-subscriptions-modal.store';
import { NotificationFilterPipe } from './shared/pipes/notification-filter.pipe';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    TreatmentsComponent,
    TreatmentComponent,
    ReaderComponent,
    QuizComponent,
    ExamComponent,
    CaseComponent,
    TimerComponent,
    SafeHtmlPipe,
    AccountComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SettingsComponent,
    SetPasswordComponent,
    StaticPageComponent,
    NewOwnerComponent,
    NewUserComponent,
    CompanyComponent,
    CompanyNewUserComponent,
    CompanyEditUserComponent,
    CompanyAccountComponent,
    LoadingComponent,
    NotificationsComponent,
    ConnectionLostComponent,
    ActiveExamModalComponent,
    FormatTimePipe,
    FormatDatePipe,
    UnsupportedBrowserComponent,
    ConfirmModalComponent,
    Nl2brPipe,
    ExpiredSubscriptionsModalComponent,
    NotificationFilterPipe,
  ],
  imports: [
    BrowserModule,
    DatepickerModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HotkeyModule.forRoot(),
    ConnectionServiceModule,
    AppSharedModule,
    AppRoutingModule,
  ],
  providers: [
    //APM
    ApmAppService, // apm service containing instance to interact with apm service from package
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler,
    },

    // guards
    CompanyAuthenticatedGuard,
    AppAuthenticatedGuard,
    GeneralAuthenticatedGuard,
    NotAuthenticatedGuard,
    ForceNotAuthenticatedGuard,
    RouteNotFoundGuard,

    // services
    NotificationService,
    SocketService,

    // stores
    UserStore,
    DashboardStore,
    ReaderStore,
    CaseStore,
    QuizStore,
    SlidersStore,
    TreatmentsStore,
    TreatmentStore,
    SplashStore,
    ResetPasswordStore,
    SetPasswordStore,
    StaticPageStore,
    AccountStore,
    NewOwnerStore,
    NewUserStore,
    SettingsStore,
    NotificationStore,
    CompanyStore,
    CompanyNewUserStore,
    CompanyEditUserStore,
    CompanyAccountStore,
    LoadingStore,
    ModalStore,
    ConnectionStore,
    ActiveExamModalStore,
    ConfirmModalStore,
    ExpiredSubscriptionsModalStore,

    // vendors
    { provide: LOCALE_ID, useValue: 'nl'},
    {
      provide: ConnectionServiceOptionsToken,
      useValue: <ConnectionServiceOptions>{
        enableHeartbeat: true,
        heartbeatInterval: 60000,
        heartbeatUrl: environment.heart_beat_url,
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
