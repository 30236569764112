<div class="nav-bar">
  <div class="nav-bar-section-wrapper">
    <section class="nav-bar-menu">
      <a class="nav-icon nav-icon--qcare"></a>
      <div class="button-container" *ngIf="showDashboardLinks()">
        <a class="nav-text" (click)="goToDashboard()">Lesmodules</a>
        <a class="nav-text" (click)="goToTreatments()">Naslagwerk</a>
      </div>
      <div class="button-container">
        <a class="nav-icon nav-icon--account" (click)="goToAccount()"></a>
        <a class="nav-icon nav-icon--settings nav-icon--settings--active"></a>
      </div>
    </section>
  </div>
</div>
<section class="settings-main settings-application" *ngIf="settings">
  <div class="settings-wrapper">
    <a class="settings-item" (click)="logout()">
      <span>Uitloggen</span>
    </a>
    <a class="settings-item" (click)="goToSetPassword()">
      <span>Wachtwoord wijzigen</span>
    </a>
    <a class="settings-item" (click)="goToCompany()" *ngIf="showCompanyLink()">
      <span>Bedrijfsportal</span>
    </a>
    <a class="settings-item" *ngFor="let page of getStaticPages()" (click)="goToPage(page)">
      <span>{{ page.title }}</span>
    </a>
  </div>
</section>
