<div class="nav-bar">
  <div class="nav-bar-section-wrapper">
    <section class="nav-bar-menu">
      <div class="button-container">
        <a class="nav-icon nav-icon--border nav-icon--menu" (click)="goToDashboard()"></a>
      </div>
      <div class="button-container">
        <a class="nav-icon nav-icon--account" (click)="goToAccount()"></a>
        <a class="nav-icon nav-icon--settings" (click)="goToSettings()"></a>
      </div>
    </section>
  </div>
</div>
<section class="settings-main">
  <div class="settings-wrapper">
    <form class="settings-form">
      <div class="personalia-item" *ngIf="status == 'form'">
        <div class="settings-form__item">
          <h2>Wachtwoord Instellen</h2>
          <h3>Vul hier een wachtwoord in waarmee u in wilt loggen.</h3>
          <p *ngFor="let error of errors">{{ error }}</p>
        </div>
        <div class="settings-form__item settings-form__item--locked">
          <p>E-mailadres</p>
          <input type="text" placeholder="{{ email }}" class="login-input" disabled>
        </div>
        <div class="settings-form__item">
          <p>Wachtwoord*</p>
          <input type="password" placeholder="Vul hier uw nieuwe wachtwoord in" class="login-input" #password>
        </div>
        <div class="settings-form__item">
          <p>Herhaal wachtwoord*</p>
          <input type="password" placeholder="Vul hier opnieuw uw nieuwe wachtwoord in" class="login-input" #password_confirmation>
        </div>
        <div class="settings-form__item ">
          <input type="submit" value="opslaan" (click)="setPassword(password.value, password_confirmation.value)" *ngIf="showConfirmButton()">
          <input type="submit" value="bezig met aanpassen" *ngIf="!showConfirmButton()">
        </div>
      </div>
      <div class="personalia-item" *ngIf="status == 'success'">
        <div class="settings-form__item">
          <h2>Wachtwoord gewijzigd</h2>
          <h3>Uw wachtwoord is succesvol gewijzigd. Klik <a (click)="goToDashboard()">hier</a> om naar uw dashboard te gaan.</h3>
        </div>
      </div>
    </form>
  </div>
</section>
