import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class StaticPageService extends ApiService {

  fetch(slug: string) {
    return this.get('/static_pages/' + slug);
  }
}
