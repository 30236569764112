import { Component, OnInit, OnDestroy } from '@angular/core';
import { TransitionService } from '../shared/services/transition.service';
import { CompanyAccountStore } from './company-account.store';
import { CompanyAccountState } from './company-account.state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-company-account',
  templateUrl: './company-account.component.html',
  styleUrls: ['./company-account.component.scss']
})
export class CompanyAccountComponent implements OnInit, OnDestroy {

  account: CompanyAccountState = null;
  loading: boolean             = false;
  saved: boolean               = false;
  errors: string[]             = [];

  private subscriptions: Subscription = new Subscription();

  constructor(private transitionService: TransitionService, private companyAccountStore: CompanyAccountStore) {}

  ngOnInit() {

    this.subscriptions.add(

      this.companyAccountStore.state$.subscribe(data => {

        if (data.action === 'initial') {

          this.account = data;
          this.loading = false;
          this.errors  = [];
          this.saved   = false;
        }

        if (data.action === 'saved-company-account') {

          this.account = data;
          this.loading = false;
          this.errors  = [];
          this.saved   = true;
        }

        if (data.action === 'error') {

          this.account = data;
          this.loading = false;
          this.errors  = data.errors;
          this.saved   = false;
        }
      })
    );

    this.companyAccountStore.fetch();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  goToCompany() {

    return this.transitionService
      .navigate(['company']);
  }

  goToSettings() {

    return this.transitionService
      .navigate(['settings']);
  }

  showConfirmButton() {
    return false === this.loading;
  }

  showAccountPage() {
    return null !== this.account;
  }

  accountSaved() {
    return true === this.saved;
  }

  saveAccount() {

    this.loading = true;
    this.companyAccountStore.save(this.account.company, this.account.user);
  }
}
