import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ActiveExamModalStore } from 'src/app/active-exam-modal/active-exam-modal.store';
import { ExpiredSubscriptionsModalStore } from 'src/app/expired-subscriptions-modal/expired-subscriptions-modal.store';
import { UserStore } from '../stores/user.store';

@Injectable()
export class GeneralAuthenticatedGuard implements CanActivate {

  constructor(private router : Router, private userStore: UserStore, private activeExamModalStore: ActiveExamModalStore, private expiredSubscriptionsModalStore: ExpiredSubscriptionsModalStore) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return new Observable<boolean>(observer => {

      // every time route changes, refetch expired subscriptions
      this.expiredSubscriptionsModalStore.fetch();

      // every time route changes, refetch active exam
      this.activeExamModalStore.fetch();

      this.userStore.state$.subscribe(data => {

        if (data.action === 'set-user') {
          return observer.next(data.authenticated === true);
        }

        if (data.action === 'not-authenticated') {

          this.router.navigate(['']);
          observer.next(false);
        }

        if (data.action === 'authenticated') {

          // and continue route
          observer.next(true);
        }
      });
    });
  }
}
