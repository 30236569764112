import { QuestionState } from "./question.state";
import { AnswerState } from "./answer.state";
import { ResultState } from "./result.state";

export class ExamState {

  action: string;
  restoring_action: string = null;
  remaining_tries: number;
  remaining_time: number;
  slide: number;
  slides: number;
  confirmed: boolean;
  question: QuestionState;
  result: ResultState;
  answers: {

    question: QuestionState;
    answers: AnswerState[];
  }[];

  exam: {
    id: number;
    course_id: number;
    course_module_id: number;
    rank: number;
    duration_hours: number;
    duration_minutes: number;
    questions: QuestionState[];
    created_at: Date;
    updated_at: Date;
  };
}
