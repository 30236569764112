import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { TransitionService } from '../shared/services/transition.service';
import { ProgressService } from '../shared/services/progress.service';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { QuizSliderService } from './quiz_slider.service';
import { QuizState } from './states/quiz.state';
import { QuizStore } from './quiz.store';
import { QuestionState } from './states/question.state';
import { AnswerState } from './states/answer.state';
import { LoadingStore } from '../loading/loading.store';
import { environment } from 'src/environments/environment';
import { UserStore } from '../shared/stores/user.store';
import { ApmAppService, ApmEvents } from '../shared/services/apm_app.service';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
  providers: [
    QuizStore,
  ]
})
export class QuizComponent implements OnInit, OnDestroy, AfterViewInit {

  quiz: QuizState = null;
  subscriptions: Subscription = new Subscription();
  tweening: boolean = false;
  isLoaded: boolean = false;
  userIsAdmin: boolean = false;
  navbarHidden: boolean = true;

  constructor(private quizStore: QuizStore, private userStore: UserStore, private loadingStore: LoadingStore, private transitionService: TransitionService, private quizSliderService: QuizSliderService, private hotkeysService: HotkeysService, private progressService: ProgressService, private route: ActivatedRoute, private apm: ApmAppService) {
  }

  ngOnInit() {

    this.loadingStore.show();

    this.userIsAdmin = this.isUserAdmin();

    let id = this.route.snapshot.paramMap.get('id');

    this.subscriptions.add(
      this.quizSliderService.observe().subscribe(data => {
        this.tweening = false;
      })
    );

    this.subscriptions.add(

      this.quizStore.state$.subscribe(data => {

        if (data.action === 'initial') {

          this.quiz = data;

          window.setTimeout(() => {

            this.loadingStore.hide();
            this.isLoaded = true;

          }, 2000);
        }

        if (data.action === 'activate-introduction') {

          this.apm.trackEvent(ApmEvents.QUIZ_INTRODUCTION, {
            id,
          });

          this.quiz = data;
          this.slide(false);
        }

        if (data.action === 'activate-question') {

          this.quiz = data;
          this.navbarHidden = false;

          this.slide(true);

          this.apm.trackEvent(ApmEvents.QUIZ_ACTIVATE_QUESTION, {

            id,
            question: {

              id: this.quiz.question.id,
              question: this.quiz.question.question,
              answers: this.quiz.question.answers.map(answer => answer.answer),
            }
          });
        }

        if (data.action === 'activate-confirmation') {

          this.quiz = data;
          this.navbarHidden = false;

          this.slide(true);

          this.apm.trackEvent(ApmEvents.QUIZ_ACTIVATE_CONFIRMATION, {
            id
          });
        }

        if (data.action === 'quiz-validated') {

          this.quiz     = data;
          this.tweening = true;
          this.navbarHidden = false;

          let progress = this.progressService
            .quiz_completed(parseInt(id), this.quiz)
            .subscribe(_ => {
              progress.unsubscribe();
            });

          window.setTimeout(_ => {
            this.slide(true);
          }, 100);

          this.apm.trackEvent(ApmEvents.QUIZ_COMPLETED, {
            id,
          });
        }

        if (data.action === 'activate-answers') {

          this.quiz = data;
          this.navbarHidden = false;

          this.slide(true);

          this.apm.trackEvent(ApmEvents.QUIZ_ACTIVATE_ANSWERS, {
            id,
          });
        }

        if (data.action === 'activate-result-question') {

          this.quiz = data;
          this.navbarHidden = false;

          this.slide(true);
        }

        if (data.action === 'activate-completed') {

          this.quiz = data;
          this.navbarHidden = false;

          this.slide(true);
        }
      })
    );

    this.quizStore.fetch(parseInt(id));

    let progress = this.progressService
      .quiz_started(parseInt(id))
      .subscribe(_ => {
        progress.unsubscribe();
      });

    this.apm.trackEvent(ApmEvents.QUIZ_START, {
      id
    });
  }

  ngOnDestroy() {

    this.subscriptions.unsubscribe();
    this.hotkeysService.reset();
  }

  ngAfterViewInit() {

    window.setTimeout(() => {
      this.quizStore.activateIntroduction();
    }, 500);

    window.onresize = () => {
      this.resize();
    };

    this.hotkeysService.reset();
    this.hotkeysService.add(new Hotkey('right', (event: KeyboardEvent): boolean => {

      if (true === this.isQuestionActive()) {
        this.goToNextQuestion();
      } else {
        this.goToNextResultQuestion();
      }

      return false;
    }));

    this.hotkeysService.add(new Hotkey('left', (event: KeyboardEvent): boolean => {

      if (true === this.isQuestionActive()) {
        this.goToPreviousQuestion();
      } else {
        this.goToPreviousResultQuestion();
      }
      return false;
    }));
  }

  slide(tween: boolean) {

    this.tweening = true;
    this.quizSliderService.move(this.quiz.slide);
  }

  resize() {

    if (this.quiz) {
      this.quizSliderService.resize();
    }
  }

  showQuestions() {
    return false === this.quiz.confirmed && this.quiz.answers.length !== this.quiz.quiz.questions.length;
  }

  isResultQuestionActive() {
    return null !== this.quiz && null !== this.quiz.resultQuestion;
  }

  isValidated() {
    return null !== this.quiz && null !== this.quiz.result;
  }

  showGraduated() {
    return true === this.isValidated() && true === this.quiz.result.graduated;
  }

  showFailed() {
    return true === this.isValidated() && false === this.quiz.result.graduated;
  }

  showCompleted() {
    return true === (this.showGraduated() || this.showFailed());
  }

  goBackToQuiz() {

    if (true === this.tweening) {
      return;
    }

    this.quizStore.goBackToQuiz();
  }

  startQuiz() {

    if (true === this.tweening) {
      return;
    }

    this.quizStore.startQuiz();
  }

  getPreviousQuestionLinkClass() {

    if (false === this.isQuestionActive()) {
      return ['nav-icon--prev--disabled'];
    }

    let idx = this.quiz.quiz.questions.findIndex(question => {
      return this.quiz.question.id === question.id;
    });

    idx -= 1;

    if (idx < 0) {
      return ['nav-icon--prev--disabled'];
    }

    return [];
  }

  goToPreviousQuestion() {

    if (true === this.tweening) {
      return;
    }

    if (null === this.quiz.question) {
      return;
    }

    let idx = this.quiz.quiz.questions.findIndex(question => {
      return this.quiz.question.id === question.id;
    });

    if (idx === -1) {
      return;
    }

    idx -= 1;

    if (idx >= 0) {
      this.quizStore.activatePreviousQuestion(this.quiz.quiz.questions[idx]);
    }
  }

  getNextQuestionLinkClass() {

    if (false === this.isQuestionActive()) {
      return ['nav-icon--next--disabled'];
    }

    let idx = this.quiz.quiz.questions.findIndex(question => {
      return this.quiz.question.id === question.id;
    });

    if (false === this.quizStore.isAnswered(this.quiz.quiz.questions[idx])) {
      return ['nav-icon--next--disabled'];
    }

    idx += 1;

    if (idx >= this.quiz.quiz.questions.length) {
      return ['nav-icon--next--disabled'];
    }

    return [];
  }

  goToNextQuestion(confirmButton: boolean = false) {

    if (true === this.tweening) {
      return;
    }

    if (null === this.quiz.question) {
      return;
    }

    let idx = this.quiz.quiz.questions.findIndex(question => {
      return this.quiz.question.id === question.id;
    });

    if (idx === -1) {

      // active question was not found
      return;
    }

    if (false === this.quizStore.isAnswered(this.quiz.quiz.questions[idx])) {

      // active question was not answered
      return;
    }

    idx += 1;

    if (idx <= (this.quiz.quiz.questions.length - 1)) {

      // next question available
      this.quizStore.activateQuestion(this.quiz.quiz.questions[idx]);

    } else {

      // active question is last question
      if (true === confirmButton) {

        // only activate confirmation if clicked by confirm button
        this.quizStore.activateConfirmation();
      }
    }
  }

  getPreviousResultQuestionLinkClass() {

    if (false === this.isResultQuestionActive()) {
      return ['nav-icon--prev--disabled'];
    }

    let idx = this.quiz.quiz.questions.findIndex(question => {
      return this.quiz.resultQuestion.id === question.id;
    });

    idx -= 1;

    if (idx < 0) {
      return ['nav-icon--prev--disabled'];
    }

    return [];
  }

  goToPreviousResultQuestion() {

    if (true === this.tweening) {
      return;
    }

    if (false === this.isResultQuestionActive()) {
      return;
    }

    let idx = this.quiz.quiz.questions.findIndex(question => {
      return this.quiz.resultQuestion.id === question.id;
    });

    if (idx === -1) {
      return;
    }

    idx -= 1;

    if (idx >= 0) {
      this.quizStore.activatePreviousResultQuestion(this.quiz.quiz.questions[idx]);
    }
  }

  getNextResultQuestionLinkClass() {

    if (false === this.isResultQuestionActive()) {
      return ['nav-icon--next--disabled'];
    }

    let idx = this.quiz.quiz.questions.findIndex(question => {
      return this.quiz.resultQuestion.id === question.id;
    });

    idx += 1;

    if (idx >= this.quiz.quiz.questions.length) {
      return ['nav-icon--prev--disabled'];
    }

    return [];
  }

  goToNextResultQuestion() {

    if (true === this.tweening) {
      return;
    }

    if (false === this.isResultQuestionActive()) {
      return;
    }

    let idx = this.quiz.quiz.questions.findIndex(question => {
      return this.quiz.resultQuestion.id === question.id;
    });

    if (idx === -1) {
      return;
    }

    idx += 1;

    if (idx <= (this.quiz.quiz.questions.length - 1)) {
      this.quizStore.activateNextResultQuestion(this.quiz.quiz.questions[idx]);
    } else {
      this.activateCompleted();
    }
  }

  activateAnswers() {

    if (true === this.tweening) {
      return;
    }

    this.quizStore.activateAnswers();
  }

  activateCompleted() {

    if (true === this.tweening) {
      return;
    }

    this.quizStore.activateCompleted();
  }

  markAnswer(question: QuestionState, answer: AnswerState) {
    this.quizStore.markAnswer(question, answer);
  }

  getAnswerClass(question: QuestionState, answer: AnswerState) {

    let selectedQuestionIdx = this.quiz.answers.findIndex(selected => {
      return question.id === selected.question.id;
    });

    if (selectedQuestionIdx === -1) {
      return [];
    }

    let selectedAnswerIdx = this.quiz.answers[selectedQuestionIdx].answers.findIndex(selected => {
      return selected.id === answer.id;
    });

    if (selectedAnswerIdx === -1) {
      return [];
    }

    return ['answer-active'];
  }

  getResultAnswerClass(question: QuestionState, answer: AnswerState) {

    let selectedQuestionIdx = this.quiz.answers.findIndex(selected => {
      return question.id === selected.question.id;
    });

    if (selectedQuestionIdx === -1) {
      return true === answer.correct ? ['answer-correct'] : [];
    }

    let selectedAnswerIdx = this.quiz.answers[selectedQuestionIdx].answers.findIndex(selected => {
      return selected.id === answer.id;
    });

    if (selectedAnswerIdx === -1) {
      return true === answer.correct ? ['answer-correct'] : [];
    }

    return true === answer.correct ? ['answer-correct'] : ['answer-wrong'];
  }

  hideConfirmButton(question: QuestionState) {

    let selectedQuestionIdx =  this.quiz.answers.findIndex(selected => {
      return question.id === selected.question.id;
    });

    if (selectedQuestionIdx === -1) {
      return true;
    }

    return this.quiz.answers[selectedQuestionIdx].answers.length === 0;
  }

  validateQuiz() {

    if (true === this.tweening) {
      return;
    }

    this.quizStore.validateQuiz();
  }

  getTotalQuestions() {
    return this.quiz.quiz.questions.length;
  }

  isQuestionActive() {
    return null !== this.quiz && null !== this.quiz.question;
  }

  getCurrentQuestionLabel() {

    if (false === this.isQuestionActive()) {

      // not active question
      return '';
    }

    let idx = this.quiz.quiz.questions.findIndex(question => {
      return question.id === this.quiz.question.id;
    });

    let current = idx === -1 ? 0 : (idx + 1);

    return `Vraag ${current} / ${this.quiz.quiz.questions.length}`;
  }

  getCurrentResultQuestionLabel() {

    if (false === this.isResultQuestionActive()) {

      // not active result question
      return '';
    }

    let idx = this.quiz.quiz.questions.findIndex(question => {
      return question.id === this.quiz.resultQuestion.id;
    });

    let current = idx === -1 ? 0 : (idx + 1);

    return `Vraag ${current} / ${this.quiz.quiz.questions.length}`;
  }

  getQuizPercentageStyle() {

    if (false === this.isQuestionActive()) {

      return {
        'width': '0%'
      };
    }

    let answered = 0;

    this.quiz.answers.forEach(answer => {

      if (answer.answers.length > 0 && answer.question.id !== this.quiz.question.id) {
        answered += 1;
      }
    });

    let percentage = ((answered / this.quiz.quiz.questions.length) * 100);

    return {
      'width': `${percentage}%`
    };
  }

  getQuizGrade() {
    return this.quiz.result.grade.toFixed(1);
  }

  isUserAdmin() {
    return this.userStore.getUser().roles.indexOf('admin') > 1;
  }

  goToDashboard() {

    // hide navbar
    this.navbarHidden = true;

    // show loader
    this.loadingStore.show();

    // adding timeout to allow fade out of reader
    window.setTimeout(() => {

      this.transitionService
        .navigate(['']);

    }, 2000);
  }

  goToBackend() {
    window.open(`${environment.backend_url}/modules/${this.quiz.quiz.course_module_id}/quizzes/${this.quiz.quiz.id}`);
  }

  dump(data: any) {
    // console.log(data);
  }
}
