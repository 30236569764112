import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';
import { ActiveExamModalService } from "./active-exam-modal.service";
import { ActiveExamModalState, ActiveExamStatus } from "./active-exam-modal.state";

@Injectable({
  providedIn: 'root'
})
export class ActiveExamModalStore extends Store<ActiveExamModalState> {

  constructor(private api: ActiveExamModalService) {
    super(new ActiveExamModalState());
  }

  fetch() {

    // fetching sess token from local storage, because active exam modal component
    // is called before everything
    let token = localStorage.getItem('_puma_sess_tk');

    let fetch = this.api
      .fetch(token)
      .subscribe(

        (data: any) => {

          fetch.unsubscribe();

          let state = {

            ...this.state,
            action: 'initial',
            show: true,
            name: data.active_exam.name,
            status: data.active_exam.status,
            exam_id: data.active_exam.exam_id,
            running_time: data.active_exam.running_time,
            remaining_time: data.active_exam.remaining_time,
            total_time: data.active_exam.total_time,
          };

          if (true === this.state.close) {

            // modal was closed, don't show it (see active-exam-modal component goToExam method)
            state = {

              ...state,
              show: false,
              close: false,
            }
          }

          this.setState(state);
        },

        _ => {

          let cleanState = new ActiveExamModalState();

          this.setState({

            ...cleanState,
            action: 'initial',
            show: false,
          });
        }
      );
  }

  update(newState: ActiveExamModalState) {

    this.setState({

      ...newState,
      action: 'initial',
      show: false,
    });
  }

  clear() {

    let cleanState = new ActiveExamModalState();

    this.setState({


      ...cleanState,
      action: 'initial',
      show: false,
    });
  }

  hide() {

    this.setState({

      ...this.state,
      show: false
    });
  }

  close() {

    this.setState({

      ...this.state,
      close: true,
      show: false,
    });
  }

  show() {

    this.setState({

      ...this.state,
      show: true
    });
  }

  finished() {

    this.setState({

      ...this.state,
      status: ActiveExamStatus.FINISHED,
    });
  }
}
