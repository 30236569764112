import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { UserStore } from '../stores/user.store';
import { HttpClient } from '@angular/common/http';
import { UserState } from '../stores/states/user.state';
import { ExamState } from 'src/app/exam/states/exam.state';
import { QuizState } from 'src/app/quiz/states/quiz.state';

@Injectable({
  providedIn: 'root'
})
export class ProgressService extends ApiService {

  constructor(protected http: HttpClient, private userStore: UserStore) {
    super(http);
  }

  reader_started(reader_id: number) {

    return this.put('/progress/reader_started/' + reader_id, {
      session_token: this.userStore.getSessionToken(),
    });
  }

  reader_completed(reader_id: number) {

    return this.put('/progress/reader_completed/' + reader_id, {
      session_token: this.userStore.getSessionToken(),
    });
  }

  case_started(case_id: number) {

    return this.put('/progress/case_started/' + case_id, {
      session_token: this.userStore.getSessionToken(),
    });
  }

  case_completed(case_id: number) {

    return this.put('/progress/case_completed/' + case_id, {
      session_token: this.userStore.getSessionToken(),
    });
  }

  quiz_started(quiz_id: number) {

    return this.put('/progress/quiz_started/' + quiz_id, {
      session_token: this.userStore.getSessionToken(),
    });
  }

  quiz_completed(quiz_id: number, quiz: QuizState) {

    let answers = quiz.answers.map(selected => {

      return {

        question_pool_id: selected.question.question_pool_id,
        question_id: selected.question.id,
        answer_id: selected.answers[0].id,
        weight: selected.question.weight,
        correct: selected.answers[0].correct,
      };
    });

    return this.put('/progress/quiz_completed/' + quiz_id, {

      session_token: this.userStore.getSessionToken(),
      progress: {
        result: quiz.result,
        answers: answers,
      }
    });
  }

  exam_started(exam_id: number) {

    return this.put('/progress/exam_started/' + exam_id, {
      session_token: this.userStore.getSessionToken(),
    });
  }

  exam_completed(exam_id: number, exam: ExamState) {

    let answers = exam.answers
      .filter(selected => selected.answers.length > 0)
      .map(selected => {

      return {

        question_pool_id: selected.question.question_pool_id,
        question_id: selected.question.id,
        answer_id: selected.answers[0].id,
        weight: selected.question.weight,
        correct: selected.answers[0].correct,
      };
    });

    return this.put('/progress/exam_completed/' + exam_id, {

      session_token: this.userStore.getSessionToken(),
      progress: {
        result: exam.result,
        answers: answers,
      }
    });
  }
}
