import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';
import { SetPasswordService } from "./set-password.service";
import { SetPasswordState } from "./set-password.state";
import { UserStore } from "../shared/stores/user.store";

@Injectable()
export class SetPasswordStore extends Store<SetPasswordState> {

  constructor(private setPasswordService: SetPasswordService, private userStore: UserStore) {
    super(new SetPasswordState());
  }

  setPassword(session_token: string, password: string, password_confirmation: string) {

    let set = this.setPasswordService
      .set(session_token, password, password_confirmation)
      .subscribe((data: any) => {

        set.unsubscribe();

        this.setState({

          ...this.state,
          action: 'set',
          errors: [],
        });

      }, (data: any) => {

        set.unsubscribe();
        this.setState({

          ...this.state,
          action: 'error',
          errors: data.error.errors
        });
      });
  }
}
