import { ProgressState } from '../shared/stores/states/progress.state';
import { ChapterState } from '../shared/stores/states/chapter.state';

export class Grades {
  [course_module_item_id: number]: {

    graduated: boolean,
    grade: number
  }
};

export class ExpiringSubscription {
  name: string;
  remaining_days: number;
}

export class DashboardState {

  action: string;
  chapters: ChapterState[] = [];
  progress: ProgressState;
  grades: Grades;
  expiring: ExpiringSubscription[];
}
