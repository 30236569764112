import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConnectionStore } from '../connection-lost/connection.store';
import { BrowserSupport } from '../shared/browser.support';

@Component({
  selector: 'app-unsupported-browser',
  templateUrl: './unsupported-browser.component.html',
  styleUrls: ['./unsupported-browser.component.scss']
})
export class UnsupportedBrowserComponent implements OnInit, OnDestroy {

  supported: boolean = true;
  hasConnection: boolean = false;
  private subscriptions: Subscription = new Subscription();

  constructor(private store: ConnectionStore) {

    // check if browser is supported
    // @TODO fix this so only old browsers fail, not new ones
    this.supported = true;// true === BrowserSupport.test(navigator.userAgent);
  }

  ngOnInit(): void {

    this.subscriptions.add(

      this.store.state$.subscribe(connectionStatus => {

        if (null !== connectionStatus) {
          this.hasConnection = connectionStatus;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
