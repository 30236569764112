import { CompanyState } from "../shared/stores/states/company.state";
import { UserState } from "../shared/stores/states/user.state";

export class CompanyAccountState {

  action: string;
  errors: string[];
  error_fields: string[] = [];
  company: CompanyState;
  user: UserState;
};
