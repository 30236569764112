import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class QuizSliderService {

  private subject: BehaviorSubject<boolean>;
  private observable: Observable<boolean>;
  private resizing: number = null;

  constructor() {

    this.subject    = new BehaviorSubject<boolean>(false);
    this.observable = this.subject.asObservable();
  }

  observe() {
    return this.observable;
  }

  move(currentChild: number) {

    let container = $('.exam-items-container');
    let items     = container.children();

    $(".exam-item--active").removeClass("exam-item--active");

    $(items[currentChild]).addClass("exam-item--active");

    let margin = -currentChild * 100;
    container.css("margin-left", margin + "vw");

    this.subject.next(true);
  }

  resize() {

    if (null !== this.resizing) {
      return;
    }

    $('.exam-items-container').addClass('is-resizing');

    this.resizing = window.setTimeout(() => {

      $('.exam-items-container').removeClass('is-resizing');
      this.resizing = null;
    }, 200);
  }
}
