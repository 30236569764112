import { Component, OnInit, OnDestroy } from '@angular/core';
import { SetPasswordStore } from './set-password.store';
import { Subscription } from 'rxjs';
import { TransitionService } from '../shared/services/transition.service';
import { UserStore } from '../shared/stores/user.store';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit, OnDestroy {

  email: string               = null;
  status: string              = 'form';
  errors: string[]            = [];
  loading: boolean            = false;
  subscriptions: Subscription = new Subscription();

  constructor(private setPasswordStore: SetPasswordStore, private userStore: UserStore, private transitionService: TransitionService) { }

  ngOnInit() {

    this.email = this.userStore.getUser().email;
    this.subscriptions.add(

      this.setPasswordStore.state$.subscribe(data => {

        this.loading = false;

        if (data.action === 'set') {

          this.status = 'success';
          this.errors  = [];
        }

        if (data.action === 'error') {

          this.status = 'form';
          this.errors = data.errors;
        }

      }, _ => {
        this.goToSettings();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  goToSettings() {

    return this.transitionService
      .navigate(['settings']);
  }

  goToAccount() {

    this.transitionService
      .navigate(['account']);
  }

  goToDashboard() {

    return this.transitionService
      .navigate(['dashboard']);
  }

  setPassword(password: string, password_confirmation: string) {

    if (password !== password_confirmation) {

      this.status = 'form';
      this.errors = ['Wachtwoord komt niet met elkaar overeen'];

      return;
    }

    this.loading = true;

    let session_token = this.userStore.getSessionToken();
    this.setPasswordStore.setPassword(session_token, password, password_confirmation);
  }

  showConfirmButton() {
    return false === this.loading;
  }
}
