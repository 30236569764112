import { UserState } from "../shared/stores/states/user.state";
import { CompanyState } from "../shared/stores/states/company.state";

export class NewOwnerState {

  action: string;
  user: UserState;
  company: CompanyState;
  errors: string[] = [];
  error_fields: string[] = [];
}
