import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConnectionStore } from './connection.store';

@Component({
  selector: 'app-connection-lost',
  templateUrl: './connection-lost.component.html',
  styleUrls: ['./connection-lost.component.scss']
})
export class ConnectionLostComponent implements OnInit, OnDestroy {

  hasConnection: boolean = true;
  private subscriptions: Subscription = new Subscription();

  constructor(private store: ConnectionStore) {
  }

  ngOnInit(): void {

    this.subscriptions.add(

      this.store.state$.subscribe(connectionStatus => {

        if (null !== connectionStatus) {
          this.hasConnection = connectionStatus;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
