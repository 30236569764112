import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';
import { UserStore } from "../shared/stores/user.store";
import { UserState } from "../shared/stores/states/user.state";
import { CompanyNewUserState } from "./company-new-user.state";
import { CompanyNewUserService } from "./company-new-user.service";

@Injectable()
export class CompanyNewUserStore extends Store<CompanyNewUserState> {

  constructor(private companyNewUserService: CompanyNewUserService, private userStore: UserStore) {
    super(new CompanyNewUserState());
  }

  reset() {
    this.setState(new CompanyNewUserState());
  }

  fetch() {

    let fetch = this.companyNewUserService
      .fetch(this.userStore.getSessionToken())
      .subscribe((data: any) => {

        fetch.unsubscribe();
        this.setState({

          ...this.state,
          action: 'initial',
          company: data.company
        });
      });
  }

  addUser(user: UserState, subscriptions: number[]) {

    let addUser = this.companyNewUserService
      .addUser(user, subscriptions, this.userStore.getSessionToken())
      .subscribe((data: any) => {

        addUser.unsubscribe();

        this.setState({

          ...this.state,
          action: 'user-added',
          credits_left: data.credits_left,
        });

      }, (error) => {

        addUser.unsubscribe();

        this.setState({

          ...this.state,
          action: 'user-error',
          errors: error.error.errors,
          error_fields: error.error.error_fields,
        });
      });
  }
}
