import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(date?: Date): string {
    return date ? format(date, 'dd/MM/yyyy HH:mm:ss') : '';
  }
}
