<div class="nav-bar">
  <section class="nav-bar-business">
    <div class="button-container">
      <div class="nav-bar-item nav-section">Q Care Academie Portaal</div>
    </div>
    <div class="button-container">
      <a class="nav-icon nav-icon--account" [attr.data-navigation-id]="'qcare.nav.company.account'" (click)="goToCompanyAccount()"></a>
      <a class="nav-icon nav-icon--settings" [attr.data-navigation-id]="'qcare.nav.company.settings'" (click)="goToSettings()"></a>
    </div>
  </section>
</div>
<section class="business-main business-home" *ngIf="company">
  <div class="business-wrapper">
    <app-notifications></app-notifications>
    <div class="business-top-message">
      <div class="business-top-message__text">
        <h1>Welkom, {{ company.name }}</h1>
        <p>In dit portaal kunt u uw werknemers koppelen aan cursussen en hun voortgang bekijken.</p>
      </div>
      <a class="top-message-info-button" [attr.data-navigation-id]="'qcare.nav.company.toggle-top-message'" (click)="toggleTopMessage()">?</a>
    </div>
    <div class="top-message-foldable" data-role="top-message">
      <ul>
        <li>U heeft bij QCare cursussen aangeschaft.</li>
        <li>U ontvangt van QCare een openstaand tegoed voor de betreffende cursus.</li>
        <li>U kunt deze cursussen koppelen aan uw werknemers.</li>
        <li>Na koppeling wordt de gekoppelde cursus van uw tegoed afgeschreven.</li>
        <li>Uw werknemer ontvangt een automatische e-mail waarmee zij kunnen inloggen op de e-learning.</li>
        <li>U kunt op deze pagina vervolgens de voortgang van uw werknemers volgen.</li>
        <li>Aan de hand van de Cursus ID kunt u herkennen welke cursussen voltooid zijn.</li>
      </ul>
    </div>
    <div class="business-course-credits-wrapper" *ngIf="company.credits.length > 0">
      <div class="business-course-title">Uw Openstaand Tegoed</div>
      <table>
        <thead>
          <tr>
            <th>Tegoed</th>
            <th>Cursus</th>
            <th>Cursus ID</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let credit of company.credits">
            <td class="business-course-credit__value">{{ credit.credits }}X</td>
            <td class="business-course-credit__name">{{ credit.course_name }}</td>
            <td><div class="course-circle">{{ credit.course_id }}</div></td>
          </tr>
        </table>
    </div>
    <a class="business-course-add-button" [attr.data-navigation-id]="'qcare.nav.company.new-user'" (click)="goToNewUserForm()" *ngIf="company.credits.length > 0">
      <div class="business-course-add-button__text">Cursist toevoegen</div>
      <div class="business-course-add-button__icon"></div>
    </a>
    <div class="business-course-users">
      <table class="business-course-user-table">
        <thead>
          <tr>
            <th>Naam</th>
            <th>Voorletters</th>
            <th>E-mailadres</th>
            <th>Cursussen<br>Voortgang</th>
            <th>Laatst<br>Actief</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let user of company.users">
            <tr *ngIf="user.user_id != currentUser.id">
              <td>{{ user.full_name }}</td>
              <td>{{ user.initials }}</td>
              <td>{{ user.email }}</td>
              <td class="cource-circles">
                <ng-container *ngFor="let item of user.progress">
                  <div class="course-circle" [class.course-circle--complete]="item.completed" [class.course-circle--expired]="item.remaining_days == 0">
                    {{ item.percentage }}%
                    <span class="course-circle__tooltip">
                      {{ item.course_name }}
                      <ng-container *ngIf="item.remaining_days > 0"><br>{{item.remaining_days}} dag{{ item.remaining_days == 1 ? '' : 'en' }} resterend</ng-container>
                      <ng-container *ngIf="item.remaining_days == 0"><br>einddatum verstreken</ng-container>
                    </span>
                  </div>
                </ng-container>
              </td>
              <td>{{ user.last_login }}</td>
              <td><a [attr.data-navigation-id]="'qcare.nav.company.edit-user'" [attr.data-navigation-user]="user.user_id" (click)="goToEditUserForm(user.user_id)">&#x2794;</a></td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</section>
