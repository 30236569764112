import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends ApiService {

  fetch() {
    return this.get('/settings');
  }

  logout(session_token: string) {
    return this.destroy('/sessions', {session_token});
  }
}
