import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { UserState } from '../shared/stores/states/user.state';
import { CompanyState } from '../shared/stores/states/company.state';

@Injectable({
  providedIn: 'root'
})
export class NewOwnerService extends ApiService {

  check(reset_password_token: string) {
    return this.get(`/new_owner/${reset_password_token}`);
  }

  setNewOwner(reset_password_token: string, user: UserState, company: CompanyState, password: string, password_confirmation: string) {

    return this.post(`/new_owner/${reset_password_token}`, {

      new_owner: {

        title: user.title,
        initials: user.initials,
        last_name: user.last_name,
        phone: user.phone,
      },
      company: {

        street: company.street,
        housenumber: company.housenumber,
        housenumber_suffix: company.housenumber_suffix,
        postalcode: company.postalcode,
        city: company.city,
        country: company.country,
      },
      reset_password: {

        password,
        password_confirmation
      }
    });
  }
}
