import { UserState } from "../shared/stores/states/user.state";
import { CompanyState } from "../old-company/company.state";
import { CreditsState } from "../old-company/credits.state";

export class CompanyNewUserState {

  action: string;
  user: UserState  = new UserState();
  errors: string[] = [];
  error_fields: string[] = [];
  subscriptions: number[] = [];
  credits_left: number;
  company: {
    id: number;
    user_id: number;
    name: string;
    street: string;
    housenumber: string;
    housenumber_suffix: string|null;
    postalcode: string;
    city: string;
    country: string;
    credits: CreditsState[];
    roles: string[];
    users: UserState[];
  };
}
