import { Injectable } from "@angular/core";
import { Store } from './store';

@Injectable()
export class ModalStore extends Store<boolean> {

  constructor() {
    super(false);
  }

  deactivate() {
    this.setState(false);
  }

  activate() {
    this.setState(true);
  }
}
