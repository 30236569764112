import { Injectable } from "@angular/core";
import { Store } from './store';
import { NotificationState } from './states/notification.state';

@Injectable({
  providedIn: 'root'
})
export class NotificationStore extends Store<NotificationState[]> {

  constructor() {
      super([]);
  }

  clear() {
    this.setState([]);
  }

  add(notification: NotificationState) {

    let notifications = [...this.state];
    notifications.push(notification);

    this.setState(notifications);
  }

  addSuccessNotification(message: string) {

    this.add({

      id: null,
      kind: 'success',
      message: message,
    });
  }

  addErrorNotification(message: string) {

    this.add({

      id: null,
      kind: 'error',
      message: message,
    });
  }

  addExpiringNotification(message: string) {

    this.add({

      id: null,
      kind: 'expiring',
      message: message
    });
  }

  addNotifications(notifications: NotificationState[]) {
    this.setState([...notifications, ...this.state]);
  }

  getNotifications() {
    return this.state;
  }
}
