<div class="nav-bar">
    <div class="nav-bar-section-wrapper">
      <section class="nav-bar-menu">
        <div class="button-container">
            <div class="nav-bar-item nav-section" [attr.data-navigation-id]="'qcare.nav.company-account.company'" (click)="goToCompany()">Q Care Academie Portaal</div>
          </div>
        <div class="button-container">
          <a class="nav-icon nav-icon--account nav-icon--account--active"></a>
          <a class="nav-icon nav-icon--settings" [attr.data-navigation-id]="'qcare.nav.company-account.settings'" (click)="goToSettings()"></a>
        </div>
      </section>
    </div>
  </div>
  <section class="settings-main settings-account" *ngIf="showAccountPage()">
    <div class="settings-wrapper">
      <div class="personalia-item personalia-item--success" *ngIf="accountSaved()">
        Uw gegevens zijn succesvol opgeslagen
      </div>
      <div class="personalia-item personalia-item--error" *ngIf="errors.length > 0">
        Er ging iets fout
        <ul style="margin-bottom: 0;">
          <li *ngFor="let error of errors">{{ error }}</li>
        </ul>
      </div>
      <div class="personalia-item">
        <form class="settings-form">
          <div class="settings-form__item">
            <h2>Bedrijfscontact</h2>
            <h3>Vul hieronder de gegevens van uw bedrijfsprofiel in.</h3>
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="account.error_fields.includes('name')">
            <p>Bedrijf / Instelling*</p>
            <input type="text" name="name" placeholder="Vul bedrijf / instelling in" class="login-input" [(ngModel)]="account.company.name">
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="account.error_fields.includes('title')">
            <p>Aanhef*</p>
            <select name="title" [(ngModel)]="account.user.title">
              <option value="" disabled>Kies uw aanhef</option>
              <option value="Dhr.">Dhr.</option>
              <option value="Mevr.">Mevr.</option>
            </select>
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="account.error_fields.includes('initials')">
            <p>Voorletters*</p>
            <input type="text" name="initials" placeholder="Vul uw voorletters in" class="login-input" [(ngModel)]="account.user.initials">
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="account.error_fields.includes('last_name')">
            <p>Achternaam*</p>
            <input type="text" name="last_name" placeholder="Vul uw achternaam in" class="login-input" [(ngModel)]="account.user.last_name">
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="account.error_fields.includes('phone')">
            <p>Telefoon*</p>
            <input type="text" name="phone" placeholder="Vul uw telefoon in" class="login-input" name="phone" [(ngModel)]="account.user.phone">
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="account.error_fields.includes('street')">
            <p>Straat*</p>
            <input type="text" name="street" placeholder="Vul straatnaam in van uw organisatie" class="login-input" name="street" [(ngModel)]="account.company.street">
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="account.error_fields.includes('housenumber')">
            <p>Huisnummer*</p>
            <input type="text" name="housenumber" placeholder="Vul huisnummer in van uw organisatie" class="login-input" name="housenumber" [(ngModel)]="account.company.housenumber">
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="account.error_fields.includes('housenumber_suffix')">
            <p>Toevoeging*</p>
            <input type="text" name="housenumber_suffix" placeholder="Vul toevoeging in van uw organisatie" class="login-input" name="housenumber_suffix" [(ngModel)]="account.company.housenumber_suffix">
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="account.error_fields.includes('postalcode')">
            <p>Postcode*</p>
            <input type="text" name="postalcode" placeholder="Vul postcode in van uw organisatie" class="login-input" name="postalcode" [(ngModel)]="account.company.postalcode">
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="account.error_fields.includes('city')">
            <p>Stad*</p>
            <input type="text" name="city" placeholder="Vul plaatsnaam in van uw organisatie" class="login-input" name="city" [(ngModel)]="account.company.city">
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="account.error_fields.includes('country')">
            <p>Land*</p>
            <input type="text" name="country" placeholder="Vul land in van uw organisatie" class="login-input" name="country" [(ngModel)]="account.company.country">
          </div>
          <div class="settings-form__item">
            <input type="submit" value="bezig met aanpassen" *ngIf="!showConfirmButton()">
            <input type="submit" value="opslaan" [attr.data-navigation-id]="'qcare.nav.company-account.save-account'" (click)="saveAccount()" *ngIf="showConfirmButton()">
          </div>
        </form>
      </div>
    </div>
  </section>
