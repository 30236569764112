import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { ApiService } from '../shared/services/api.service';
import { UserState } from '../shared/stores/states/user.state';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends ApiService {

  fetch(session_token: string) {

    return this.get('/account', {
      session_token: session_token
    });
  }

  update(user: UserState, session_token: string) {

    return this.put('/account', {

      session_token: session_token,
      user: {

        title: user.title,
        initials: user.initials,
        last_name: user.last_name,
        birthday: format(user.birthday, 'yyyy-MM-dd'),
        registration_kind: user.registration_kind,
        registration_number: user.registration_number,
      }
    });
  }

  getDiploma(id: number, session_token: string) {

    return this.post(`/v2/diplomas/${id}`, {
        session_token,
      });
  }
}
