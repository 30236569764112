import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class TreatmentsService extends ApiService {

  all() {
    return this.get('/treatments');
  }
}
