import { Component, OnInit } from '@angular/core';
import { ForgotPasswordService } from './forgot-password.service';
import { TransitionService } from '../shared/services/transition.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  loading: boolean = false;
  sent: boolean = false;

  constructor(private forgotPasswordService: ForgotPasswordService, private transitionService: TransitionService) {}

  forgotPassword(email: string) {

    this.loading = true;

    let forgotPassword = this.forgotPasswordService.forgotPassword(email)
      .subscribe(data => {

        this.sent    = true;
        this.loading = false;

        forgotPassword.unsubscribe();

      }, (error) => {

        this.loading = false;
        this.sent    = true;
      });
  }

  goToLogin() {

    return this.transitionService
      .navigate(['']);
  }
}
