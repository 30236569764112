<section class="settings-main settings-account">
  <div class="settings-wrapper">
    <form class="settings-form">
      <ng-template [ngIf]="status == 'form'">
        <div class="personalia-item personalia-item--error" *ngIf="new_owner.errors.length > 0">
          <ul>
            <li *ngFor="let error of new_owner.errors">{{ error }}</li>
          </ul>
        </div>
        <div class="personalia-item">
          <div class="settings-form__item">
            <h2>Wachtwoord Instellen</h2>
            <h3>Vul hier een wachtwoord in waarmee u in wilt loggen.</h3>
          </div>
          <div class="settings-form__item settings-form__item--locked">
            <p>E-mailadres</p>
            <input type="text" placeholder="{{ new_owner.user.email }}" class="login-input" disabled>
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_owner.error_fields.includes('title')">
            <p>Aanhef*</p>
            <select name="title" [(ngModel)]="new_owner.user.title">
              <option value="" disabled>Kies uw aanhef</option>
              <option value="Dhr.">Dhr.</option>
              <option value="Mevr.">Mevr.</option>
            </select>
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_owner.error_fields.includes('initials')">
            <p>Voorletters*</p>
            <input type="text" name="initials" placeholder="{{ new_owner.user.initials }}" class="login-input" [(ngModel)]="new_owner.user.initials">
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_owner.error_fields.includes('last_name')">
            <p>Achternaam*</p>
            <input type="text" name="last_name" placeholder="{{ new_owner.user.last_name }}" class="login-input" [(ngModel)]="new_owner.user.last_name">
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_owner.error_fields.includes('phone')">
            <p>Telefoon*</p>
            <input type="text" name="phone" placeholder="{{ new_owner.user.phone }}" class="login-input" [(ngModel)]="new_owner.user.phone">
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_owner.error_fields.includes('password')">
            <p>Wachtwoord*</p>
            <input type="password" placeholder="Vul hier uw nieuwe wachtwoord in" class="login-input" #password>
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_owner.error_fields.includes('password_confirmation')">
            <p>Herhaal wachtwoord*</p>
            <input type="password" placeholder="Vul hier opnieuw uw nieuwe wachtwoord in" class="login-input" #password_confirmation>
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_owner.error_fields.includes('street')">
            <p>Straat*</p>
            <input type="text" name="street" placeholder="{{ new_owner.company.street }}" class="login-input" [(ngModel)]="new_owner.company.street">
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_owner.error_fields.includes('housenumber')">
            <p>Huisnummer*</p>
            <input type="text" name="housenumber" placeholder="{{ new_owner.company.housenumber }}" class="login-input" [(ngModel)]="new_owner.company.housenumber">
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_owner.error_fields.includes('housenumber_suffix')">
            <p>Toevoeging</p>
            <input type="text" name="housenumber_suffix" placeholder="{{ new_owner.company.housenumber_suffix }}" class="login-input" [(ngModel)]="new_owner.company.housenumber_suffix">
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_owner.error_fields.includes('postalcode')">
            <p>Postcode*</p>
            <input type="text" name="postalcode" placeholder="{{ new_owner.company.postalcode }}" class="login-input" [(ngModel)]="new_owner.company.postalcode">
          </div>
          <div class="settings-form__item" [class.settings-form__item--error]="new_owner.error_fields.includes('country')">
            <p>Land*</p>
            <input type="text" name="country" placeholder="{{ new_owner.company.country }}" class="login-input" [(ngModel)]="new_owner.company.country">
          </div>
          <div class="settings-form__item">
            <input type="submit" value="opslaan" (click)="newOwner(password.value, password_confirmation.value)">
          </div>
        </div>
      </ng-template>
      <div class="personalia-item" *ngIf="status == 'success'">
        <div class="settings-form__item">
          <h2>Account opgezet</h2>
          <h3>Uw account is succesvol opgezet. Klik <a (click)="goToDashboard()">hier</a> om naar uw dashboard te gaan.</h3>
        </div>
      </div>
    </form>
  </div>
</section>
