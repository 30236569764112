export class ExpiredSubscription {
  name: string;
  expired_at: Date;
};

export class ExpiredSubscriptionsModalState {

  transition: boolean;
  confirming: boolean;
  show: boolean;
  subscriptions: ExpiredSubscription[];
}
