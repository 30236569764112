import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActiveExamModalStore } from './active-exam-modal.store';
import { ActiveExamModalState, ActiveExamStatus } from './active-exam-modal.state';
import { TransitionService } from '../shared/services/transition.service';
import { TimerComponent } from '../timer/timer.component';

@Component({
  selector: 'app-active-exam-modal',
  templateUrl: './active-exam-modal.component.html',
  styleUrls: ['./active-exam-modal.component.scss'],
})
export class ActiveExamModalComponent implements OnInit, OnDestroy {

  state: ActiveExamModalState = new ActiveExamModalState();
  savedTime: number = null;
  isFinished: ActiveExamStatus = ActiveExamStatus.FINISHED;
  isRunning: ActiveExamStatus = ActiveExamStatus.RUNNING;
  @ViewChildren('timer') timer: QueryList<TimerComponent>;

  private subscriptions: Subscription = new Subscription();

  constructor(private activeExamModalStore: ActiveExamModalStore, private transitionService: TransitionService) { }

  ngOnInit(): void {

    this.subscriptions.add(

      this.activeExamModalStore.state$.subscribe(state => {

        if (state.action === 'initial') {

          this.state = state;
          this.savedTime = this.state.remaining_time;
        }
      })
    );
  }

  ngOnDestroy(): void {

    this.subscriptions.unsubscribe();
    this.clearTimer();
  }

  timerTick(seconds: number) {
    this.savedTime = seconds;
  }

  timerFinished() {

    this.clearTimer();
    this.activeExamModalStore.finished();
  }

  clearTimer() {

    if (this.timer.length > 0) {
      this.timer.map(timer => timer.clearTimer());
    }
  }

  goToExam() {

    this.activeExamModalStore.close();

    // navigate to exam
    this.transitionService
      .navigate([`exam/${this.state.exam_id}`]);
  }
}
