import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './shared/components';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TreatmentsComponent } from './treatments/treatments.component';
import { TreatmentComponent } from './treatment/treatment.component';
import { ReaderComponent } from './reader/reader.component';
import { QuizComponent } from './quiz/quiz.component';
import { ExamComponent } from './exam/exam.component';
import { CaseComponent } from './case/case.component';
import { CompanyAuthenticatedGuard } from './shared/guards/company-authenticated.guard';
import { AppAuthenticatedGuard } from './shared/guards/app-authenticated.guard';
import { GeneralAuthenticatedGuard } from './shared/guards/general-authenticated.guard';
import { NotAuthenticatedGuard } from './shared/guards/not-authenticated.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SettingsComponent } from './settings/settings.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AccountComponent } from './account/account.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { StaticPageComponent } from './static-page/static-page.component';
import { NewOwnerComponent } from './new-owner/new-owner.component';
import { ForceNotAuthenticatedGuard } from './shared/guards/force-not-authenticated.guard';
import { NewUserComponent } from './new-user/new-user.component';
import { CompanyComponent } from './company/company.component';
import { CompanyNewUserComponent } from './company-new-user/company-new-user.component';
import { CompanyEditUserComponent } from './company-edit-user/company-edit-user.component';
import { CompanyAccountComponent } from './company-account/company-account.component';
import { RouteNotFoundGuard } from './shared/guards/route-not-found.guard';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    pathMatch: 'full',
    canActivate: [NotAuthenticatedGuard]
  },
  {
    path: 'forgot',
    component: ForgotPasswordComponent,
    pathMatch: 'full',
    canActivate: [ForceNotAuthenticatedGuard]
  },
  {
    path: 'reset/:reset_password_token',
    component: ResetPasswordComponent,
    pathMatch: 'full',
    canActivate: [ForceNotAuthenticatedGuard]
  },
  {
    path: 'new_owner/:reset_password_token',
    component: NewOwnerComponent,
    pathMatch: 'full',
    canActivate: [ForceNotAuthenticatedGuard]
  },
  {
    path: 'new_user/:new_user_token',
    component: NewUserComponent,
    pathMatch: 'full',
    canActivate: [ForceNotAuthenticatedGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    pathMatch: 'full',
    canActivate: [AppAuthenticatedGuard]
  },
  {
    path: 'company',
    component: CompanyComponent,
    pathMatch: 'full',
    canActivate: [CompanyAuthenticatedGuard]
  },
  {
    path: 'company/account',
    component: CompanyAccountComponent,
    pathMatch: 'full',
    canActivate: [CompanyAuthenticatedGuard]
  },
  {
    path: 'company/users/new',
    component: CompanyNewUserComponent,
    pathMatch: 'full',
    canActivate: [CompanyAuthenticatedGuard]
  },
  {
    path: 'company/users/:id',
    component: CompanyEditUserComponent,
    pathMatch: 'full',
    canActivate: [CompanyAuthenticatedGuard]
  },
  {
    path: 'account',
    component: AccountComponent,
    pathMatch: 'full',
    canActivate: [AppAuthenticatedGuard]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    pathMatch: 'full',
    canActivate: [GeneralAuthenticatedGuard]
  },
  {
    path: 'set-password',
    component: SetPasswordComponent,
    pathMatch: 'full',
    canActivate: [GeneralAuthenticatedGuard]
  },
  {
    path: 'page/:slug',
    component: StaticPageComponent,
    pathMatch: 'full',
    canActivate: [NotAuthenticatedGuard]
  },
  {
    path: 'account/page/:slug',
    component: StaticPageComponent,
    pathMatch: 'full',
    canActivate: [AppAuthenticatedGuard]
  },
  {
    path: 'treatments',
    component: TreatmentsComponent,
    pathMatch: 'full',
    canActivate: [AppAuthenticatedGuard]
  },
  {
    path: 'treatments/:id',
    component: TreatmentComponent,
    canActivate: [AppAuthenticatedGuard]
  },
  {
    path: 'treatments/:page/:id',
    component: TreatmentComponent,
    canActivate: [AppAuthenticatedGuard]
  },
  {
    path: 'reader/:id/:page',
    component: ReaderComponent,
    canActivate: [AppAuthenticatedGuard]
  },
  {
    path: 'quiz/:id',
    component: QuizComponent,
    canActivate: [AppAuthenticatedGuard]
  },
  {
    path: 'exam/:id',
    component: ExamComponent,
    canActivate: [AppAuthenticatedGuard]
  },
  {
    path: 'case/:id',
    component: CaseComponent,
    canActivate: [AppAuthenticatedGuard]
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [RouteNotFoundGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
