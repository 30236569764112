import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';
import { ExpiredSubscriptionsModalService } from "./expired-subscriptions-modal.service";
import { ExpiredSubscription, ExpiredSubscriptionsModalState } from "./expired-subscriptions-modal.state";

@Injectable({
  providedIn: 'root'
})
export class ExpiredSubscriptionsModalStore extends Store<ExpiredSubscriptionsModalState> {

  constructor(private api: ExpiredSubscriptionsModalService) {
    super(new ExpiredSubscriptionsModalState());
  }

  fetch() {

    this.hide();

    // fetching sess token from local storage, because active exam modal component
    // is called before everything
    let token = localStorage.getItem('_puma_sess_tk');

    let fetch = this.api
      .fetch(token)
      .subscribe(

        (data: any) => {

          fetch.unsubscribe();

          let state = {

            ...this.state,
            confirming: false,
            show: false,
            subscriptions: []
          };

          if (data.subscriptions.length > 0) {

            state = {

              ...state,
              confirming: false,
              show: true,
              subscriptions: data.subscriptions.map((sub: ExpiredSubscription) => {

                sub.expired_at = new Date(sub.expired_at);
                return sub;
              })
            };
          }

          this.setState(state);
        },

        _ => {
          this.hide();
        }
      );
  }

  confirm() {

    this.setState({

      ...this.state,
      confirming: true,
    });

    // fetching sess token from local storage, because active exam modal component
    // is called before everything
    let token = localStorage.getItem('_puma_sess_tk');

    let fetch = this.api
      .confirm(token)
      .subscribe(

        (data: any) => {

          fetch.unsubscribe();
          return window.location.href = '';

        },
        _ => {
          this.hide();
        }
      );
  }

  hide() {

    let cleanState = new ExpiredSubscriptionsModalState();

    this.setState({

      ...cleanState,
      confirming: false,
      show: false,
      subscriptions: []
    });
  }
}
