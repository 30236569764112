import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewOwnerStore } from './new-owner.store';
import { Subscription } from 'rxjs';
import { TransitionService } from '../shared/services/transition.service';
import { NewOwnerState } from './new-owner.state';

@Component({
  selector: 'app-new-owner',
  templateUrl: './new-owner.component.html',
  styleUrls: ['./new-owner.component.scss']
})
export class NewOwnerComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();
  new_owner: NewOwnerState;
  status: string = null;

  constructor(private newOwnerStore: NewOwnerStore, private transitionService: TransitionService, private route: ActivatedRoute) { }

  ngOnInit() {

    let reset_password_token = this.route.snapshot.paramMap.get('reset_password_token');
    this.subscriptions.add(

      this.newOwnerStore.state$.subscribe(data => {

        if (data.action === 'valid') {

          this.status    = 'form';
          this.new_owner = data;
        }

        if (data.action === 'invalid') {
          return this.goToLogin();
        }

        if (data.action === 'reset') {
          return this.goToDashboard();
        }

        if (data.action === 'error') {

          this.status    = 'form';
          this.new_owner = data;

          window.scroll(0, 0);
        }

      }, _ => {
        this.goToLogin();
      })
    );

    this.newOwnerStore.check(reset_password_token);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  goToLogin() {

    return this.transitionService
      .navigate(['']);
  }

  goToDashboard() {

    return this.transitionService
      .navigate(['dashboard']);
  }

  newOwner(password: string, password_confirmation: string) {

    let reset_password_token = this.route.snapshot.paramMap.get('reset_password_token');

    this.newOwnerStore
      .setNewOwner(reset_password_token, this.new_owner.user, this.new_owner.company, password, password_confirmation);
  }
}
