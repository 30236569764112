import { Component, OnInit } from '@angular/core';
import { TransitionService } from '../shared/services/transition.service';
import { Subscription } from 'rxjs';
import { SettingsState } from './settings.state';
import { SettingsStore } from './settings.store';
import { UserStore } from '../shared/stores/user.store';
import { UserState } from '../shared/stores/states/user.state';
import { StaticPageState } from '../static-page/static-page.state';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  settings: SettingsState = null;

  private user: UserState = null;
  private subscriptions: Subscription = new Subscription();

  constructor(private transitionService: TransitionService, private settingsStore: SettingsStore, private userStore: UserStore) { }

  ngOnInit() {

    this.user = this.userStore.getUser();

    this.subscriptions.add(

      this.settingsStore.state$.subscribe(data => {

        if (data.action === 'initial') {
          this.settings = data;
        }
      })
    );

    this.settingsStore.fetch();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getStaticPages() {
    return this.settings.static_pages;
  }

  showCompanyLink() {
    return this.user.roles.indexOf('company') !== -1 || this.user.roles.indexOf('admin') !== -1;
  }

  logout() {

    this.userStore.logout();
    return this.goToLogin();
  }

  goToPage(page: StaticPageState) {

    return this.transitionService
      .navigate([`/account/page/${page.slug}`]);
  }

  goToSetPassword() {

    return this.transitionService
      .navigate(['set-password']);
  }

  goToLogin() {

    return this.transitionService
      .navigate(['']);
  }

  goToCompany() {

    return this.transitionService
      .navigate(['company']);
  }

  goToTreatments() {

    this.transitionService
      .navigate(['treatments']);
  }

  goToDashboard() {

    return this.transitionService
      .navigate(['dashboard']);
  }

  goToAccount() {

    return this.transitionService
      .navigate([true === this.isCompanyUser() ? 'company/account' : 'account']);
  }

  isCompanyUser() {
    return this.user.roles.indexOf('company') > -1;
  }

  showDashboardLinks() {
    return this.user.roles.indexOf('company') === -1 || this.user.roles.indexOf('admin') > 1;
  }
}
