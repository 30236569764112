<div class="nav-bar" [class.nav-bar--hidden]="navbarHidden">
  <div class="nav-bar-progressbar">
    <div class="nav-bar-progressbar__percentage" [ngStyle]="getQuizPercentageStyle()"></div>
  </div>
  <div class="nav-bar-section-wrapper">
    <section class="nav-bar-exam">
      <div class="button-container" *ngIf="isQuestionActive()">
        <a class="nav-icon nav-icon--border nav-icon--prev" (click)="goToPreviousQuestion()" [ngClass]="getPreviousQuestionLinkClass()"></a>
        <a class="nav-icon nav-icon--border nav-icon--next" (click)="goToNextQuestion()" [ngClass]="getNextQuestionLinkClass()"></a>
        <a class="nav-icon nav-icon--border nav-icon--admin" (click)="goToBackend()" *ngIf="userIsAdmin == true"></a>
        <div class="nav-bar-item nav-section nav-section--light">{{ getCurrentQuestionLabel() }}</div>
      </div>
      <div class="button-container" *ngIf="isResultQuestionActive()">
        <a class="nav-icon nav-icon--border nav-icon--prev" (click)="goToPreviousResultQuestion()" [ngClass]="getPreviousResultQuestionLinkClass()"></a>
        <a class="nav-icon nav-icon--border nav-icon--next" (click)="goToNextResultQuestion()" [ngClass]="getNextResultQuestionLinkClass()"></a>
        <a class="nav-icon nav-icon--border nav-icon--admin" (click)="goToBackend()" *ngIf="userIsAdmin == true"></a>
        <div class="nav-bar-item nav-section nav-section--light">{{ getCurrentResultQuestionLabel() }}</div>
      </div>
      <a class="nav-icon nav-icon--close" (click)="goToDashboard()"></a>
    </section>
  </div>
</div>
<section class="main-exam" *ngIf="quiz && isLoaded">
  <div class="exam-content-wrapper">
    <div class="exam-items-container">
      <div class="exam-item exam-item--message exam-item--active">
        <div class="question-container question-container--message">
          <h2>Deze toets bevat {{ getTotalQuestions() }} meerkeuzevragen. Druk op start om aan de toets te beginnen.</h2>
          <a class="round-button" (click)="startQuiz()">start overhoring</a>
        </div>
      </div>
      <div class="exam-item exam-item--question" *ngFor="let question of quiz.quiz.questions; let idx = index">
        <div class="question-container">
          <div class="question-wrapper">
            <div class="exam-question">
              <h1>{{ question.question }}</h1>
            </div>
            <ul class="exam-answers">
              <li *ngFor="let answer of question.answers" [ngClass]="getAnswerClass(question, answer)" (click)="markAnswer(question, answer)">
                <a>
                  {{ answer.answer }}
                  <ng-template [ngIf]="userIsAdmin == true && answer.correct == true">
                    <br  />
                    <small>(for admin: correct answer)</small>
                  </ng-template>
                </a>
              </li>
            </ul>
            <a class="round-button" (click)="goToNextQuestion(true)" [ngClass]="{ 'round-button--hidden': hideConfirmButton(question) }">bevestigen</a>
          </div>
        </div>
      </div>
      <div class="exam-item exam-item--message">
        <div class="question-container question-container--message">
          <h2>U heeft alle vragen beantwoord. Druk op bevestigen om uw antwoorden te verzenden.</h2>
          <div class="flex-row">
            <a class="round-button round-button--cancel" (click)="goBackToQuiz()">terug gaan</a>
            <a class="round-button" (click)="validateQuiz()">bevestigen</a>
          </div>
        </div>
      </div>
      <div class="exam-item exam-item--message">
        <div class="question-container question-container--message" *ngIf="showGraduated()">
          <div class="flex-row">
            <div class="exam-result-badge">✔</div>
            <div class="exam-result-badge">{{ getQuizGrade() }}</div>
          </div>
          <h2>U heeft {{ quiz.result.correct }} van de {{ quiz.result.total }} vragen goed beantwoord.</h2>
          <a class="question-buttons__button round-button" (click)="activateAnswers()" *ngIf="quiz.result.perfect == false">naar antwoorden</a>
          <a class="question-buttons__button round-button" (click)="goToDashboard()" *ngIf="quiz.result.perfect == true">naar hoofdmenu</a>
        </div>
        <div class="question-container question-container--message" *ngIf="showFailed()">
          <div class="flex-row">
            <div class="exam-result-badge exam-result-badge--false">✘</div>
            <div class="exam-result-badge exam-result-badge--false">{{ getQuizGrade() }}</div>
          </div>
          <h2>U heeft {{ quiz.result.correct }} van de {{ quiz.result.total }} vragen goed beantwoord.</h2>
          <a class="question-buttons__button round-button" (click)="activateAnswers()">naar antwoorden</a>
        </div>
      </div>
      <div class="exam-item exam-item--result" *ngFor="let question of quiz.quiz.questions; let idx = index">
        <ng-template [ngIf]="quiz.result">
          <div class="question-container">
            <div class="question-wrapper">
              <div class="exam-question">
                <h1>{{ question.question }}</h1>
              </div>
              <ul class="exam-answers">
                <li *ngFor="let answer of question.answers" [ngClass]="getResultAnswerClass(question, answer)">
                  <a>{{ answer.answer }}</a>
                </li>
              </ul>
            </div>
            <div class="exam-explanation">
              <b class="exam-explanation__heading">Toelichting</b>
              <p class="exam-explanation__text">{{ question.explanation }}</p>
              <a class="round-button" (click)="goToNextResultQuestion()">volgende</a>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="exam-item exam-item--message">
        <div class="question-container question-container--message">
          <h2>U heeft de overhoring afgerond.</h2>
          <div class="flex-row">
            <a class="round-button" (click)="goToDashboard()">naar hoofdmenu</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
