import { Component, OnInit, OnDestroy } from '@angular/core';
import { StaticPageStore } from './static-page.store';
import { StaticPageState } from './static-page.state';
import { ActivatedRoute, Router } from '@angular/router';
import { TransitionService } from '../shared/services/transition.service';
import { Subscription } from 'rxjs';
import { UserStore } from '../shared/stores/user.store';

@Component({
  selector: 'app-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss']
})
export class StaticPageComponent implements OnInit, OnDestroy {

  static_page: StaticPageState = null;
  backUri: string = null;

  private subscriptions: Subscription = new Subscription();

  constructor(private transitionService: TransitionService, private userStore: UserStore, private staticPageStore: StaticPageStore, private router: Router, private route: ActivatedRoute) {

    let extras = this.router.getCurrentNavigation().extras;

    if (extras.state && extras.state.backUri) {
      this.backUri = extras.state.backUri;
    }
  }

  ngOnInit() {

    let slug = this.route.snapshot.paramMap.get('slug');

    this.subscriptions.add(
      this.staticPageStore.state$.subscribe(data => {

        if (data.protected === true && false === this.userStore.isAuthenticated()) {
          return this.transitionService.navigate(['']);
        }

        this.static_page = data;
      })
    );

    this.staticPageStore.fetch(slug);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  goBack() {

    if (null !== this.backUri) {

      return this.transitionService
        .navigate([this.backUri]);
    }

    return this.transitionService
      .navigate(['settings']);
  }
}
