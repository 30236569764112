import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class TreatmentService extends ApiService {

  find(id: number) {
    return this.get(`/treatments/${id}`);
  }

  findWithPage(id: number, page_id: number) {
    return this.get(`/treatments/${page_id}/${id}`);
  }
}
