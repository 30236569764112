import { NavigationExtras, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TransitionService {

  private transitioning: boolean = false;

  constructor(private router: Router) {
  }

  navigate(route: any, extras?: NavigationExtras) {
    return this.router.navigate(route, extras);
  }

  replace(route: any) {
    return this.router.navigate(route, {replaceUrl: true});
  }

  navigateByUrl(url: string) {
    return this.router.navigateByUrl(url);
  }

  isActive(url: string) {
    return this.router.isActive(url, true);
  }



  // @deprecated
  navigateWithFade(route) {

    $('.app-holder')
      .animate({opacity: 0}, () => {
          this.router
            .navigate(route)
            .then(_ => {
              $('.app-holder').animate({opacity: 1});
            });
      });
  }

  greenRightToLeftTransition() {

    if (true === this.transitioning) {
      return;
    }

    this.transitioning = true;

    return new Promise<void>((resolve) => {

      let canvas = $('.loading-canvas');
      let circle = $('.loading-circle');

      canvas
        .css({'right': '0'});

      circle
        .delay(900)
        .fadeIn(400);

      canvas
        .animate({width: 'toggle'}, 1500, () => {

        canvas.css({'left': '0'});
        resolve();
      });
    });
  }

  displayGreenTransitionedContent() {

    if (false === this.transitioning) {
      return;
    }

    return new Promise<void>((resolve) => {

      let circle = $('.loading-circle');
      let canvas = $('.loading-canvas');

      circle
        .delay(3000)
        .fadeOut(500);

      canvas
        .delay(3000)
        .animate({width: 'toggle'}, 1500, () => {

          canvas.css({'right': '', 'left': ''});

          resolve();

          this.transitioning = false;
      });
    });
  }

  blueTransition(menu: string, slideElement: JQuery = null, customSlide: number = null) {

    return new Promise<void>((resolve) => {

      if (true === this.transitioning) {

        resolve();
        return;
      }

      this.transitioning = true;

      let menuElement = $('.end-block.' + menu);

      $('.bottom-bar .navigation-menu').css('marginRight', '350px');
      $('.end-block.' + menu + ' .ending-items').css({'display': 'block'});
      $('.end-block.' + menu).css({'display':'inline-flex'});

      if (slideElement === null) {

        menuElement.css({'opacity': '1'});
        resolve();

        this.transitioning = false;

      } else {

        slideElement.animate({marginLeft: ('-=' + (customSlide !== null ? customSlide : menuElement.width()) + 'px')}, 1000, () => {

          menuElement.css({'opacity': '1'});
            resolve();

            this.transitioning = false;
        });
      }
    });
  }

  blueRightToLeftTransition(menu: string) {

    return new Promise<void>((resolve) => {

      $('.ending-items').fadeOut(1000);
      $('.loading-circle').delay(1000).fadeIn(500);

      $('.end-block.' + menu).animate({
          width: '100%'
      }, 1500, function() {
          resolve();
      });
    });
  }

  blueRightToLeftTransitionWithoutMenu(menu: string) {

    $('.end-block.' + menu).css({'opacity': 1, 'width': '0%'}).show();
    return this.blueRightToLeftTransition(menu);
  }

  displayBlueTransitionedContent(menu: string) {

    return new Promise<void>((resolve) => {

      $('.bottom-bar .navigation-menu').css('marginRight', '0');
      $('.end-block.' + menu).css({'left':'0'});
      $('.loading-circle').delay(1000).fadeOut(500);

      $('.end-block.' + menu).delay(1500).animate({'width': 'toggle'}, 1500, function() {

        $('.end-block.' + menu).css({'left': ''});
        $('.end-block.' + menu).css({'width': '350px'});
        $('.end-block.' + menu).css({'opacity': '0'});
        $('.end-block.' + menu + ' .ending-items').css({'display': 'block'});

        resolve();
      });
    });
  }

  onBlueMenuDashboard(menu: string) {

    this.blueRightToLeftTransition(menu)
      .then(_ => {

        $('.app-holder').hide();
        return this.displayBlueTransitionedContent(menu);
      })
      .then(_ => {
        this.router.navigate(['/dashboard']);
      });
  }

  hideBlueMenu(menu: string) {

    return new Promise<void>((resolve) => {

      let block = $('.end-block.' + menu);

      if (block.css('opacity') == '0') {

        block.css({'display': 'none'});
        resolve();
        return;
      }

      $('.bottom-bar .navigation-menu').css('marginRight', '0');
      block.css({'left':''});

      setTimeout(function() {

          block.css({'opacity': '0'});

          setTimeout(function() {

            block.css({'display': 'none'});
            resolve();

          }, 1000);

      }, 10);
    });
  }
}
