import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { environment } from "src/environments/environment";
import { ENVIRONMENTS } from "../environments";
import { ApmAppService } from "./apm_app.service";

@Injectable()
export class ApmErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) {}

  handleError(error: any): void {
    this.injector.get<ApmAppService>(ApmAppService).logException(error);

    if (environment.environment !== ENVIRONMENTS.PRODUCTION) {
      console.exception(error);
    }
  }
}
