import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'nl2br'
})
export class Nl2brPipe implements PipeTransform {

  constructor(private sanitized: DomSanitizer) {}

  transform(value: string): SafeHtml {

    if (!value) {

      // if value provided isn't a string, return empty string
      return '';
    }

    return this.sanitized.bypassSecurityTrustHtml(
      value.replace(/\n/g, '<br/>')
    );
  }

}
