<div class="modal-page" [class.modal-page--hidden]="state.show != true">
  <div class="modal">
    <div class="modal-title">Verlopen cursus</div>
    <div class="modal-body">
      <ng-template ngFor let-sub [ngForOf]="state.subscriptions">
        <p>De toegang tot de cursus <strong>{{ sub.name }}</strong> is verlopen op <strong>{{ sub.expired_at|formatDate }}</strong>.<br /><br /></p>
      </ng-template>
    </div>
    <div class="modal-action">
      <a class="modal-button" [attr.data-navigation-id]="'qcare.nav.expired-subscriptions-modal.confirm'" (click)="confirm()" *ngIf="state.confirming == false">OK</a>
      <a class="modal-button" (click)="false" *ngIf="state.confirming == true">Een moment geduld...</a>
    </div>
  </div>
</div>
