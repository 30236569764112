import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ActiveExamModalStore } from 'src/app/active-exam-modal/active-exam-modal.store';
import { ExpiredSubscriptionsModalStore } from 'src/app/expired-subscriptions-modal/expired-subscriptions-modal.store';
import { UserStore } from '../stores/user.store';

@Injectable()
export class NotAuthenticatedGuard implements CanActivate {

  constructor(private router : Router, private userStore: UserStore, private activeExamModalStore: ActiveExamModalStore, private expiredSubscriptionsModalStore: ExpiredSubscriptionsModalStore) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return new Observable<boolean>(observer => {

      this.userStore.state$.subscribe(data => {

        if (data.action === 'set-user') {
          return observer.next(data.authenticated === false);
        }

        if (data.action === 'authenticated') {

          this.router.navigate(['dashboard']);
          observer.next(false);
        }

        if (data.action === 'not-authenticated') {

          this.expiredSubscriptionsModalStore.hide();
          this.activeExamModalStore.hide();

          observer.next(true);
        }

        if (data.action === 'logged-out') {
          observer.next(true);
        }
      });
    });
  }
}
