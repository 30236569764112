import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActiveExamModalState } from './active-exam-modal/active-exam-modal.state';
import { ActiveExamModalStore } from './active-exam-modal/active-exam-modal.store';
import { ConnectionStore } from './connection-lost/connection.store';
import { ApmAppService, ApmEvents } from './shared/services/apm_app.service';
import { BrowserSupport } from './shared/browser.support';
import { ExpiredSubscriptionsModalStore } from './expired-subscriptions-modal/expired-subscriptions-modal.store';
import { ExpiredSubscriptionsModalState } from './expired-subscriptions-modal/expired-subscriptions-modal.state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  hasConnection: boolean = null;
  activeExam: ActiveExamModalState = null;
  expiredSubscriptions: ExpiredSubscriptionsModalState = null;

  // appLoaded will determine whether the other components are loaded
  // into the dom, see loadedParts
  appLoaded: boolean = false;

  // if app is loaded, but connection is lost or an exam becomes active
  // this will hide the app with css, because it has already been loaded into the dom
  hideApp: boolean = true;

  // this is used to prevent unnecessary api calls, app won't be loaded
  // if loadedParts does not contain 'connection' and 'active-exam'
  loadedParts: string[] = [];

  private subscriptions: Subscription = new Subscription();

  constructor(private connectionStore: ConnectionStore, private activeExamStore: ActiveExamModalStore, private expiredSubscriptionsStore: ExpiredSubscriptionsModalStore, private apm: ApmAppService) {

    // initialize apm
    this.apm.initialize();

    // @TODO fix this so only old browsers fail, not new ones
    // but for now automatically mark as supported
    this.loadedParts.push('browser-supported');
    // check browser support
    // if (false === this.checkBrowserSupport()) {

    //   // record unsupported browser as APM event
    //   this.apm.trackEvent(ApmEvents.UNSUPPORTED_BROWSER, {
    //     userAgent: navigator.userAgent
    //   });

    //   return;
    // }

    // subscribe to stores
    this.subscriptions.add(

      this.connectionStore.state$.subscribe(status => {

        this.hasConnection = status;

        if (true === this.hasConnection && false === this.loadedParts.includes('connection')) {
          this.loadedParts.push('connection');
        }

        this.appLoaded = this.isAppLoaded();
        this.hideApp   = this.isAppHidden();
      })
    );

    this.subscriptions.add(

      this.activeExamStore.state$.subscribe(state => {

        this.activeExam = state;

        if (false === this.activeExam.show && false === this.loadedParts.includes('active-exam')) {

          // active exam modal has not been shown for the first time
          // add it to loaded parts
          this.loadedParts.push('active-exam');
        }

        this.appLoaded = this.isAppLoaded();
        this.hideApp   = this.isAppHidden();
      })
    );

    this.subscriptions.add(

      this.expiredSubscriptionsStore.state$.subscribe(state => {

        this.expiredSubscriptions = state;

        if (false === this.expiredSubscriptions.show && false === this.loadedParts.includes('expired-subscriptions')) {

          // expired subscriptions modal has not been shown for the first time
          // add it to loaded parts
          this.loadedParts.push('expired-subscriptions');
        }

        this.appLoaded = this.isAppLoaded();
        this.hideApp   = this.isAppHidden();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  checkBrowserSupport() {

    if (true === BrowserSupport.test(navigator.userAgent)) {
      this.loadedParts.push('browser-supported');
    }

    this.appLoaded = this.isAppLoaded();
    this.hideApp   = this.isAppHidden();

    return true === this.loadedParts.includes('browser-supported');
  }

  isAppLoaded() {

    if (true === this.appLoaded) {

      // app was already loaded, ignore new calls to this method
      return true;
    }

    return this.loadedParts.includes('connection') && this.loadedParts.includes('active-exam') && this.loadedParts.includes('expired-subscriptions') && this.loadedParts.includes('browser-supported');
  }

  // if app is loaded, components are loaded into the dom
  // so this method determines whether the app should be hidden
  // because of loss of connection or active exam modal is active
  isAppHidden() {

    if (null === this.expiredSubscriptions) {

      // no expired subscriptions was fetched yet, hide app
      return true;
    }

    if (true === this.expiredSubscriptions.show) {

      // expired subscriptions modal is active
      return true;
    }

    if (null === this.activeExam) {

      // no active exam was fetched yet, hide app
      return true;
    }

    if (true === this.activeExam.show) {

      // active exam modal is active
      return true;
    }

    if (false === this.hasConnection) {

      // no connection
      return true;
    }

    // app should be shown
    return false;
  }
}
