import { Component, OnInit, OnDestroy } from '@angular/core';
import { CompanyNewUserStore } from './company-new-user.store';
import { Subscription } from 'rxjs';
import { CompanyNewUserState } from './company-new-user.state';
import { TransitionService } from '../shared/services/transition.service';
import { CourseState } from '../shared/stores/states/course.state';
import { NotificationStore } from '../shared/stores/notification.store';

@Component({
  selector: 'app-company-new-user',
  templateUrl: './company-new-user.component.html',
  styleUrls: ['./company-new-user.component.scss']
})
export class CompanyNewUserComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();
  company_new_user: CompanyNewUserState = null;

  loading = false;
  errors  = [];

  constructor(private companyNewUserStore: CompanyNewUserStore, private transitionService: TransitionService, private notificationStore: NotificationStore) {}

  ngOnInit() {

    this.companyNewUserStore.reset();

    this.subscriptions.add(

      this.companyNewUserStore.state$.subscribe(data => {

        if (data.action === 'initial') {
          this.company_new_user = data;
        }

        if (data.action === 'user-added') {

          this.notificationStore.addSuccessNotification('Cursist is succesvol toegevoegd');

          if (data.credits_left === 0) {
            this.notificationStore.addErrorNotification('U heeft geen openstaand tegoed om cursussen aan werknemers toe te kennen. Indien u meer cursussen wil toekennen, neem dan contact op met uw contactpersoon bij Q Care.');
          }

          this.transitionService.navigate(['company']);
        }

        if (data.action === 'user-error') {

          this.loading = false;
          this.company_new_user = data;

          window.scrollTo(0, 0);
          this.company_new_user.errors.forEach(error => {
            this.errors.push(error);
          });
        }
      })
    );

    this.companyNewUserStore.fetch();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  showConfirmButton() {
    return false === this.loading;
  }

  getCredits() {
    return this.company_new_user.company.credits;
  }

  hasSubscription(course: CourseState) {
    return this.company_new_user.subscriptions.indexOf(course.id) > -1;
  }

  toggleSubscription(course: CourseState) {

    let idx = this.company_new_user.subscriptions.indexOf(course.id);
    if (idx > -1) {
      this.company_new_user.subscriptions.splice(idx, 1);
    } else {
      this.company_new_user.subscriptions.push(course.id);
    }
  }

  addUser() {

    this.loading = true;
    this.errors  = [];
    this.companyNewUserStore.addUser(this.company_new_user.user, this.company_new_user.subscriptions);
  }

  goToDashboard() {

    return this.transitionService
      .navigate(['dashboard']);
  }

  goToAccount() {

    this.transitionService
      .navigate(['account']);
  }

  goToSettings() {

    this.transitionService
      .navigate(['settings']);
  }
}
