import { Component, OnInit, OnDestroy, AfterViewInit, HostListener, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ReaderState } from './states/reader.state';
import { TransitionService } from '../shared/services/transition.service';
import { ProgressService } from '../shared/services/progress.service';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { ReaderStore } from './reader.store';
import { GalleryService } from './gallery.service';
import { GalleryItemState } from './states/gallery_item.state';
import { PageGalleryBlockState } from './states/page_gallery_block.state';
import { TreatmentState } from './states/treatment.state';
import { trigger, style, state, transition, animate } from '@angular/animations';
import { LoadingStore } from '../loading/loading.store';
import { environment } from 'src/environments/environment';
import { UserState } from '../shared/stores/states/user.state';
import { UserStore } from '../shared/stores/user.store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ApmAppService, ApmEvents } from '../shared/services/apm_app.service';

@Component({
  selector: 'app-reader',
  templateUrl: './reader.component.html',
  styleUrls: ['./reader.component.scss'],
  providers: [ReaderStore],
  animations: [
    trigger('infoMessageChanged', [
      state('shown', style({opacity: 1})),
      state('hidden', style({opacity: 0})),
      transition('* => *', animate('0.3s'))
    ]),
  ],
})
export class ReaderComponent implements OnInit, OnDestroy, AfterViewInit {

  initialized: boolean = false;
  infoMessageState: string = 'hidden';
  infoMessage: string = null;
  reader: ReaderState = null;
  subscriptions: Subscription = new Subscription();
  pageLoadingTimer: number = null;
  showReaderLoader: boolean = false;
  readerLoadingTimer: number = null;
  navbarHidden: boolean = true;
  loadedPages: number[] = [];
  user: UserState = null;
  isMobile: boolean = false;

  GALLERY_WIDTH_THRESHOLD: number = 680; // threshold of viewport width to enable/disable gallery

  @ViewChildren('galleryImage') galleryImageElements: QueryList<ElementRef>;

  constructor(private readerStore: ReaderStore, private userStore: UserStore, private transitionService: TransitionService, private progressService: ProgressService, private route: ActivatedRoute, private hotkeysService: HotkeysService, private galleryService: GalleryService, private loadingStore: LoadingStore, private deviceService: DeviceDetectorService, private apm: ApmAppService) {
  }

  ngOnInit() {

    this.isMobile = this.deviceService.isMobile();

    this.user = this.userStore.getUser();

    this.route.paramMap.subscribe(params => {

      let id   = parseInt(params.get('id'));
      let page = parseInt(params.get('page'));

      this.loadPage(id, page);

      this.apm.trackEvent(ApmEvents.READER_START, {

        id,
        page,
      });
    });
  }

  loadPage(id: number, page: number) {

    if (true === this.initialized) {

      // already initialized
      // activate page
      this.readerStore.activatePageById(page);
      return;
    }

    this.initialized = true;

    this.subscriptions.add(

      this.loadingStore.state$.subscribe(state => {

        if (true === this.navbarHidden && state === false) {
          this.navbarHidden = false;
        }
      })
    );

    this.subscriptions.add(

      this.readerStore.state$.subscribe(data => {

        if (data.action === 'initial') {

          this.reader = data;

          if (this.reader.reader.pages.length < 2) {

            let progress = this.progressService.reader_completed(id).subscribe(_ => {
              progress.unsubscribe();
            });

            this.apm.trackEvent(ApmEvents.READER_COMPLETED, {

              id,
              page,
            });
          }
        }

        if (data.action === 'activate-page') {

          this.reader = data;

          this.preloadImages();

          window.scroll(0, 0);

          this.apm.trackEvent(ApmEvents.READER_ACTIVATE_PAGE, {

            id,
            page,
          });
        }

        if (data.action === 'scrolled') {
          this.reader.progress = data.progress;
        }

        if (data.action === 'images-loaded') {

          this.imagesLoaded();
          this.restoreScrollPosition();
        }

        if (data.action === 'reader-completed') {

          let progress = this.progressService.reader_completed(id).subscribe(_ => {
            progress.unsubscribe();
          });

          this.apm.trackEvent(ApmEvents.READER_COMPLETED, {

            id,
            page,
          });
        }
      })
    );

    // fetching from store
    this.readerStore.fetch(id, page);

    let progress = this.progressService.reader_started(id).subscribe(_ => {
      progress.unsubscribe();
    });
  }

  ngAfterViewInit() {

    let body = $('body');

    body.on('mouseover', '[data-info-block-id]', event => {

      let id = (event.target as HTMLAnchorElement).getAttribute('data-info-block-id');

      if (id in this.reader.keywords) {

        this.infoMessage      = this.reader.keywords[id];
        this.infoMessageState = 'shown';
      }
    });

    body.on('mouseout', '[data-info-block-id]', () => {

      this.infoMessage      = null;
      this.infoMessageState = 'hidden';
    });

    this.hotkeysService.reset();
    this.hotkeysService.add(new Hotkey('right', (event: KeyboardEvent): boolean => {

      this.nextPage();
      return false;
    }));

    this.hotkeysService.add(new Hotkey('left', (event: KeyboardEvent): boolean => {

      this.previousPage();
      return false;
    }));
  }

  infoMessageChangedStart(event) {

    if (event.toState === 'shown') {
      event.element.style.display = 'block';
    }
  }

  infoMessageChangedDone(event) {

    if (event.toState === 'hidden') {
      event.element.style.display =  'none';
    }
  }

  preloadImages() {

    if (true === this.loadedPages.includes(this.reader.active.page.id)) {
      return;
    }

    this.readerStore.preloadImages();

    if (null !== this.pageLoadingTimer) {
      this.showReaderLoader = true;
    }
  }

  imagesLoaded() {

    if (true === this.loadedPages.includes(this.reader.active.page.id)) {

      this.repositionActiveGalleryImage();
      return;
    }

    this.loadedPages.push(this.reader.active.page.id);

    if (null === this.pageLoadingTimer) {

      // page wasn't loaded yet
      this.pageLoadingTimer = window.setTimeout(() => {

        this.loadingStore.hide();
        this.showReaderLoader = false;

        this.repositionActiveGalleryImage();

      }, 2000);

    } else {

      // page was loaded, using reader loader
      if (null !== this.readerLoadingTimer) {

        window.clearTimeout(this.readerLoadingTimer);
        this.readerLoadingTimer = null;
      }

      this.readerLoadingTimer = window.setTimeout(() => {

        this.showReaderLoader   = false;
        this.readerLoadingTimer = null;

        this.repositionActiveGalleryImage();

      }, 700);
    }
  }

  goToSettings() {

    return this.transitionService
      .navigate(['settings']);
  }

  nextPage() {
    this.readerStore.nextPage();
  }

  previousPage() {
    this.readerStore.previousPage();
  }

  goToBackend() {
    window.open(`${environment.backend_url}/readers/${this.reader.active.page.reader_id}/pages/${this.reader.active.page.id}`);
  }

  userIsAdmin() {
    return this.user.roles.indexOf('admin') > 1;
  }

  getNextPageLinkClass() {
    return true === this.showNextPageLink() ? [] : ['nav-icon--next--disabled'];
  }

  showNextPageLink() {
    return true === this.readerStore.hasNextPage();
  }

  getPreviousPageLinkClass() {
    return true === this.showPreviousPageLink() ? [] : ['nav-icon--prev--disabled'];
  }

  showPreviousPageLink() {
    return false === this.readerStore.isFirstPage();
  }

  showCaseLink() {
    return true === this.readerStore.isFinalPage() && this.reader.next_case !== null;
  }

  showQuizLink() {
    return true === this.readerStore.isFinalPage() && this.reader.next_case === null && this.reader.next_quiz !== null;
  }

  showExamLink() {
    return true === this.readerStore.isFinalPage() && this.reader.next_case === null && this.reader.next_quiz === null && this.reader.next_exam !== null;
  }

  showFooterDashboardLink() {
    return true === this.readerStore.isFinalPage();
  }

  getProgressBarStyle() {

    return {
      'width': `${this.reader ? this.reader.progress : 0}%`
    };
  }

  goToDashboard() {
    this.navigateWithLoadingScreen('');
  }

  goToCase() {
    this.navigateWithLoadingScreen('/case/' + this.reader.next_case);
  }

  goToQuiz() {
    this.navigateWithLoadingScreen('/quiz/' + this.reader.next_quiz);
  }

  goToExam() {
    this.navigateWithLoadingScreen('/exam/' + this.reader.next_exam);
  }

  goToTreatment(treatment: TreatmentState) {

    this.saveScrollPosition();

    // hiding navbar for transition
    this.navbarHidden = true;

    // and navigating
    this.transitionService
        .navigate([`/treatments/${this.reader.active.page.id}/${treatment.id}`]);
  }

  navigateWithLoadingScreen(route: string) {

    // hiding navbar for transition
    this.navbarHidden = true;

    // show loader
    this.loadingStore.show();

    // adding timeout to allow fade out of dashboard
    window.setTimeout(() => {

      this.transitionService
        .navigate([route]);

    }, 2000);
  }

  readerLoaded() {
    return null !== this.reader && null !== this.reader.active;
  }

  activateGalleryImage(gallery: PageGalleryBlockState, image: GalleryItemState, element: HTMLDivElement) {

    if (window.innerWidth < this.GALLERY_WIDTH_THRESHOLD) {

      // viewport is smaller than threshold, disabling activating gallery
      return;
    }

    this.galleryService.setGalleryActiveFlags(gallery, image);
    this.galleryService.activateImage(element);
  }

  @HostListener('window:resize', ['$event'])
  repositionActiveGalleryImage() {

    // reposition active gallery item when reader loads
    if (this.galleryImageElements && this.galleryImageElements.length > 0) {

      if (window.innerWidth < this.GALLERY_WIDTH_THRESHOLD) {

        // viewport is smaller than threshold, gallery becomes a vertical list
        // so disable repositioning
        this.galleryService.resetPositionGallery(this.galleryImageElements);

      } else {

        // repositioning gallery
        this.galleryService.repositionActiveImage(this.galleryImageElements);
      }
    }
  }

  getScrollPositionKey() {
    return `rp[${this.reader.reader.id}][${this.reader.active.page.id}]`;
  }

  restoreScrollPosition() {

    let key            = this.getScrollPositionKey();
    let scrollPosition = window.localStorage.getItem(key);

    if (null !== scrollPosition) {

      window.scroll(0, parseInt(scrollPosition));
      window.localStorage.removeItem(key);

    } else {
      window.scroll(0, 0);
    }
  }

  saveScrollPosition() {

    let scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    window.localStorage.setItem(this.getScrollPositionKey(), scrollPosition.toString());
  }

  ngOnDestroy() {

    this.subscriptions.unsubscribe();
    this.hotkeysService.reset();
  }

  dump(d: any) {
    // console.log(d);
  }
}
