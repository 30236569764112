import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';
import { ResetPasswordService } from "./reset-password.service";
import { ResetPasswordState } from "./reset-password.state";
import { UserStore } from "../shared/stores/user.store";
import { UserState } from "../shared/stores/states/user.state";

@Injectable()
export class ResetPasswordStore extends Store<ResetPasswordState> {

  constructor(private resetPasswordService: ResetPasswordService, private userStore: UserStore) {
    super(new ResetPasswordState());
  }

  check(reset_password_token: string) {

    let fetch = this.resetPasswordService
      .check(reset_password_token)
      .subscribe((data: any) => {

        fetch.unsubscribe();
        this.setState({

          ...this.state,
          action: 'valid',
          email: data.email,
          errors: [],
          error_fields: [],
        });

      }, _ => {

        fetch.unsubscribe();
        this.setState({

          ...this.state,
          action: 'invalid',
        });
      });
  }

  resetPassword(reset_password_token: string, password: string, password_confirmation: string) {

    let reset = this.resetPasswordService
      .reset(reset_password_token, password, password_confirmation)
      .subscribe((data: any) => {

        reset.unsubscribe();

        let user = data.user as UserState;
        user.birthday = new Date(data.user.birthday);

        this.userStore.setAuthenticated(data.session_token, user);
        this.setState({

          ...this.state,
          action: 'reset',
          errors: [],
          error_fields: [],
        });

      }, (data: any) => {

        reset.unsubscribe();

        if (data.error.errors) {

          this.setState({

            ...this.state,
            action: 'error',
            errors: data.error.errors,
            error_fields: data.error.error_fields,
          });

        } else {

          this.setState({

            ...this.state,
            action: 'invalid',
          });
        }
      });
  }
}
