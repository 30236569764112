import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';
import { StaticPageState } from "./static-page.state";
import { StaticPageService } from "./static-page.service";

@Injectable()
export class StaticPageStore extends Store<StaticPageState> {

  constructor(private staticPageService: StaticPageService) {
    super(new StaticPageState());
  }

  fetch(slug: string) {

    let fetch = this.staticPageService
      .fetch(slug)
      .subscribe((data: {static_page: StaticPageState}) => {

        fetch.unsubscribe();
        this.setState(data.static_page);

      }, error => {
        fetch.unsubscribe();
      });
  }
}
