import { Component, OnInit, OnDestroy } from '@angular/core';
import { CompanyEditUserStore } from './company-edit-user.store';
import { Subscription } from 'rxjs';
import { CompanyEditUserState } from './company-edit-user.state';
import { TransitionService } from '../shared/services/transition.service';
import { NotificationStore } from '../shared/stores/notification.store';
import { CourseState } from '../shared/stores/states/course.state';
import { ActivatedRoute } from '@angular/router';
import { CreditsState } from '../old-company/credits.state';

@Component({
  selector: 'app-company-edit-user',
  templateUrl: './company-edit-user.component.html',
  styleUrls: ['./company-edit-user.component.scss']
})
export class CompanyEditUserComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();

  company_edit_user: CompanyEditUserState = null;
  credits = [];

  loading = false;

  constructor(private companyEditUserStore: CompanyEditUserStore, private transitionService: TransitionService, private notificationStore: NotificationStore, private route: ActivatedRoute) {}

  ngOnInit() {

    this.companyEditUserStore.reset();

    this.subscriptions.add(

      this.companyEditUserStore.state$.subscribe(data => {

        if (data.action === 'initial') {

          this.company_edit_user = data;

          for (let credit of this.company_edit_user.credits) {

            let creditData = {

              id: credit.id,
              course: credit.course,
              credits: credit.credits,
              subscribed: this.company_edit_user.subscriptions.indexOf(credit.course.id) > -1,
              checked: this.company_edit_user.subscriptions.indexOf(credit.course.id) > -1,
              locked: false
            };

            if (creditData.credits === 0 || true === creditData.subscribed) {
              creditData.locked = true;
            }

            this.credits.push(creditData);
          }
        }

        if (data.action === 'user-updated') {

          if (data.credits_left === 0) {

            this.notificationStore.addSuccessNotification('Cursist is succesvol aangepast.');
            this.notificationStore.addErrorNotification('U heeft geen openstaand tegoed om cursussen aan werknemers toe te kennen. Indien u meer cursussen wil toekennen, neem dan contact op met uw contactpersoon bij Q Care.');

          } else {
            this.notificationStore.addSuccessNotification(`Cursist is succesvol aangepast. U heeft nog ${data.credits_left} tegoed openstaan.`);
          }

          this.transitionService.navigate(['company']);
        }

        if (data.action === 'user-error') {
          this.company_edit_user = data;
        }
      })
    );

    let id = this.route.snapshot.paramMap.get('id');
    this.companyEditUserStore.fetch(parseInt(id));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  showConfirmButton() {
    return false === this.loading;
  }

  toggleSubscription(credit: CreditsState) {

    let idx = this.credits.findIndex(c => c.id === credit.id);

    if (idx === -1 ) {

      // credit not found
      return;
    }

    if (this.credits[idx].credits === 0) {
      return;
    }

    if (true === this.credits[idx].subscribed) {
      return;

    }

    if (this.credits[idx].credits > 0) {

      // user has a subscription or company has credits
      // therefore it is allowed to toggle
      let sidx = this.company_edit_user.subscriptions.findIndex(c => c === credit.course.id);

      if (sidx > -1) {

        this.company_edit_user.subscriptions.splice(sidx, 1);
        this.credits[idx].checked = false;

      } else {

        this.company_edit_user.subscriptions.push(credit.course.id);
        this.credits[idx].checked = true;
      }
    }
  }

  updateUser() {

    this.loading = true;
    this.companyEditUserStore.updateUser(this.company_edit_user);
  }

  goToDashboard() {

    return this.transitionService
      .navigate(['dashboard']);
  }

  goToAccount() {

    this.transitionService
      .navigate(['account']);
  }

  goToSettings() {

    this.transitionService
      .navigate(['settings']);
  }
}
