import { AnswerState } from "./answer.state";

export enum WeightEnum {

  LOW    = 'low',
  MEDIUM = 'medium',
  HIGH   = 'high'
};

export enum ScoreEnum {

  LOW    = 0.5,
  MEDIUM = 0.75,
  HIGH   = 1
};

export const ScoreByWeight = {

    [WeightEnum.LOW]: ScoreEnum.LOW,
    [WeightEnum.MEDIUM]: ScoreEnum.MEDIUM,
    [WeightEnum.HIGH]: ScoreEnum.HIGH
};

export class QuestionState {

  id: number;
  question_pool_id: number;
  question: string;
  weight: WeightEnum;
  rank: number;
  explanation: string;
  answers: AnswerState[];
  created_at: Date;
  updated_at: Date;
}
