import { Injectable } from "@angular/core";
import { Store } from './store';
import { SessionState } from './states/session.state';
import { UserState } from "./states/user.state";
import { LoginService } from "../../login/login.service";
import { ApmAppService } from "../services/apm_app.service";

@Injectable({
    providedIn: 'root'
})
export class UserStore extends Store<SessionState> {

    constructor(private loginService: LoginService, private apmAppService: ApmAppService) {

        super(new SessionState());
        this.restoreUser();
    }

    restoreUser() {

        let token = localStorage.getItem('_puma_sess_tk');

        if (token) {

            this.loginService.checkSession(token).subscribe(

                (data: {type: string, user: any}) => {

                    if (data.type == 'success') {

                        let user = data.user as UserState;
                        user.birthday = new Date(data.user.birthday);

                        this.setAuthenticated(token, user);

                    } else {
                        this.notAuthenticated();
                    }
                },
                _ => {
                    this.notAuthenticated();
                }
            );

        } else {
            this.notAuthenticated();
        }
    }

    getUser() {
        return this.state.user;
    }

    isAuthenticated() {
        return true === this.state.authenticated;
    }

    setUser(user: UserState) {

        this.setState({

            ...this.state,
            action: 'set-user',
            user: user
        });
    }

    getSessionToken() {
        return this.state.session_token;
    }

    setAuthenticated(session_token: string, user: UserState): void {

        this.apmAppService.setUserContext(user);

        localStorage.setItem('_puma_sess_tk', session_token);

        this.setState({

            ...this.state,
            action: 'authenticated',
            session_token: session_token,
            authenticated: true,
            user: user,
        });
    }

    notAuthenticated() {

        this.apmAppService.removeUserContext();

        localStorage.removeItem('_puma_sess_tk');

        this.setState({

            ...this.state,
            action: 'not-authenticated',
            session_token: null,
            authenticated: false,
            user: null,
        });
    }

    logout() {

        this.apmAppService.removeUserContext();

        localStorage.removeItem('_puma_sess_tk');

        this.setState({

            ...this.state,
            action: 'logged-out',
            session_token: null,
            authenticated: false,
            user: null,
        });
    }
}
