import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';
import { SettingsService } from "./settings.service";
import { SettingsState } from "./settings.state";

@Injectable()
export class SettingsStore extends Store<SettingsState> {

  constructor(private settingsService: SettingsService) {
    super(new SettingsState());
  }

  fetch() {

    let fetch = this.settingsService
      .fetch()
      .subscribe((data: any) => {

        fetch.unsubscribe();

        this.setState({

          ...this.state,
          action: 'initial',
          static_pages: data.static_pages
        });

      }, (data: any) => {
        fetch.unsubscribe();
      });
  }
}
