import { Injectable } from "@angular/core";
import { io, Socket } from 'socket.io-client';
import { environment } from "src/environments/environment";
import { UserStore } from "../stores/user.store";

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private io: Socket = null;

  constructor(private userStore: UserStore) {
  }

  get socket() {

    if (null !== this.io) {
      return this.io;
    }

    this.io = io(`${environment.backend_url}`, {

      path: '/qs/',
      auth: (cb) => {
        cb({
          token: this.userStore.getSessionToken(),
        });
      },
    });

    this.io.on("connect_error", (err) => {
      // console.log(err.message);
    });

    return this.io;
  }

  disconnect() {

    this.io.disconnect();
    this.io = null;
  }
}
