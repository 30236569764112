import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ReaderService extends ApiService {

  fetch(id: number) {
    return this.get(`/readers/${id}`)
  }
}
