import { Component, OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { UserStore } from '../shared/stores/user.store';
import { TransitionService } from '../shared/services/transition.service';
import { UserState } from '../shared/stores/states/user.state';
import { ApmAppService, ApmEvents } from '../shared/services/apm_app.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loading: boolean = false;
  success: boolean = false;
  error: boolean = false;

  constructor(private loginService: LoginService, private userStore: UserStore, private transitionService: TransitionService, private apm: ApmAppService) {}

  ngOnInit() {
  }

  login(email: string, password: string) {

    this.loading = true;

    let authenticated = this.loginService.authenticate(email, password)
      .subscribe((data: {session_token: string, user: any}) => {

        this.error   = false;
        this.loading = false;
        this.success = true;

        authenticated.unsubscribe();

        let user = data.user as UserState;
        user.birthday = new Date(data.user.birthday);

        this.userStore.setAuthenticated(data.session_token, user);

        this.apm.trackEvent(ApmEvents.LOGIN_SUCCESS);
        this.apm.trackEvent(ApmEvents.LOGIN_REDIRECT_DASHBOARD);

        this.transitionService
          .navigate(['dashboard']);

      }, _ => {

        this.loading = false;
        this.success = false;
        this.error   = true;

        this.apm.trackEvent(ApmEvents.LOGIN_FAILED);
      });

    this.apm.trackEvent(ApmEvents.LOGIN_START);
  }

  goToForgotPassword() {

    this.apm.trackEvent(ApmEvents.LOGIN_REDIRECT_FORGOT_PASSWORD);

    return this.transitionService
      .navigate(['forgot']);
  }
}
