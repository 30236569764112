import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';
import { TreatmentsState } from "./treatments.state";
import { TreatmentsService } from "./treatments.service";

@Injectable({
  providedIn: 'root'
})
export class TreatmentsStore extends Store<TreatmentsState> {

  constructor(private treatmentsService: TreatmentsService) {

    super(new TreatmentsState());

    let fetch = this.treatmentsService
      .all()
      .subscribe((data: any) => {

        fetch.unsubscribe();

        this.setState({

          ...this.state,
          action: 'initial',
          treatments: data.treatments
        });
      });
  }
}
