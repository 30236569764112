<section class="login">
  <div class="login-wrapper">
    <div class="login-header">
      <div class="login-header__logo"></div>
      <small>wachtwoord vergeten</small>
    </div>
    <div class="login-window">
      <form class="login-form login-form--reset">
        <div class="login-reset-text" *ngIf="sent">er is een link naar uw e-mailadres verzonden</div>
        <ng-container *ngIf="!sent">
          <div class="login-credentials">
            <input type="email" placeholder="e-mailadres" class="login-input" #email>
          </div>
          <div class="login-submit">
            <input type="submit" value="bevestigen" (click)="forgotPassword(email.value)" *ngIf="!loading">
            <input type="submit" value="bezig met aanvragen..." *ngIf="loading">
          </div>
        </ng-container>
        <div class="login-submit" *ngIf="sent">
          <input type="submit" value="terug" (click)="goToLogin()">
        </div>
      </form>
    </div>
    <div class="login-bottom-link" *ngIf="!sent">
      <a class="login-bottom-link--grey" (click)="goToLogin()">
        inloggen
      </a>
    </div>
  </div>
</section>
