import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoadingStore } from './loading.store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();
  public show: boolean = false;
  public showHiddenClass: boolean = true;

  constructor(private store: LoadingStore) {}

  ngOnInit(): void {

    this.subscriptions.add(

      this.store.state$.subscribe(state => {

        let showHiddenClass = true;
        if (this.show === false && state === true) {
          showHiddenClass = false;
        }

        // first adding component
        this.show = state;

        // then removing the hidden class to trigger css animation
        window.setTimeout(() => this.showHiddenClass = showHiddenClass, 50);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
