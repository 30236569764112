import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { LoginService } from '../../login/login.service';
import { UserStore } from '../stores/user.store';
import { UserState } from '../stores/states/user.state';
import { ActiveExamModalStore } from 'src/app/active-exam-modal/active-exam-modal.store';
import { ExpiredSubscriptionsModalStore } from 'src/app/expired-subscriptions-modal/expired-subscriptions-modal.store';

@Injectable()
export class ForceNotAuthenticatedGuard implements CanActivate {

  constructor(private router : Router, private userStore: UserStore, private activeExamModalStore: ActiveExamModalStore, private expiredSubscriptionsModalStore: ExpiredSubscriptionsModalStore) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    this.expiredSubscriptionsModalStore.hide();
    this.activeExamModalStore.hide();

    this.userStore.logout();
    return of(true);
  }
}
