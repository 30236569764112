import { Injectable } from "@angular/core";
import { Store } from "../shared/stores/store";
import { ConfirmModalState } from "./confirm-modal.state";

@Injectable({
  providedIn: 'root'
})
export class ConfirmModalStore extends Store<ConfirmModalState> {

  constructor() {
      super(new ConfirmModalState());
  }

  create(modal: ConfirmModalState) {
    this.setState(modal);
  }

  close () {
    this.setState(new ConfirmModalState());
  }

  createNewUserExpired(email: string) {

    this.create(
      {
        id: 'new-user-expired',
        show: true,
        title: 'Uw activatie is verlopen',
        description: `Om veiligheidsredenen verloopt uw activatiemail 7 dagen na ontvangst.\n\nWe hebben zojuist een nieuwe activatiemail gestuurd naar ${email} waarmee u uw e-learning account kan activeren.\n\nAls u binnen enkele minuten geen mail heeft ontvangen, kijk dan in uw spam of map met ongewenste e-mail.`,
        label: 'OK'

      } as ConfirmModalState
    );
  }
}
