import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';
import { NewUserService } from "./new-user.service";
import { NewUserState } from "./new-user.state";
import { UserStore } from "../shared/stores/user.store";
import { UserState } from "../shared/stores/states/user.state";

@Injectable()
export class NewUserStore extends Store<NewUserState> {

  constructor(private newUserService: NewUserService, private userStore: UserStore) {
    super(new NewUserState());
  }

  check(new_user_token: string) {

    let fetch = this.newUserService
      .check(new_user_token)
      .subscribe((data: any) => {

        fetch.unsubscribe();

        if (data.refreshed === true) {

          // token was expired, has been refreshed
          this.setState({

            ...this.state,
            action: 'refreshed',
            user: {
              ...new UserState(),
              email: data.user.email
            }
          });

        } else {

          // token wasn't refreshed
          if (data.user === null) {

            // token wasn't valid, mark invalid
            this.setState({

              ...this.state,
              action: 'invalid',
            });

          } else {

            // user was not found, token was invalid
            this.setState({

              ...this.state,
              action: 'valid',
              user: data.user,
              errors: [],
              error_fields: []
            });
          }
        }

      }, _ => {

        fetch.unsubscribe();
        this.setState({

          ...this.state,
          action: 'invalid',
        });
      });
  }

  setNewUser(new_user_token: string, user: UserState, password: string, password_confirmation: string) {

    let reset = this.newUserService
      .setNewUser(new_user_token, user, password, password_confirmation)
      .subscribe((data: any) => {

        reset.unsubscribe();

        let user = data.user as UserState;
        user.birthday = new Date(data.user.birthday);

        this.userStore.setAuthenticated(data.session_token, user);
        this.setState({

          ...this.state,
          action: 'reset',
          user: user,
          errors: [],
          error_fields: [],
        });

      }, (data: any) => {

        reset.unsubscribe();

        if (data.error.errors) {

          this.setState({

            ...this.state,
            action: 'error',
            errors: data.error.errors,
            error_fields: data.error.error_fields,
          });

        } else {

          this.setState({

            ...this.state,
            action: 'invalid',
          });
        }
      });
  }
}
