import { DatepickerOptions } from "ng2-datepicker";
import locale from 'date-fns/locale/nl';

export const DatePickerOptions: DatepickerOptions = {

  placeholder: 'dd/MM/yyyy',
  format: 'dd/MM/yyyy',
  firstCalendarDay: 1, // start calendar on monday
  locale: locale,
};
