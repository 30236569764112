import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';
import { NewOwnerService } from "./new-owner.service";
import { NewOwnerState } from "./new-owner.state";
import { UserStore } from "../shared/stores/user.store";
import { UserState } from "../shared/stores/states/user.state";
import { CompanyState } from "../shared/stores/states/company.state";

@Injectable()
export class NewOwnerStore extends Store<NewOwnerState> {

  constructor(private newOwnerService: NewOwnerService, private userStore: UserStore) {
    super(new NewOwnerState());
  }

  check(reset_password_token: string) {

    let fetch = this.newOwnerService
      .check(reset_password_token)
      .subscribe((data: any) => {

        fetch.unsubscribe();
        this.setState({

          ...this.state,
          action: 'valid',
          user: data.user,
          company: data.company,
          errors: [],
          error_fields: [],
        });

      }, _ => {

        fetch.unsubscribe();
        this.setState({

          ...this.state,
          action: 'invalid',
        });
      });
  }

  setNewOwner(reset_password_token: string, user: UserState, company: CompanyState, password: string, password_confirmation: string) {

    let reset = this.newOwnerService
      .setNewOwner(reset_password_token, user, company, password, password_confirmation)
      .subscribe((data: any) => {

        reset.unsubscribe();

        let user = data.user as UserState;
        user.birthday = new Date(data.user.birthday);

        this.userStore.setAuthenticated(data.session_token, user);
        this.setState({

          ...this.state,
          action: 'reset',
          user: user,
          errors: [],
          error_fields: [],
        });

      }, (data: any) => {

        reset.unsubscribe();
        this.setState({

          ...this.state,
          action: 'error',
          errors: data.error.errors,
          error_fields: data.error.error_fields,
        });
      });
  }
}
