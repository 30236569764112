import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class SetPasswordService extends ApiService {

  set(session_token: string, password: string, password_confirmation: string) {

    return this.post('/sessions/set_password', {

      session_token: session_token,
      set_password: {

        password,
        password_confirmation
      }
    });
  }
}
