import { UserState } from "../shared/stores/states/user.state";

export class NewUserState {

  action: string;
  user: UserState;
  errors: string[] = [];
  error_fields: string[] = [];
  terms: boolean = false;
}
