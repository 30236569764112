<div class="modal-page" [class.modal-page--hidden]="state.show != true">
  <div class="modal">
    <div class="modal-title">Lopend examen</div>
    <div class="modal-body" *ngIf="state.status == isRunning">
      U bent begonnen met examen {{ state.name }}. U heeft nog
      <app-timer seconds="{{ state.remaining_time }}" (done)="timerFinished()" (ticker)="timerTick($event)" #timer></app-timer>
      de tijd.
    </div>
    <div class="modal-body" *ngIf="state.status == isFinished">
      U was bezig met examen {{ state.name }}. De resterende tijd is verlopen.
    </div>
    <div class="modal-action">
      <a class="modal-button" [attr.data-navigation-id]="'qcare.nav.active-exam-modal.exam'" (click)="goToExam()">Terug naar examen</a>
    </div>
  </div>
</div>
