import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService extends ApiService {

  check(reset_password_token: string) {
    return this.get(`/sessions/reset_password/${reset_password_token}`);
  }

  reset(reset_password_token: string, password: string, password_confirmation: string) {

    return this.post(`/sessions/reset_password/${reset_password_token}`, {

      reset_password: {

        password,
        password_confirmation
      }
    });
  }
}
