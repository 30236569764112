import { CourseModuleItemState } from './course-module-item.state';

export enum CourseKind {

  PORTRAIT  = 'portrait',
  LANDSCAPE = 'landscape'
}

export class CourseState {

  id: number;
  course_id: number;
  section_id: number;
  course: {
    id: number;
    name: string;
    rank: number;
    price: number;
  };
  name: string;
  rank: number = 1;
  kind: CourseKind;
  description: string;
  duration_hours: number;
  duration_minutes: number;
  image ?: string;
  course_module_items: CourseModuleItemState[];
  created_at: Date;
  updated_at: Date;
}
