<div class="nav-bar">
  <section class="nav-bar-business">
    <div class="button-container">
      <div class="nav-bar-item nav-section">Q Care Academie Portaal</div>
    </div>
    <div class="button-container">
      <a class="nav-icon nav-icon--account" [attr.data-navigation-id]="'qcare.nav.company-new-user.account'" (click)="goToAccount()"></a>
      <a class="nav-icon nav-icon--settings" [attr.data-navigation-id]="'qcare.nav.company-new-user.settings'" (click)="goToSettings()"></a>
    </div>
  </section>
</div>
<section class="business-main business-user" *ngIf="company_new_user">
  <div class="business-wrapper">
    <div class="menu-message__wrapper menu-message__wrapper--red" *ngIf="errors.length > 0">
      <img src="/assets/images/info.svg" class="menu-message__icon">
      <p class="menu-message__text">
        <ng-template ngFor let-message [ngForOf]="errors">{{ message }} <br /></ng-template>
      </p>
    </div>
    <div class="business-top-message">
      <div class="business-top-message__text">
        <h1>Cursist Toevoegen</h1>
        <p>U kunt hier persoonsgegevens instellen van uw werknemer en u kunt cursussen toekennen.</p>
      </div>
    </div>
    <div class="personalia-item">
      <form class="settings-form">
        <div class="settings-form__item" [class.settings-form__item--error]="company_new_user.error_fields.includes('initials')">
          <p>Voorletters*</p>
          <input type="text" name="initials" placeholder="Vul voorletters in" class="login-input" [(ngModel)]="company_new_user.user.initials">
        </div>
        <div class="settings-form__item" [class.settings-form__item--error]="company_new_user.error_fields.includes('last_name')">
          <p>Achternaam*</p>
          <input type="text" name="last_name" placeholder="Vul achternaam in" class="login-input" [(ngModel)]="company_new_user.user.last_name">
        </div>
        <div class="settings-form__item" [class.settings-form__item--error]="company_new_user.error_fields.includes('email')">
          <p>E-mailadres*</p>
          <input type="email" name="email" placeholder="Vul e-mailadres in" class="login-input" [(ngModel)]="company_new_user.user.email">
        </div>
        <span class="settings-form-divider">Selecteer de cursussen die u wilt toekennen</span>
        <div class="settings-form__item" [class.settings-form__item--locked]="credit.credits == 0 && false == hasSubscription(credit.course)" *ngFor="let credit of getCredits()">
          <div class="settings-form__checkbox">
            <input type="checkbox" id="subscription_{{ credit.course.id }}" [disabled]="credit.credits == 0 && false == hasSubscription(credit.course)" [checked]="hasSubscription(credit.course)" (change)="toggleSubscription(credit.course)">
            <label for="subscription_{{ credit.course.id }}"></label>
            <p>{{ credit.course.name }}</p>
          </div>
        </div>
        <div class="settings-form__item ">
          <input type="submit" value="een moment geduld" *ngIf="!showConfirmButton()">
          <input type="submit" value="opslaan" [attr.data-navigation-id]="'qcare.nav.company-new-user.add'" (click)="addUser()" *ngIf="showConfirmButton()">
        </div>
      </form>
    </div>
  </div>
</section>
