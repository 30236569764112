import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResetPasswordStore } from './reset-password.store';
import { Subscription } from 'rxjs';
import { TransitionService } from '../shared/services/transition.service';
import { ResetPasswordState } from './reset-password.state';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();
  reset_password: ResetPasswordState;
  status: string = null;

  constructor(private resetPasswordStore: ResetPasswordStore, private transitionService: TransitionService, private route: ActivatedRoute) { }

  ngOnInit() {

    let reset_password_token = this.route.snapshot.paramMap.get('reset_password_token');
    this.subscriptions.add(

      this.resetPasswordStore.state$.subscribe(data => {

        if (data.action === 'valid') {

          this.reset_password = data;
          this.status = 'form';
        }

        if (data.action === 'invalid') {
          return this.goToLogin();
        }

        if (data.action === 'reset') {
          this.status = 'success';
        }

        if (data.action === 'error') {

          this.reset_password = data;
          this.status = 'form';
        }

      }, _ => {
        this.goToLogin();
      })
    );

    this.resetPasswordStore.check(reset_password_token);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  goToLogin() {

    return this.transitionService
      .navigate(['']);
  }

  goToDashboard() {

    return this.transitionService
      .navigate(['dashboard']);
  }

  resetPassword(password: string, password_confirmation: string) {

    let reset_password_token = this.route.snapshot.paramMap.get('reset_password_token');

    this.resetPasswordStore
      .resetPassword(reset_password_token, password, password_confirmation);
  }
}
