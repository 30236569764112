import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TransitionService } from '../shared/services/transition.service';
import { AccountStore } from './account.store';
import { AccountState, DiplomaStatus } from './account.state';
import { UserState } from '../shared/stores/states/user.state';
import { UserStore } from '../shared/stores/user.store';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {

  account: AccountState    = null;
  user: UserState          = null;
  loading: boolean         = false;
  saved: boolean           = false;
  errors: string[]         = [];
  pendingDiplomaButtonLabel: string = 'Een moment geduld aub.';
  pendingDiplomaButtonLabelTimer: number = null;
  datepicker_options = environment.datepicker_options;

  DIPLOMA_UNAVAILABLE: DiplomaStatus = DiplomaStatus.UNAVAILABLE;
  DIPLOMA_PENDING: DiplomaStatus = DiplomaStatus.PENDING;
  DIPLOMA_AVAILABLE: DiplomaStatus = DiplomaStatus.AVAILABLE;

  private subscriptions: Subscription = new Subscription();

  constructor(private transitionService: TransitionService, private accountStore: AccountStore, private userStore: UserStore) {}

  ngOnInit() {

    this.user = this.userStore.getUser();
    this.subscriptions.add(

      this.accountStore.state$.subscribe(data => {

        if (data.action === 'initial') {

          this.account = data;
          this.loading = false;
          this.errors  = [];
          this.saved   = false;

          window.scrollTo(0, 0);
        }

        if (data.action === 'saved-account') {

          this.account = data;
          this.loading = false;
          this.errors  = [];
          this.saved   = true;

          window.scrollTo(0, 0);
        }

        if (data.action === 'error') {

          this.account = data;
          this.loading = false;
          this.errors  = data.errors;
          this.saved   = false;

          window.scrollTo(0, 0);
        }

        if (data.action === 'diploma') {

          this.account = data;
          this.loading = false;
          this.errors  = data.errors;
          this.saved   = false;

          window.scrollTo(0, 0);
        }
      })
    );

    this.accountStore.initialize();
    this.startPendingButtonLabelTimer();
  }

  ngOnDestroy() {

    this.subscriptions.unsubscribe();
    this.accountStore.destroy();

    if (null !== this.pendingDiplomaButtonLabelTimer) {
      window.clearInterval(this.pendingDiplomaButtonLabelTimer);
    }
  }

  startPendingButtonLabelTimer() {

    let colons = 0;
    this.pendingDiplomaButtonLabelTimer = window.setInterval(() => {

      colons += 1;
      this.pendingDiplomaButtonLabel = `Een moment geduld aub${".".repeat(colons)}`;

      if (colons === 3) {
        colons = 0;
      }

    }, 1000);
  }

  goToDashboard() {

    return this.transitionService
      .navigate(['dashboard']);
  }

  goToTreatments() {

    this.transitionService
      .navigate(['treatments']);
  }

  goToSettings() {

    return this.transitionService
      .navigate(['settings']);
  }

  showConfirmButton() {
    return false === this.loading;
  }

  showAccountPage() {
    return null !== this.account;
  }

  accountSaved() {
    return true === this.saved;
  }

  saveAccount() {

    this.loading = true;
    this.accountStore.saveAccount(this.user);
  }

  getDiploma(id: number) {
    return this.accountStore.getDiploma(id);
  }

  debug(data: any) {
    // console.log(data);
  }
}
