<div class="nav-bar">
  <section class="nav-bar-business">
    <div class="button-container">
      <div class="nav-bar-item nav-section">Q Care Academie Portaal</div>
    </div>
    <div class="button-container">
      <a class="nav-icon nav-icon--account" [attr.data-navigation-id]="'qcare.nav.company-edit-user.account'" (click)="goToAccount()"></a>
      <a class="nav-icon nav-icon--settings" [attr.data-navigation-id]="'qcare.nav.company-edit-user.settings'" (click)="goToSettings()"></a>
    </div>
  </section>
</div>
<section class="business-main business-user" *ngIf="company_edit_user">
  <div class="business-wrapper">
    <div class="business-top-message">
      <div class="business-top-message__text">
        <h1>Informatie Wijzigen</h1>
        <p>U kunt hier persoonsgegevens instellen van uw werknemer en u kunt cursussen toekennen.</p>
      </div>
    </div>
    <div class="personalia-item">
      <form class="settings-form">
        <div class="settings-form__item">
          <p>Aanhef*</p>
          <select name="title" [(ngModel)]="company_edit_user.user.title">
            <option value="" disabled>Kies uw aanhef</option>
            <option value="Dhr.">Dhr.</option>
            <option value="Mevr.">Mevr.</option>
          </select>
        </div>
        <div class="settings-form__item">
          <p>Voorletters*</p>
          <input type="text" name="initials" placeholder="Vul voorletters in" class="login-input" [(ngModel)]="company_edit_user.user.initials">
        </div>
        <div class="settings-form__item">
          <p>Achternaam*</p>
          <input type="text" name="last_name" placeholder="Vul achternaam in" class="login-input" [(ngModel)]="company_edit_user.user.last_name">
        </div>
        <div class="settings-form__item settings-form__item--locked">
          <p>E-mailadres</p>
          <input type="email" name="email" class="login-input" [value]="company_edit_user.user.email" disabled>
        </div>
        <span class="settings-form-divider">Selecteer de cursussen die u wilt toekennen</span>
        <div class="settings-form__item" [ngClass]="{'settings-form__item--locked': true == credit.locked}" *ngFor="let credit of credits">
          <div class="settings-form__checkbox" (click)="toggleSubscription(credit)">
            <input type="checkbox" name="subscription_{{ credit.course.id }}" [(ngModel)]="credit.checked">
            <label for="subscription_{{ credit.course.id }}"></label>
            <p>{{ credit.course.name }}</p>
          </div>
        </div>
        <div class="settings-form__item ">
          <input type="submit" value="een moment geduld" *ngIf="!showConfirmButton()">
          <input type="submit" value="wijzigen" [attr.data-navigation-id]="'qcare.nav.company-edit-user.update'" (click)="updateUser()" *ngIf="showConfirmButton()">
        </div>
      </form>
    </div>
  </div>
</section>
