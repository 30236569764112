import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends ApiService {

  fetch(session_token: string) {
    return this.post('/notifications', {session_token});
  }

  clear(session_token: string) {

    let clear = this
      .destroy(`/notifications`, {session_token})
      .subscribe(

        _ => {
          clear.unsubscribe();
        },

        error => {
          clear.unsubscribe();
        });
  }
}
