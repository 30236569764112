import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class QuizService extends ApiService {

  fetch(id: number) {
    return this.get(`/quizzes/${id}`);
  }
}
