import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';
import { AccountState, DiplomaStatus } from "./account.state";
import { AccountService } from "./account.service";
import { UserStore } from "../shared/stores/user.store";
import { UserState } from "../shared/stores/states/user.state";
import { SocketService } from "../shared/services/socket.service";
import { NotificationStore } from "../shared/stores/notification.store";

@Injectable()
export class AccountStore extends Store<AccountState> {

  constructor(private accountService: AccountService, private userStore: UserStore, private socketService: SocketService, private notificationStore: NotificationStore) {
    super(new AccountState());
  }

  initialize() {

    // fetching account
    this.fetch();

    // and connecting to websocket
    this.listen();
  }

  fetch() {

    let fetch = this.accountService
      .fetch(this.userStore.getSessionToken())
      .subscribe((data: any) => {

        fetch.unsubscribe();

        this.setState({

          ...this.state,
          action: 'initial',
          errors: [],
          error_fields: [],
          subscriptions: data.subscriptions
        });
      });
  }

  listen() {

    this.socketService.socket.on('diplomas.generated', (message) => {

      if (message.subscription_id) {

        let subscriptions = this.state.subscriptions.map((subscription) => {

          if (subscription.id === message.subscription_id) {
            subscription.diploma = message.diploma_status;
          }

          return subscription;
        });

        this.setState({

          ...this.state,
          action: 'diploma',
          subscriptions: subscriptions,
        });
      }
    });
  }

  destroy() {
    this.socketService.disconnect();
  }

  saveAccount(user: UserState) {

    let save = this.accountService
      .update(user, this.userStore.getSessionToken())
      .subscribe((data: any) => {

        save.unsubscribe();

        this.userStore.setUser(data.user);
        this.setState({

          ...this.state,
          action: 'saved-account',
          errors: [],
          error_fields: [],
        });

      }, (error) => {

        save.unsubscribe();

        this.setState({

          ...this.state,
          action: 'error',
          errors: error.error.errors,
          error_fields: error.error.error_fields,
        });
      });
  }

  getDiploma(subscriptionId: number) {

    this.markDiplomaState(subscriptionId, DiplomaStatus.PENDING);

    let diploma = this.accountService
      .getDiploma(subscriptionId, this.userStore.getSessionToken())
      .subscribe((data: any) => {

        diploma.unsubscribe();

        if (data.diploma) {

          let status = data.diploma.status as DiplomaStatus;
          this.markDiplomaState(subscriptionId, status);

          if (status === DiplomaStatus.AVAILABLE) {
            window.open(data.diploma.uri);
          }

        } else {
          this.notificationStore.addErrorNotification('Er ging iets mis, probeer het later opnieuw');
        }

      }, (error: any) => {

        diploma.unsubscribe();
        this.markDiplomaState(subscriptionId, DiplomaStatus.UNAVAILABLE);

        this.notificationStore.addErrorNotification('Er ging iets mis, probeer het later opnieuw');
      });
  }

  markDiplomaState(subscriptionId: number, diplomaStatus: DiplomaStatus) {

    let subscriptions = this.state.subscriptions.map(subscription => {

      if (subscription.id === subscriptionId) {
        subscription.diploma = diplomaStatus;
      }

      return subscription;
    });

    this.setState({

      ...this.state,
      action: 'diploma',
      subscriptions: subscriptions
    });
  }
}
