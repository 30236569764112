<div class="disable-pointer-events reader-loading" *ngIf="showReaderLoader">
  <div class="reader-loading-wrapper">
    <div class="reader-loading__circle"></div>
    <svg class="reader-loading__circle" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" r="32" stroke="rgb(240, 240, 240)" stroke-width="10" fill="none"></circle>
      <circle class="reader-loading-animation__circle__stroke" cx="50" cy="50" r="32" stroke="#d1d1d1" stroke-dasharray="50 100" stroke-width="10" stroke-linecap="square" fill="none" transform="rotate(497.745 50 50)"></circle>
    </svg>
  </div>
</div>
<div class="nav-bar" [class.nav-bar--hidden]="navbarHidden">
  <div class="nav-bar-progressbar">
    <div class="nav-bar-progressbar__percentage" [ngStyle]="getProgressBarStyle()"></div>
  </div>
  <div class="nav-bar-section-wrapper">
    <section class="nav-bar-reader" *ngIf="!readerLoaded()">
      <div class="button-container">
        <a class="nav-icon nav-icon--border nav-icon--menu" (click)="goToDashboard()"></a>
        <div class="nav-bar-item nav-section">Loading reader</div>
      </div>
      <a class="nav-icon nav-icon--settings" (click)="goToSettings()"></a>
    </section>
    <section class="nav-bar-reader" *ngIf="readerLoaded()">
      <div class="button-container">
        <a class="nav-icon nav-icon--border nav-icon--prev" (click)="previousPage()" [ngClass]="getPreviousPageLinkClass()"></a>
        <a class="nav-icon nav-icon--border nav-icon--next" (click)="nextPage()" [ngClass]="getNextPageLinkClass()"></a>
        <a class="nav-icon nav-icon--border nav-icon--admin" (click)="goToBackend()" *ngIf="true == userIsAdmin()"></a>
        <div class="nav-bar-item nav-section">{{ reader.active.page.name }}</div>
      </div>
      <a class="nav-icon nav-icon--close" (click)="goToDashboard()"></a>
    </section>
  </div>
</div>
<div class="info-message" [@infoMessageChanged]="infoMessageState" (@infoMessageChanged.start)="infoMessageChangedStart($event)" (@infoMessageChanged.done)="infoMessageChangedDone($event)">
  <div class='info-message-wrapper'>
    <p class="info-message__text">{{ infoMessage }}</p>
  </div>
</div>
<section class="main-reader" *ngIf="readerLoaded()">
  <div class="reader-content-wrapper">
    <ng-template ngFor let-block [ngForOf]="reader.active.blocks">
      <div class="reader-item reader-text" *ngIf="block.block == 'text'">
        <div class="reader-text-wrapper" [innerHtml]="block.text | safeHtml"></div>
      </div>
      <div class="reader-item single-image-container" *ngIf="block.block == 'image'">
        <img class="single-image" src="{{ block.image }}">
        <div class="gallery-image-watermark">
          <div class="gallery-image-watermark__logo watermark-elem"></div>
          <div class="gallery-image-watermark__copyright watermark-elem">&copy;</div>
        </div>
        <div class="single-image-caption" *ngIf="block.description.trim() !== ''">
          <p class="single-image-caption-text">{{ block.description }}</p>
        </div>
      </div>
      <div class="reader-item single-image-container" *ngIf="block.block == 'illustration'">
        <img class="illustration" src="{{ block.illustration }}" [ngClass]="{'illustration--png-margin': block.shadow}">
        <div class="single-image-caption" *ngIf="block.description.trim() !== ''">
          <p class="single-image-caption-text">{{ block.description }}</p>
        </div>
      </div>
      <div class="reader-item image-gallery" *ngIf="block.block == 'gallery'">
        <div class="image-gallery-wrapper">
          <div #galleryImage class="gallery-image-container" [ngClass]="{'gallery-image-container--active': image.active}" *ngFor="let image of block.images; let first = first;" (click)="activateGalleryImage(block, image, galleryImage)">
            <img class="gallery-image" src="{{ image.image }}">
            <div class="gallery-image-watermark">
              <div class="gallery-image-watermark__logo watermark-elem"></div>
              <div class="gallery-image-watermark__copyright watermark-elem">&copy;</div>
            </div>
            <div class="gallery-image-watermark__arrows">
              <div class="watermark-arrow watermark-arrow--left"></div>
              <div class="watermark-arrow watermark-arrow--right"></div>
            </div>
            <div class="gallery-image-caption" *ngIf="image.description.trim() !== ''">
              <p class="gallery-image-caption-text">{{ image.description }}</p>
            </div>
          </div>
        </div>
      </div>
      <ng-template ngIf="block.block == 'treatments'">
        <div class="reader-item reader-treatment" *ngFor="let treatment of block.treatments">
          <div class="reader-treatment__wrapper">
            <div class="reader-treatment__image-wrapper">
              <img class="reader-treatment__image" src="{{ treatment.image }}">
            </div>
            <div class="reader-treatment__info-wrapper">
              <p class="reader-treatment__title">{{ treatment.name }}</p>
              <div class="reader-treatment__info-text" [innerHtml]="treatment.indication"></div>
            </div>
          </div>
          <a class="reader-treatment__more-button" (click)="goToTreatment(treatment)" *ngIf="treatment.display_menu == true">meer informatie</a>
        </div>
      </ng-template>
      <div class="reader-item reader-keypoints" *ngIf="block.block == 'key_points'">
        <h3>Kernpunten</h3>
        <ul>
          <li *ngFor="let key_point of block.key_points">{{ key_point }}</li>
        </ul>
      </div>
    </ng-template>
  </div>
  <div class="reader-footer">
    <div class="reader-footer-button" *ngIf="showFooterDashboardLink()">
      <a (click)="goToDashboard()" class="reader-footer-button__text">naar hoofdmenu</a>
    </div>
    <div class="reader-footer-button" *ngIf="showNextPageLink()">
      <a (click)="nextPage()" class="reader-footer-button__text">volgende pagina</a>
    </div>
    <div class="reader-footer-button" *ngIf="showCaseLink()">
      <a (click)="goToCase()" class="reader-footer-button__text">start praktijktraining</a>
    </div>
    <div class="reader-footer-button" *ngIf="showQuizLink()">
      <a (click)="goToQuiz()" class="reader-footer-button__text">start overhoring</a>
    </div>
    <div class="reader-footer-button" *ngIf="showExamLink()">
      <a (click)="goToExam()" class="reader-footer-button__text">start examen</a>
    </div>
  </div>
</section>
