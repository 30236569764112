import { Component, OnInit, OnDestroy, QueryList, ViewChildren, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { TransitionService } from '../shared/services/transition.service';
import { ProgressService } from '../shared/services/progress.service';
import { CaseStore } from './case.store';
import { CaseState } from './states/case.state';
import { AnswerState } from './states/answer.state';
import { TreatmentState } from './states/treatment.state';
import { GridItemState } from './states/grid_item.state';
import { LoadingStore } from '../loading/loading.store';
import { environment } from 'src/environments/environment';
import { UserStore } from '../shared/stores/user.store';
import { ApmAppService, ApmEvents } from '../shared/services/apm_app.service';

@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.scss'],
  providers: [
    CaseStore
  ]
})
export class CaseComponent implements OnInit, OnDestroy {

  navbarHidden: boolean = true;
  case: CaseState = null;
  userIsAdmin: boolean = false;

  isLoaded = false;
  componentIsLoaded = true;

  caseError: boolean    = false;
  caseSuccess: boolean  = false;
  caseAnswered: boolean = false;

  missingCount: number      = 0;
  missing: TreatmentState[] = [];
  correct: TreatmentState[] = [];
  wrong:   TreatmentState[] = [];

  subscriptions: Subscription = new Subscription();

  @ViewChildren('caseAnswersTitle') caseAnswersTitle: QueryList<ElementRef>;

  constructor(private caseStore: CaseStore, private userStore: UserStore, private loadingStore: LoadingStore, private transitionService: TransitionService, private progressService: ProgressService, private route: ActivatedRoute, private apm: ApmAppService) {
  }

  ngOnInit() {

    this.loadingStore.show();

    this.userIsAdmin = this.isUserAdmin();

    let id = this.route.snapshot.paramMap.get('id');

    this.subscriptions.add(

      this.caseStore.state$.subscribe(data => {

        if (data.action === 'initial') {

          this.caseAnswered = false;
          this.correct      = [];
          this.wrong        = [];
          this.missing      = [];
          this.missingCount = 0;
          this.case         = data;

          window.setTimeout(() => {

            this.loadingStore.hide();
            this.isLoaded = true;
            this.navbarHidden = false;

          }, 2000);
        }

        if (data.action === 'add-correct') {

          this.case = data;

          this.apm.trackEvent(ApmEvents.CASE_ADD_CORRECT, {
            id,
          });
        }

        if (data.action === 'add-done') {
          this.case = data;
        }

        if (data.action === 'toggle-answer') {
          this.case = data;
        }

        if (data.action === 'activate-case') {

          this.componentIsLoaded = false;

          window.setTimeout(() => {
            this.componentIsLoaded = true;
          }, 700);

          this.caseAnswered = false;
          this.correct      = [];
          this.wrong        = [];
          this.missing      = [];
          this.missingCount = 0;
          this.case         = data;

          window.setTimeout(_ => {
            window.scrollTo(0, 0);
          }, 0);

          this.apm.trackEvent(ApmEvents.CASE_ACTIVATE_CASE, {
            id,
          });
        }
      })
    );

    this.caseStore.fetch(parseInt(id));

    this.apm.trackEvent(ApmEvents.CASE_START, {
      id
    });
  }

  getActiveCaseTitle() {
    return this.caseStore.getActiveCaseTitle();
  }

  getCaseStatus() {
    return null !== this.case ? `${this.case.correct.length} / ${this.case.case.cases.length} Goed Beantwoord` : '';
  }

  getProgressBarStyle() {

    if (null === this.case) {

      return {
        'width': '0%'
      };
    }

    return {
      'width':`${(this.case.correct.length / this.case.case.cases.length) * 100}%`
    };
  }

  getAnswer(treatment: TreatmentState) {

    let result = null;

    for (let category in this.case.active.answers) {

      for (let answer of this.case.active.answers[category]) {

        if (answer.treatment.id === treatment.id) {
          result = answer;
        }
      }
    }

    return result;
  }

  getTreatmentClass(treatment: TreatmentState) {

    let answer = this.getAnswer(treatment);

    if (null === answer) {
      return {};
    }

    return {

      'menu-treatment--active': this.case.selected.find(selected => {
        return selected.treatment.id === answer.treatment.id;
      })
    };
  }

  getCheckCaseButtonClass() {
    return this.case.selected.length === 0 ? ['round-button--hidden'] : [];
  }

  toggleTreatment(treatment: TreatmentState) {

    let answer = this.getAnswer(treatment);

    if (null === answer) {
      return;
    }

    this.caseStore.toggleAnswer(answer);
  }

  getCorrectAnswers() {

    let answers = [];
    for (let category in this.case.active.answers) {

      for (let answer of this.case.active.answers[category]) {

        if (answer.correct === true) {
          answers.push(`CATEGORY ${answer.category}, ANSWER ${answer.treatment.name}`);
        }
      }
    }

    return answers.join("<br />---------------<br />");
  }

  checkCase() {

    if (this.caseAnswersTitle.length > 0) {

      this.caseAnswersTitle.first.nativeElement
        .scrollIntoView({behavior: 'smooth', block: 'start'});
    }

    this.caseAnswered = true;
    this.correct      = [];
    this.wrong        = [];
    this.missing      = [];
    this.missingCount = 0;

    let correctPerCategory = {};

    for (let category in this.case.active.answers) {

      for (let answer of this.case.active.answers[category]) {

        let selected = this.case.selected.find(selected => {
          return selected.treatment.id === answer.treatment.id;
        });

        if (true === answer.correct) {

          if (selected) {

            // user selected correct answer
            this.correct.push(answer.treatment);

            correctPerCategory[category] = answer.treatment.id;
          }

        } else {

          if (selected) {
            // user has selected wrong answer
            this.wrong.push(answer.treatment);
          }
        }
      }
    }

    let categoryCounted = [];
    for (let category in this.case.active.answers) {

      for (let answer of this.case.active.answers[category]) {

        if (true === answer.correct) {

          if (!correctPerCategory[category]) {

            this.missing.push(answer.treatment);

            if (false === categoryCounted.includes(category)) {

              this.missingCount += 1;
              categoryCounted.push(category);
            }
          }
        }
      }
    }

    this.caseStore.markActiveCaseAsDone();

    if (this.missing.length > 0 || this.wrong.length > 0) {

      this.caseSuccess = false;
      this.caseError   = true;

    } else {

      this.caseSuccess = true;
      this.caseError   = false;

      this.caseStore.markActiveCaseAsCorrect();
    }
  }

  getCaseGrid() {

    let sorted = this.case.active.grid.sort((a: GridItemState, b: GridItemState) => {

      let comparison = 0;
      if (a.category.rank > b.category.rank) {
        comparison = 1;
      } else if (a.category.rank < b.category.rank) {
        comparison = -1;
      }

      return comparison;
    });

    return sorted;
  }

  getAnswers() {

    let unsorted    = {correct: [], wrong: [], missing: []};
    let sortAnswers = (a: AnswerState, b: AnswerState) => {

      let comparison = 0;
      if (a.number > b.number) {
        comparison = 1;
      } else if (a.number < b.number) {
        comparison = -1;
      }

      return comparison;
    };

    this.correct.forEach(item => {

      let answer = this.getAnswer(item);

      if (null !== answer) {
        unsorted.correct.push(answer);
      }
    });

    unsorted.correct.sort(sortAnswers);

    this.wrong.forEach(item => {

      let answer = this.getAnswer(item);

      if (null !== answer) {
        unsorted.wrong.push(answer);
      }
    });

    unsorted.wrong.sort(sortAnswers);

    this.missing.forEach(item => {

      let answer = this.getAnswer(item);

      if (null !== answer) {
        unsorted.missing.push(answer);
      }
    });

    unsorted.missing.sort(sortAnswers);

    let answers     = [].concat(unsorted.wrong).concat(unsorted.correct).concat(unsorted.missing);
    let categories  = [];
    let number      = 0;
    let correct     = 0;

    answers.forEach(answer => {

       if (true === answer.correct) {
           correct += 1;
       }
    });

    answers.forEach(answer => {

      if (true === answer.correct) {

        if (categories.indexOf(answer.category) === -1) {

          number += 1;
          categories.push(answer.category);
        }

        // if only 1 answer, hide numbers (ie number = false) or else assign number
        answer.number = correct === 1 ? false : number;

      } else {
        answer.number = false;
      }
    });

    return answers;
  }

  getAnswerClass(answer: AnswerState) {

    let wrong = this.wrong.findIndex(wrong_item => {
      return wrong_item.id === answer.treatment.id;
    });

    let correct = this.correct.findIndex(correct_item => {
      return correct_item.id === answer.treatment.id;
    });

    return {

      'menu-treatment--false': wrong > -1,
      'menu-treatment--correct': correct > -1,
    };
  }

  showAnswerNumber(answer: AnswerState) {
    return false !== answer.number;
  }

  showAnswerNumberExplanation() {

    let answers = this.getAnswers();
    let total   = answers.filter(answer => false !== answer.number).length;

    return total > 0;
  }

  getAnswerNumber(answer: AnswerState) {
    return answer.number;
  }

  allCasesCompleted() {
    return this.case.correct.length === this.case.case.cases.length;
  }

  getProceedCaseButtonLabel() {
    return true === this.allCasesCompleted() ? 'casus afronden' : 'volgende casus';
  }

  proceedCase() {

    if (true === this.allCasesCompleted()) {

      let progress = this.progressService
        .case_completed(this.case.case.id)
        .subscribe(_ => {

          this.caseAnswered = false;
          this.correct      = [];
          this.wrong        = [];
          this.missing      = [];
          this.missingCount = 0;

          progress.unsubscribe();
          this.goToDashboard();
        });

    } else {
      this.caseStore.activateNextCase();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  goToDashboard() {

    // hide navbar
    this.navbarHidden = true;

    // show loader
    this.loadingStore.show();

    // adding timeout to allow fade out of reader
    window.setTimeout(() => {

      this.transitionService
        .navigate(['']);

    }, 2000);
  }

  goToBackend() {
    window.open(`${environment.backend_url}/cases/${this.case.case.id}/case_items/${this.case.active.id}`);
  }

  isUserAdmin() {
    return this.userStore.getUser().roles.indexOf('admin') > 1;
  }

  dump(d: any) {
    // console.log(d);
  }
}
