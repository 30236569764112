<section class="settings-main settings-application">
  <div class="settings-wrapper">
    <a class="settings-item settings-item--back" (click)="goBack()">
      <span>terug</span>
    </a>
    <div class="settings-page">
      <h1>{{ static_page.title }}</h1>
      <div class="settings-text-box" [innerHtml]="static_page.body"></div>
    </div>
  </div>
</section>
