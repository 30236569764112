export class TreatmentState {

  action: string;

  treatment: {
    id: number;
    treatment_category_id: number;
    name: string;
    rank: number;
    display_menu: boolean;
    indication: string;
    description: string;
    image: string;
    created_at: Date;
    updated_at: Date;
  }
  page: {
    id: number;
    reader_id: number;
    name: string;
  };
}
