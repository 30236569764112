import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ExpiredSubscriptionsModalService extends ApiService {

  fetch(session_token: string) {
    return this.get('/v2/expired_subscriptions', {session_token});
  }

  confirm(session_token: string) {
    return this.destroy('/v2/expired_subscriptions', {session_token});
  }
}
