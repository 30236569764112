<div class="nav-bar">
  <div class="nav-bar-section-wrapper">
    <section class="nav-bar-menu">
      <a class="nav-icon nav-icon--qcare"></a>
      <div class="button-container">
        <a class="nav-text" [attr.data-navigation-id]="'qcare.nav.account.dashboard'" (click)="goToDashboard()">Lesmodules</a>
        <a class="nav-text" [attr.data-navigation-id]="'qcare.nav.account.treatments'" (click)="goToTreatments()">Naslagwerk</a>
      </div>
      <div class="button-container">
        <a class="nav-icon nav-icon--account nav-icon--account--active"></a>
        <a class="nav-icon nav-icon--settings" [attr.data-navigation-id]="'qcare.nav.account.settings'" (click)="goToSettings()"></a>
      </div>
    </section>
  </div>
</div>
<section class="settings-main settings-account" *ngIf="showAccountPage()">
  <div class="settings-wrapper">
    <app-notifications></app-notifications>
    <div class="diploma-item" *ngFor="let subscription of account.subscriptions">
      <div class="diploma-item__section">
        <p>Toegang: {{ subscription.remaining_days }} dag{{ subscription.remaining_days == 1 ? '' : 'en' }} resterend</p>
        <h2>{{ subscription.course }}</h2>
      </div>
      <div class="diploma-item__section">
        <p>Voortgang</p>
        <div class="diploma-item__progress">
          <div class="diploma-item__progress-bar" [class.diploma-item__progress-bar--success]="subscription.done" [style.width]="subscription.percentage + '%'"></div>
        </div>
      </div>
      <div class="diploma-item__balloon" *ngIf="true == subscription.done && DIPLOMA_UNAVAILABLE == subscription.diploma">
        <b>Verifieer uw gegevens</b>
        <p>Controleer of uw persoonlijke en accreditatiegegevens onderaan deze pagina kloppen voordat u uw diploma aanmaakt. Wanneer u uw 'Diploma Aanmaken' drukt kunt u de gegevens die op het diploma komen te staan niet meer wijzigen.
        </p>
      </div>
      <div class="diploma-item__section">
        <button [disabled]="true" *ngIf="false == subscription.done">Diploma aanmaken.</button>
        <button *ngIf="true == subscription.done && subscription.diploma == DIPLOMA_UNAVAILABLE" [attr.data-navigation-id]="'qcare.nav.account.diploma.generate'" (click)="getDiploma(subscription.id)">Diploma aanmaken.</button>
        <button *ngIf="true == subscription.done && subscription.diploma == DIPLOMA_AVAILABLE" [attr.data-navigation-id]="'qcare.nav.account.diploma.download'" (click)="getDiploma(subscription.id)">Download Diploma.</button>
        <button *ngIf="true == subscription.done && subscription.diploma == DIPLOMA_PENDING" [innerHtml]="pendingDiplomaButtonLabel"></button>
      </div>
      <div class="diploma-item__section">
        <p *ngIf="false == subscription.done">U kunt hier uw diploma downloaden wanneer u de cursus voltooid hebt.</p>
        <p *ngIf="DIPLOMA_UNAVAILABLE != subscription.diploma">Behaalde accredatiepunten worden automatisch door ons aangemeld bij V&VN en/of ADAP.</p>
      </div>
    </div>
    <div class="personalia-item personalia-item--success" *ngIf="accountSaved()">
      Uw gegevens zijn succesvol opgeslagen
    </div>
    <div class="personalia-item personalia-item--error" *ngIf="errors.length > 0">
      Er ging iets fout
      <ul style="margin-bottom: 0;">
        <li *ngFor="let error of errors">{{ error }}</li>
      </ul>
    </div>
    <div class="personalia-item">
      <form class="settings-form">
        <div class="settings-form__item">
          <h2>Persoonlijke Gegevens</h2>
          <h3>Onderstaande gegevens worden gebruikt om uw studiepunten aan te melden en uw diploma te genereren.</h3>
        </div>
        <div class="settings-form__item" [class.settings-form__item--error]="account.error_fields.includes('title')">
          <p>Aanhef</p>
          <select name="title" [(ngModel)]="user.title">
            <option value="" disabled>Kies uw aanhef</option>
            <option value="Dhr.">Dhr.</option>
            <option value="Mevr.">Mevr.</option>
          </select>
        </div>
        <div class="settings-form__item" [class.settings-form__item--error]="account.error_fields.includes('initials')">
          <p>Voorletters</p>
          <input type="text" name="initials" placeholder="Vul uw voorletters in" class="login-input" [(ngModel)]="user.initials">
        </div>
        <div class="settings-form__item" [class.settings-form__item--error]="account.error_fields.includes('last_name')">
          <p>Achternaam</p>
          <input type="text" name="last_name" placeholder="Vul uw achternaam in" class="login-input" [(ngModel)]="user.last_name">
        </div>
        <div class="settings-form__item" [class.settings-form__item--error]="account.error_fields.includes('birthday')">
          <p>Geboortedatum</p>
          <ngx-datepicker name="birthday" [(ngModel)]="user.birthday" [options]="datepicker_options"></ngx-datepicker>
        </div>
        <div class="settings-form__item" [class.settings-form__item--error]="account.error_fields.includes('registration_kind')">
          <p>V&VN of ADAP accreditatie*</p>
          <select name="registration_kind" [(ngModel)]="user.registration_kind">
            <option value="unregistered">Ik wil geen studiepunten ontvangen</option>
            <option value="vnvn">Ik wil studiepunten ontvangen van V&VN</option>
            <option value="adap">Ik wil studiepunten ontvangen van ADAP</option>
          </select>
        </div>
        <div class="settings-form__item" [class.settings-form__item--error]="account.error_fields.includes('registration_number')" *ngIf="user.registration_kind == 'vnvn'">
          <p>BIG of V&VN Registratienummer</p>
          <input type="text" name="registration_number" placeholder="Vul uw BIG of V&VN registratienummer in" class="login-input" [(ngModel)]="user.registration_number">
        </div>
        <div class="settings-form__item" [class.settings-form__item--error]="account.error_fields.includes('registration_number')" *ngIf="user.registration_kind == 'adap'">
          <p>KP Registratienummer</p>
          <input type="text" name="registration_number" placeholder="Vul uw KP registratienummer in" class="login-input" [(ngModel)]="user.registration_number">
        </div>
        <div class="settings-form__item">
          <input type="submit" value="bezig met aanpassen" *ngIf="!showConfirmButton()">
          <input type="submit" value="opslaan" [attr.data-navigation-id]="'qcare.nav.account.save'" (click)="saveAccount()" *ngIf="showConfirmButton()">
        </div>
      </form>
    </div>
  </div>
</section>
