import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NewUserStore } from './new-user.store';
import { TransitionService } from '../shared/services/transition.service';
import { NewUserState } from './new-user.state';
import { environment } from 'src/environments/environment';
import { ConfirmModalStore } from '../confirm-modal/confirm-modal.store';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();
  new_user: NewUserState;
  status: string = null;
  datepicker_options = environment.datepicker_options;

  constructor(private newUserStore: NewUserStore, private confirmModalStore: ConfirmModalStore, private transitionService: TransitionService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

    let new_user_token = this.route.snapshot.paramMap.get('new_user_token');
    this.subscriptions.add(

      this.newUserStore.state$.subscribe(data => {

        if (data.action === 'valid') {

          this.new_user = data;
          this.status   = 'form';
        }

        if (data.action === 'refreshed') {

          this.confirmModalStore.createNewUserExpired(data.user.email);
          return this.goToLogin();
        }

        if (data.action === 'invalid') {
          return this.goToLogin();
        }

        if (data.action === 'reset') {
          return this.goToDashboard();
        }

        if (data.action === 'error') {

          this.new_user = data;
          this.status   = 'form';

          window.scrollTo(0, 0);
        }

      }, _ => {
        this.goToLogin();
      })
    );

    this.newUserStore.check(new_user_token);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  goToLogin() {

    return this.transitionService
      .navigate(['']);
  }

  goToDashboard() {

    return this.transitionService
      .navigate(['dashboard']);
  }

  goToConditions() {

    return this.transitionService
      .navigate(['page/conditions'], {state: {backUri: this.router.url}});
  }

  newUser(password: string, password_confirmation: string) {

    let new_user_token = this.route.snapshot.paramMap.get('new_user_token');

    this.newUserStore
      .setNewUser(new_user_token, this.new_user.user, password, password_confirmation);
  }
}
