import { Injectable } from "@angular/core";

@Injectable()
export class SplashStore {
  private show_splash: boolean = true;

  showSplash() {
    return this.show_splash === true;
  }

  hide() {
    this.show_splash = false;
  }

  show() {
    this.show_splash = true;
  }
}
