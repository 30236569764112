import { ExamState } from "../exam/states/exam.state";

export enum ActiveExamStatus {
  FINISHED = 'finished',
  RUNNING  = 'running'
}

export class ActiveExamModalState {

  action: string;
  show: boolean;
  close: boolean;
  name: string;
  exam_id: number;
  status: ActiveExamStatus;
  running_time: number;
  remaining_time: number;
  total_time: number;
}
