import { Component, OnInit, AfterViewInit, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { TransitionService } from '../shared/services/transition.service';
import { ProgressService } from '../shared/services/progress.service';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { ExamSliderService } from './exam_slider.service';
import { ExamState } from './states/exam.state';
import { ExamStore } from './exam.store';
import { QuestionState } from './states/question.state';
import { AnswerState } from './states/answer.state';
import { TimerComponent } from '../timer/timer.component';
import { LoadingStore } from '../loading/loading.store';
import { UserStore } from '../shared/stores/user.store';
import { environment } from 'src/environments/environment';
import { ApmAppService, ApmEvents } from '../shared/services/apm_app.service';

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.scss'],
  providers: [
    ExamStore,
  ]
})
export class ExamComponent implements OnInit, OnDestroy, AfterViewInit {

  exam: ExamState = null;
  subscriptions: Subscription = new Subscription();
  savedTime: number = null;
  tweening: boolean = false;
  isLoaded: boolean = false;
  userIsAdmin: boolean = false;

  @ViewChildren('timer') timer: QueryList<TimerComponent>;

  constructor(private examStore: ExamStore, private userStore: UserStore, private loadingStore: LoadingStore, private transitionService: TransitionService, private examSliderService: ExamSliderService, private hotkeysService: HotkeysService, private progressService: ProgressService, private route: ActivatedRoute, private apm: ApmAppService) {
  }

  ngOnInit() {

    this.loadingStore.show();

    this.userIsAdmin = this.isUserAdmin();

    let id = this.route.snapshot.paramMap.get('id');

    this.subscriptions.add(

      this.examStore.state$.subscribe(data => {

        if (data.action === 'initial') {

          this.exam      = data;
          this.savedTime = this.exam.remaining_time;

          window.setTimeout(() => {

            this.loadingStore.hide();
            this.isLoaded = true;

          }, 2000);
        }

        if (data.action === 'restore-state') {

          this.exam      = data;
          this.savedTime = this.exam.remaining_time;

          window.setTimeout(() => {

            this.loadingStore.hide();
            this.isLoaded = true;

          }, 2000);

          this.apm.trackEvent(ApmEvents.EXAM_RESTORE_STATE, {

            id,
            savedTime: this.savedTime,
          });
        }

        if (data.action === 'activate-introduction') {

          this.exam = data;
          this.slide(false);

          this.apm.trackEvent(ApmEvents.EXAM_INTRODUCTION, {
            id,
          });
        }

        if (data.action === 'activate-question') {

          this.exam = data;
          this.slide(true);

          this.apm.trackEvent(ApmEvents.EXAM_ACTIVATE_QUESTION, {

            id,
            question: {

              id: this.exam.question.id,
              question: this.exam.question.question,
              answers: this.exam.question.answers.map(answer => answer.answer),
            }
          });
        }

        if (data.action === 'activate-confirmation') {

          this.exam = data;
          this.slide(true);

          this.apm.trackEvent(ApmEvents.EXAM_ACTIVATE_CONFIRMATION, {
            id
          });
        }

        if (data.action === 'exam-validated') {

          this.exam = data;

          let progress = this.progressService
            .exam_completed(parseInt(id), this.exam)
            .subscribe(_ => {
              progress.unsubscribe();
            });

          this.slide(true);

          this.apm.trackEvent(ApmEvents.EXAM_COMPLETED, {
            id,
          });
        }

        if (data.action === 'error') {
          this.goToDashboard();
        }
      })
    );

    this.examStore.fetch(parseInt(id));

    this.subscriptions.add(
      this.examSliderService.observe().subscribe(data => {
        this.tweening = false;
      })
    );

    this.apm.trackEvent(ApmEvents.EXAM_START, {
      id,
    });
  }

  ngOnDestroy() {

    this.clearTimer();
    this.subscriptions.unsubscribe();
    this.hotkeysService.reset();
  }

  ngAfterViewInit() {

    window.setTimeout(() => {

      if (null === this.exam) {
        return;
      }

      if (this.exam.action === 'initial') {
        this.examStore.activateIntroduction();
      } else {
        this.slide(false);
      }

    }, 3000);

    this.hotkeysService.reset();
    this.hotkeysService.add(new Hotkey('right', (event: KeyboardEvent): boolean => {

      this.goToNextQuestion();
      return false;
    }));

    this.hotkeysService.add(new Hotkey('left', (event: KeyboardEvent): boolean => {

      this.goToPreviousQuestion();
      return false;
    }));
  }

  slide(tween: boolean) {

    this.tweening = true;
    this.examSliderService.move(this.exam.slide);
  }

  resize() {

    if (this.exam) {
      this.examSliderService.resize();
    }
  }

  startExam() {

    if (true === this.tweening) {
      return;
    }

    this.examStore.startExam();
  }

  showQuestions() {
    return false === this.exam.confirmed && this.exam.answers.length !== this.exam.exam.questions.length;
  }

  showGraduated() {
    return null !== this.exam.result && true === this.exam.result.graduated;
  }

  showFailed() {
    return null !== this.exam.result && false === this.exam.result.graduated;
  }

  showCompleted() {
    return true === (this.showGraduated() || this.showFailed());
  }

  getNavBarClass() {
    return false === this.isQuestionActive() ? ['nav-bar--hidden'] : [];
  }

  getNextQuestionLinkClass() {

    if (false === this.isQuestionActive()) {
      return ['nav-icon--next--disabled'];
    }

    let idx = this.exam.exam.questions.findIndex(question => {
      return this.exam.question.id === question.id;
    });

    if (false === this.examStore.isAnswered(this.exam.exam.questions[idx])) {
      return ['nav-icon--next--disabled'];
    }

    idx += 1;

    if (idx >= this.exam.exam.questions.length) {
      return ['nav-icon--next--disabled'];
    }

    return [];
  }

  goToNextQuestion(confirmButton: boolean = false) {

    if (true === this.tweening) {
      return;
    }

    if (null === this.exam.question) {

      // no active question
      return;
    }

    let idx = this.exam.exam.questions.findIndex(question => {
      return this.exam.question.id === question.id;
    });

    if (idx === -1) {

      // active queston not found
      return;
    }

    if (false === this.examStore.isAnswered(this.exam.exam.questions[idx])) {

      // active question was not answered
      return;
    }

    idx += 1;

    if (idx <= (this.exam.exam.questions.length - 1)) {

      // next question available
      this.examStore.activateQuestion(this.exam.exam.questions[idx]);

    } else {

      // active question is last question
      if (true === confirmButton) {

        // only activate confirmation if clicked by confirm button
        this.examStore.activateConfirmation();
      }
    }
  }

  markAnswer(question: QuestionState, answer: AnswerState) {
    this.examStore.markAnswer(question, answer);
  }

  getAnswerClass(question: QuestionState, answer: AnswerState) {

    let selectedQuestionIdx = this.exam.answers.findIndex(selected => {
      return question.id === selected.question.id;
    });

    if (selectedQuestionIdx === -1) {
      return [];
    }

    let selectedAnswerIdx = this.exam.answers[selectedQuestionIdx].answers.findIndex(selected => {
      return selected.id === answer.id;
    });

    if (selectedAnswerIdx === -1) {
      return [];
    }

    return ['answer-active'];
  }

  hideConfirmButton(question: QuestionState) {

    let selectedQuestionIdx =  this.exam.answers.findIndex(selected => {
      return question.id === selected.question.id;
    });

    if (selectedQuestionIdx === -1) {
      return true;
    }

    return this.exam.answers[selectedQuestionIdx].answers.length === 0;
  }

  validateExam() {

    this.clearTimer();
    this.examStore.validateExam();
  }

  getTotalQuestions() {
    return this.exam.exam.questions.length;
  }

  isQuestionActive() {
    return null !== this.exam && null !== this.exam.question;
  }

  getExamDurationLabel() {

    let duration = '';
    if (this.exam.exam.duration_hours > 0) {
      duration += `${this.exam.exam.duration_hours} uur${(this.exam.exam.duration_minutes > 0 ? ' en ' : '')}`;
    }

    if (this.exam.exam.duration_minutes > 0) {
      duration += `${this.exam.exam.duration_minutes} minuten`;
    }

    return duration;
  }

  getCurrentQuestionLabel() {

    if (false === this.isQuestionActive()) {

      // not active question
      return `0 / ${this.exam.exam.questions.length}`;
    }

    let idx = this.exam.exam.questions.findIndex(question => {
      return question.id === this.exam.question.id;
    });

    return `${idx === -1 ? 0 : (idx + 1)} / ${this.exam.exam.questions.length}`;
  }

  getExamPercentageStyle() {

    let percentage = 0;

    if (true === this.isQuestionActive()) {

      let answered = 0;
      this.exam.answers.forEach(answer => {

        if (answer.answers.length > 0 && answer.question.id !== this.exam.question.id) {
          answered += 1;
        }
      });

      percentage = ((answered / this.exam.exam.questions.length) * 100);
    }

    return {
      'width': `${percentage}%`
    };
  }

  getExamGrade() {

    if (null === this.exam.result) {
      return 0;
    }

    return this.exam.result.grade.toFixed(1);
  }

  getExamBadge() {

    if (null === this.exam.result) {
      return '';
    }

    if (this.showGraduated()) {
      return '✔';
    }

    if (this.showFailed()) {
      return '✘';
    }
  }

  getExamBadgeClass() {

    if (null === this.exam.result) {
      return [];
    }

    if (this.showGraduated()) {
      return [];
    }

    if (this.showFailed()) {
      return ['exam-result-badge--false'];
    }
  }

  getExamResult() {

    if (null === this.exam.result) {
      return '';
    }

    return `U heeft ${this.exam.result.correct} van de ${this.exam.result.total} vragen goed beantwoord.`;
  }

  getPreviousQuestionLinkClass() {

    if (false === this.isQuestionActive()) {
      return ['nav-icon--prev--disabled'];
    }

    let idx = this.exam.exam.questions.findIndex(question => {
      return this.exam.question.id === question.id;
    });

    idx -= 1;

    if (idx < 0) {
      return ['nav-icon--prev--disabled'];
    }

    return [];
  }

  goToPreviousQuestion() {

    if (true === this.tweening) {
      return;
    }

    if (null === this.exam.question) {
      return;
    }

    let idx = this.exam.exam.questions.findIndex(question => {
      return this.exam.question.id === question.id;
    });

    if (idx === -1) {
      return;
    }

    idx -= 1;

    if (idx >= 0) {
      this.examStore.activateQuestion(this.exam.exam.questions[idx]);
    }
  }

  goBackToExam() {

    if (true === this.tweening) {
      return;
    }

    this.examStore.goBackToExam();
  }

  dump(data: any) {
    // console.log(data);
  }

  isUserAdmin() {
    return this.userStore.getUser().roles.indexOf('admin') > 1;
  }

  goToDashboard() {

    this.clearTimer();

    // show loader
    this.loadingStore.show();

    // adding timeout to allow fade out of reader
    window.setTimeout(() => {

      this.transitionService
        .navigate(['']);

    }, 2000);
  }

  goToBackend() {
    window.open(`${environment.backend_url}/modules/${this.exam.exam.course_module_id}/exams/${this.exam.exam.id}`);
  }

  timerTick(seconds: number) {
    this.savedTime = seconds;
  }

  clearTimer() {

    if (this.timer.length > 0) {
      this.timer.map(timer => timer.clearTimer());
    }
  }
}
