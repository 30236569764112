import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { CompanyEditUserState } from './company-edit-user.state';

@Injectable({
  providedIn: 'root'
})
export class CompanyEditUserService extends ApiService {

  fetch(user_id: number, session_token: string) {
    return this.get(`/company/users/${user_id}`, {session_token});
  }

  updateUser(company_edit_user: CompanyEditUserState, session_token: string) {

    return this.put(`/company/users/${company_edit_user.user.id}`, {

      session_token: session_token,
      edit_user: {

        title: company_edit_user.user.title,
        initials: company_edit_user.user.initials,
        last_name: company_edit_user.user.last_name,
        email: company_edit_user.user.email,
        subscriptions: company_edit_user.subscriptions,
      }
    });
  }
}
