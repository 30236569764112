<section class="login">
  <div class="login-wrapper">
    <app-notifications></app-notifications>
    <div class="login-header">
      <div class="login-header__logo"></div>
      <small>inloggen</small>
    </div>
    <div class="login-window">
      <form class="login-form">
        <div class="login-credentials">
          <input type="email" placeholder="e-mailadres" class="login-input" #email>
          <input type="password" placeholder="wachtwoord" class="login-input" #password>
        </div>
        <div class="login-submit">
          <input type="submit" value="Bevestigen" (click)="login(email.value, password.value)" *ngIf="!loading">
          <input type="submit" value="Bezig met inloggen..." *ngIf="loading">
        </div>
      </form>
    </div>
    <div class="login-bottom-link">
      <a class="login-bottom-link--grey" (click)="goToForgotPassword()">
        wachtwoord vergeten
      </a>
      <div class="login-bottom-link--error" *ngIf="error">
        verkeerde gebruikersnaam of wachtwoord
      </div>
    </div>
  </div>
</section>
