import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TreatmentService } from './treatment.service';
import { Subscription } from 'rxjs';
import { TreatmentState } from './treatment.state';
import { TransitionService } from '../shared/services/transition.service';
import { TreatmentStore } from './treatment.store';
import { LoadingStore } from '../loading/loading.store';

@Component({
  selector: 'app-treatment',
  templateUrl: './treatment.component.html',
  styleUrls: ['./treatment.component.scss'],
  providers: [
    TreatmentStore
  ]
})
export class TreatmentComponent implements OnInit, OnDestroy {

  treatment: TreatmentState = null;
  isLoaded: boolean = false;
  loaderHidden: boolean = true;
  navbarHidden: boolean = true;

  private subscriptions: Subscription = new Subscription();
  private navigatedFromTreatments: boolean = false;

  constructor(private loadingStore: LoadingStore, private transitionService: TransitionService, private route: ActivatedRoute, private treatmentStore: TreatmentStore, private router: Router) {

    let currentNavigation = this.router.getCurrentNavigation();

    if (currentNavigation && currentNavigation.extras.state && currentNavigation.extras.state.navigatedFromTreatments) {

      this.navbarHidden = false;
      this.navigatedFromTreatments = true;
    }
  }

  ngOnInit() {

    window.scroll(0, 0);

    // if (true === this.navigatedFromTreatments) {

      // navigated from treatments, show grey loader
      this.loaderHidden = false;
    // }

    this.subscriptions.add(

      this.treatmentStore.state$.subscribe(data => {

        if (data.action === 'initial') {

          this.treatment = data;

          window.setTimeout(() => {

            this.isLoaded = true;
            this.navbarHidden = false;
            this.loaderHidden = true;

          }, 2000);
        }
      })
    );

    let id = this.route.snapshot.paramMap.get('id');
    let page = this.route.snapshot.paramMap.get('page');

    if (page) {
      this.treatmentStore.fetchWithPage(parseInt(id), parseInt(page));
    } else {
      this.treatmentStore.fetch(parseInt(id));
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  goToDashboard() {

    return this.transitionService
      .navigate(['dashboard'], {state: {navigatedFromTreatments: true}});
  }

  goToTreatments() {

    this.transitionService
      .navigate(['treatments'], {state: {navigatedFromDashboard: true}});
  }

  goToAccount() {

    this.transitionService
      .navigate(['account']);
  }

  goToSettings() {

    return this.transitionService
      .navigate(['settings']);
  }

  closeTreatment() {

    let page = this.route.snapshot.paramMap.get('page');

    if (page) {

      this.transitionService
        .navigate([`/reader/${this.treatment.page.reader_id}/${this.treatment.page.id}`]);

    } else {

      this.transitionService
        .navigate(['/treatments'], {state: {navigatedFromDashboard: true}});
    }
  }

  dump(v) {
    // console.log(v);
  }
}
