import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ActiveExamModalService extends ApiService {

  fetch(session_token: string) {
    return this.get('/v2/active_exam', {session_token});
  }
}
