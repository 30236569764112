import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';
import { CompanyStoreState } from "./company.state";
import { CompanyService } from "./company.service";
import { UserStore } from "../shared/stores/user.store";

@Injectable()
export class CompanyStore extends Store<CompanyStoreState> {

  constructor(private companyService: CompanyService, private userStore: UserStore) {
    super(new CompanyStoreState());
  }

  fetch() {

    let fetch = this.companyService
      .fetch(this.userStore.getSessionToken())
      .subscribe((data: any) => {

        fetch.unsubscribe();

        this.setState({

          ...this.state,
          action: 'initial',
          company: data.company
        });
      });
  }
}
