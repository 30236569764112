import { UserState } from "../shared/stores/states/user.state";
import { CreditsState } from "../old-company/credits.state";

export class CompanyEditUserState {

  action: string;
  user: UserState;
  errors: string[] = [];
  subscriptions: number[] = [];
  credits: CreditsState[];
  credits_left: number;
}
