import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';
import { ExamState } from "./states/exam.state";
import { ExamService } from "./exam.service";
import { QuestionState } from "./states/question.state";
import { AnswerState } from "./states/answer.state";
import { ExamValidatorService } from "./exam_validator.service";

@Injectable()
export class ExamStore extends Store<ExamState> {

  constructor(private examService: ExamService) {
    super(new ExamState());
  }

  fetch(id: number) {

    let fetch = this.examService
      .fetch(id)
      .subscribe(

        (data: any) => {

          fetch.unsubscribe();

          if (data.exam_state === null) {
            this.initializeState(data);
          } else {
            this.restoreState(data);
          }
        },

        (error) => {

          this.setState({

            ...this.state,
            action: 'error',
          });
        }
      );
  }

  initializeState(data: any) {

    this.setState({

      ...this.state,
      action: 'initial',
      remaining_time: this.getTotalRunningTime(data.exam.duration_hours, data.exam.duration_minutes),
      slide: -1,
      // slides:
      // data.exam.questions.length = questions
      // + 3 slides                 = introduction + confirmation + completion
      slides: data.exam.questions.length + 2,
      question: null,
      answers: [],
      result: null,
      confirmed: false,
      exam: data.exam,
      remaining_tries: data.remaining_tries
    });
  }

  getTotalRunningTime(hours, minutes) {
    return (hours * 60 * 60) + (minutes * 60);
  }

  activateIntroduction() {

    this.setState({

      ...this.state,
      action: 'activate-introduction',
      slide: 0,
      question: null
    });
  }

  activateQuestion(question: QuestionState) {

    let idx = this.state.exam.questions.findIndex(item => {
      return item.id === question.id;
    });

    let newState = {

      ...this.state,
      action: 'activate-question',
      slide: (idx + 1), // slides start from -1, so -1 + 1 (introduction) + question idx + 1
      question: question
    };

    this.setState(newState);
    this.commitState(newState);
  }

  goToPreviousQuestion() {

    let idx = this.state.exam.questions.findIndex(item => {
      return item.id === this.state.question.id;
    });

    idx -= 1;

    if (idx < 0) {
      return;
    }

    this.activateQuestion(this.state.exam.questions[idx]);
  }

  goToNextQuestion() {

    let idx = this.state.exam.questions.findIndex(item => {
      return item.id === this.state.question.id;
    });

    idx += 1;

    if (idx >= this.state.exam.questions.length) {
      return;
    }

    this.activateQuestion(this.state.exam.questions[idx]);
  }

  goBackToExam() {

    let idx = this.state.exam.questions.length - 1;

    this.setState({

      ...this.state,
      action: 'activate-question',
      slide: this.state.exam.questions.length, // go to last question
      question: this.state.exam.questions[idx]
    });
  }

  startExam() {

    let newState = {

      ...this.state,
      action: 'activate-question',
      slide: 1, // 1 = first question
      question: this.state.exam.questions[0]
    };

    this.setState(newState);
    this.createState(newState);
  }

  activateConfirmation() {

    let newState = {

      ...this.state,
      action: 'activate-confirmation',
      slide: this.state.exam.questions.length + 1, // + 1 = confirmation slide
      question: null,
      confirmed: true,
    };

    this.setState(newState);
    this.commitState(newState);
  }

  isAnswered(question: QuestionState) {

    let selectedAnswerIdx = this.state.answers.findIndex(selected => {
      return selected.question.id === question.id;
    });

    if (selectedAnswerIdx === -1) {
      return false;
    }

    return this.state.answers[selectedAnswerIdx].answers.length > 0;
  }

  markAnswer(question: QuestionState, answer: AnswerState) {

    let currentAnswers    = this.state.answers;
    let selectedAnswerIdx = currentAnswers.findIndex(selected => {
      return selected.question.id === question.id;
    });

    if (selectedAnswerIdx === -1) {

      // question not found
      currentAnswers.push({

        question: question,
        answers: [answer]
      });

    } else {

      let marked = currentAnswers[selectedAnswerIdx].answers.findIndex(selected => {
        return selected.id === answer.id;
      });

      if (marked === -1) {

        // comment next line if you want to enable multiple answers
        currentAnswers[selectedAnswerIdx].answers = [];

        // wasn't marked, let's mark it
        currentAnswers[selectedAnswerIdx].answers.push(answer);

      } else {

        // already marked, unmark it
        currentAnswers[selectedAnswerIdx].answers.splice(marked, 1);
      }
    }

    let newState = {

      ...this.state,
      action: 'mark-answer',
      answers: currentAnswers
    };

    this.setState(newState);
    this.commitState(newState);
  }

  validateExam() {

    let validator = new ExamValidatorService(this.state);
    this.setState({

      ...this.state,
      action: 'exam-validated',
      slide: this.state.exam.questions.length + 2, // validation screen
      question: null,
      confirmed: true,
      result: validator.validate()
    });
  }

  examDurationEnded() {

    let validator = new ExamValidatorService(this.state);
    this.setState({

      ...this.state,
      action: 'exam-validated',
      slide: this.state.exam.questions.length + 2,
      result: validator.validate()
    });
  }

  createState(state: ExamState) {

    this.examService
      .createState(state)
      .subscribe(
        _ => {},
        _ => {}
      );
  }

  commitState(state: ExamState) {

    this.examService
      .commitState(state)
      .subscribe(
        _ => {},
        _ => {}
      );
  }

  restoreState(data: any) {

    let state = JSON.parse(data.exam_state.state) as ExamState;

    this.setState({

      ...state,
      action: 'restore-state',
      restoring_action: state.action,
      remaining_time: data.exam_state.remaining_time,
    });
  }
}
