import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';
import { TreatmentState } from "./treatment.state";
import { TreatmentService } from "./treatment.service";

@Injectable({
  providedIn: 'root'
})
export class TreatmentStore extends Store<TreatmentState> {

  constructor(private treatmentService: TreatmentService) {
    super(new TreatmentState());
  }

  fetch(id: number) {

    let fetch = this.treatmentService
      .find(id)
      .subscribe((data: any) => {

        fetch.unsubscribe();

        this.setState({

          ...this.state,
          action: 'initial',
          treatment: data.treatment,
          page: null
        });
      });
  }

  fetchWithPage(id: number, page: number) {

    let fetch = this.treatmentService
      .findWithPage(id, page)
      .subscribe((data: any) => {

        fetch.unsubscribe();

        this.setState({

          ...this.state,
          action: 'initial',
          treatment: data.treatment,
          page: data.page,
        });
      });
  }
}
