import { ReaderPageState } from "./reader_page.state";

export class ReaderState {

  action: string;
  active: ReaderPageState;
  current: number;
  total: number;
  progress: number;
  next_case: number;
  next_quiz: number;
  next_exam: number;
  keywords: {
    [id: number]: string;
  };
  preloadImages: {

    [id: number]: [{

      src: string;
      loaded: boolean;
    }]
  };
  reader: {

    id: number;
    course_id: number;
    course_module_id: number;
    rank: number;
    pages: ReaderPageState[];
    next_case: number;
    next_quiz: number;
    next_exam: number;
    created_at: Date;
    updated_at: Date;
  };
}
