<div class="disable-pointer-events reader-loading" *ngIf="componentIsLoaded == false">
  <div class="reader-loading-wrapper">
    <div class="reader-loading__circle"></div>
    <svg class="reader-loading__circle" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" r="32" stroke="rgb(240, 240, 240)" stroke-width="10" fill="none"></circle>
      <circle class="reader-loading-animation__circle__stroke" cx="50" cy="50" r="32" stroke="#d1d1d1" stroke-dasharray="50 100" stroke-width="10" stroke-linecap="square" fill="none" transform="rotate(497.745 50 50)"></circle>
    </svg>
  </div>
</div>
<div class="nav-bar" [class.nav-bar--hidden]="navbarHidden">
  <div class="nav-bar-progressbar">
    <div class="nav-bar-progressbar__percentage" [ngStyle]="getProgressBarStyle()"></div>
  </div>
  <div class="nav-bar-section-wrapper">
    <section class="nav-bar-casus">
      <div class="button-container">
        <a class="nav-icon nav-icon--border nav-icon--admin" [attr.data-navigation-id]="'qcare.nav.case.backend'" (click)="goToBackend()" *ngIf="userIsAdmin == true"></a>
        <div class="nav-bar-item nav-section">{{ getCaseStatus() }}</div>
      </div>
      <a class="nav-icon nav-icon--close" [attr.data-navigation-id]="'qcare.nav.case.dashboard'" (click)="goToDashboard()"></a>
    </section>
  </div>
</div>
<section class="main-casus" *ngIf="case && case.active && isLoaded && componentIsLoaded">
  <div class="reader-content-wrapper">
    <div class="reader-item reader-text">
      <div class="reader-text-wrapper">
        <h1>{{ getActiveCaseTitle() }}</h1>
        <div [innerHtml]="case.active.anamnese | safeHtml"></div>
      </div>
    </div>
    <div class="reader-item single-image-container">
      <img class="single-image" src="{{ case.active.image }}">
      <div class="gallery-image-watermark">
        <div class="gallery-image-watermark__logo watermark-elem"></div>
        <div class="gallery-image-watermark__copyright watermark-elem">©</div>
      </div>
    </div>
    <div class="reader-item casus-anamnese">
      <div class="casus-anamnese-item" [ngClass]="{ 'casus-anamnese-item--deactivated': case.active.wound_factors.patient.trim() == '' }">
        <div class="casus-anamnese-item__head">
          <div class="casus-anamnese-item__icon casus-anamnese-item__icon--patient"></div>
          <div class="casus-anamnese-item__label">Patient</div>
        </div>
        <div class="casus-anamnese-item__text">{{ case.active.wound_factors.patient }}</div>
      </div>
      <div class="casus-anamnese-item" [ngClass]="{ 'casus-anamnese-item--deactivated': case.active.wound_factors.wound.trim() == '' }">
        <div class="casus-anamnese-item__head">
          <div class="casus-anamnese-item__icon casus-anamnese-item__icon--wond"></div>
          <div class="casus-anamnese-item__label">Wond</div>
        </div>
        <div class="casus-anamnese-item__text">{{ case.active.wound_factors.wound }}</div>
      </div>
      <div class="casus-anamnese-item" [ngClass]="{ 'casus-anamnese-item--deactivated': case.active.wound_factors.tissue.trim() == '' }">
        <div class="casus-anamnese-item__head">
          <div class="casus-anamnese-item__icon casus-anamnese-item__icon--weefsel"></div>
          <div class="casus-anamnese-item__label">Weefsel</div>
        </div>
        <div class="casus-anamnese-item__text">{{ case.active.wound_factors.tissue }}</div>
      </div>
      <div class="casus-anamnese-item" [ngClass]="{ 'casus-anamnese-item--deactivated': case.active.wound_factors.infection.trim() == '' }">
        <div class="casus-anamnese-item__head">
          <div class="casus-anamnese-item__icon casus-anamnese-item__icon--infectie"></div>
          <div class="casus-anamnese-item__label">Infectie</div>
        </div>
        <div class="casus-anamnese-item__text">{{ case.active.wound_factors.infection }}</div>
      </div>
      <div class="casus-anamnese-item" [ngClass]="{ 'casus-anamnese-item--deactivated': case.active.wound_factors.wound_fluid.trim() == '' }">
        <div class="casus-anamnese-item__head">
          <div class="casus-anamnese-item__icon casus-anamnese-item__icon--wondvocht"></div>
          <div class="casus-anamnese-item__label">Wondvocht</div>
        </div>
        <div class="casus-anamnese-item__text">{{ case.active.wound_factors.wound_fluid }}</div>
      </div>
      <div class="casus-anamnese-item" [ngClass]="{ 'casus-anamnese-item--deactivated': case.active.wound_factors.wound_edges.trim() == '' }">
        <div class="casus-anamnese-item__head">
          <div class="casus-anamnese-item__icon casus-anamnese-item__icon--wondranden"></div>
          <div class="casus-anamnese-item__label">Wondranden</div>
        </div>
        <div class="casus-anamnese-item__text">{{ case.active.wound_factors.wound_edges }}</div>
      </div>
      <div class="case-answers-title-scroll-position" #caseAnswersTitle></div> <!-- after confirming answer, app will scroll to this -->
    </div>
    <div class="casus-divider">
      <h3>Kies de juiste behandeling</h3>
    </div>
    <div class="casus-thumbnails">
      <div class="casus-item casus-questions" *ngIf="!caseAnswered">
        <div *ngIf="userIsAdmin == true">
          <strong>for admin</strong><br />
          <span [innerHtml]="getCorrectAnswers()|safeHtml"></span>
        </div>
        <div class="menu-section" *ngFor="let grid_item of getCaseGrid()">
          <h4 class="menu-section__title">
            {{ grid_item.category.name }}
          </h4>
          <div class="menu-treatment-wrapper">
            <a class="menu-treatment" *ngFor="let treatment of grid_item.treatments" [attr.data-navigation-id]="'qcare.nav.case.toggle-treatment'" [attr.data-navigation-toggle-treatment]="treatment.id" (click)="toggleTreatment(treatment)" [ngClass]="getTreatmentClass(treatment)">
              <div class="menu-treatment__image-wrapper">
                <img class="menu-treatment__image" src="{{ treatment.image }}">
              </div>
              <div class="menu-treatment__title-wrapper">
                <p class="menu-treatment__title">{{ treatment.name }}</p>
              </div>
            </a>
          </div>
        </div>
        <div class="casus-send">
          <a class="round-button send-casus" [ngClass]="getCheckCaseButtonClass()" [attr.data-navigation-id]="'qcare.nav.case.check'" [attr.data-navigation-case]="case.active.id" (click)="checkCase()">Antwoord bevestigen</a>
        </div>
      </div>
      <div class="casus-item casus-answers" *ngIf="caseAnswered">
        <div class="menu-section flex-row" *ngIf="caseError">
          <div class="casus-result-badge casus-result-badge--false">✘</div>
          <div class="casus-result-badge casus-result-badge--text casus-result-badge--false" *ngIf="missing.length > 0">{{ missingCount }} behandeling{{ missingCount == 1 ? '' : 'en' }} {{ missingCount == 1 ? 'ontbreekt' : 'ontbreken' }}</div>
          <div class="casus-result-badge casus-result-badge--text casus-result-badge--false" *ngIf="missing.length == 0 && wrong.length > 0">foute behandeling{{ wrong.length == 1 ? '' : 'en' }}</div>
        </div>
        <div class="menu-section flex-row" *ngIf="caseSuccess">
          <div class="casus-result-badge">✔</div>
          <div class="casus-result-badge casus-result-badge--text" *ngIf="correct.length > 1">alle behandelingen zijn juist</div>
          <div class="casus-result-badge casus-result-badge--text" *ngIf="correct.length == 1">juiste behandeling</div>
        </div>
        <div class="menu-section">
          <div class="menu-treatment-wrapper">
            <a class="menu-treatment" [ngClass]="getAnswerClass(answer)" *ngFor="let answer of getAnswers()">
              <div class="menu-treatment__image-wrapper">
                <img class="menu-treatment__image" src="{{ answer.treatment.image }}">
              </div>
              <div class="menu-treatment__title-wrapper">
                <p class="menu-treatment__title">{{ answer.treatment.name }}</p>
              </div>
              <div class="casus-answer-number" *ngIf="showAnswerNumber(answer)">
                <p class="casus-answer-number__text">{{ getAnswerNumber(answer) }}</p>
              </div>
            </a>
          </div>
          <div class="menu-message__wrapper menu-message__wrapper--grey" *ngIf="showAnswerNumberExplanation()">
            <div class="casus-answer-number">
              <p class="casus-answer-number__text">1</p>
            </div>
            <p class="menu-message__text">Wanneer behandelingen hetzelfde nummer hebben betekent dit dat er meerdere antwoorden mogelijk zijn. De keuze voor 1 van deze behandelingen wordt goedgekeurd.</p>
          </div>
        </div>
        <div class="menu-section">
          <h4 class="menu-section__title">
            Toelichting
          </h4>
          <div class="casus-toelichting">
            <p>{{ case.active.explanation }}</p>
          </div>
        </div>
        <div class="menu-section">
          <div class="casus-send">
            <a class="round-button next-casus" [attr.data-navigation-id]="'qcare.nav.case.proceed'" [attr.data-navigation-case]="case.active.id" (click)="proceedCase()">{{ getProceedCaseButtonLabel() }}</a>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
