import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';
import { DashboardState } from "./dashboard.state";
import { DashboardService } from "./dashboard.service";
import { ProgressService } from "../shared/services/progress.service";
import { UserStore } from "../shared/stores/user.store";

@Injectable()
export class DashboardStore extends Store<DashboardState> {

  constructor(private dashboardService: DashboardService, private userStore: UserStore) {
    super(new DashboardState());
  }

  fetch() {

    let fetch = this.dashboardService
      .all(this.userStore.getSessionToken())
      .subscribe((data: any) => {

        fetch.unsubscribe();
        this.setState({

          ...this.state,
          action: 'initial',
          chapters: data.chapters,
          progress: data.progress,
          grades: data.grades,
          expiring: data.expiring,
        });
      });
  }
}
