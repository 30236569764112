import { ExamState } from "./states/exam.state";
import { WeightEnum, ScoreByWeight } from "./states/question.state";

export class ExamValidatorService {

  constructor(private exam: ExamState) {}

  validate() {

    let result = {

      correct: this.getTotalCorrect(),
      total: this.getTotalQuestions(),
      maxScore: this.getMaxScore(),
      score: this.getScore(),
      guessPercentage: this.getGuessPercentage(),
      grade: 0,
      graduated: false,
      perfect: false,
    };

    result.grade     = this.calculateGrade(result.maxScore, result.score, result.guessPercentage);
    result.graduated = result.grade >= 5.5;
    result.perfect   = result.grade >= 10;

    return result;
  }

  private getTotalCorrect() {

    return this.exam.answers.reduce((total, selected) => {

      let correct = selected.answers.filter(answer => true === answer.correct).length > 0;
      return total + (true === correct ? 1 : 0);
    }, 0);
  }

  private getTotalQuestions() {
    return this.exam.exam.questions.length;
  }

  private getMaxScore() {

    return this.exam.exam.questions.reduce((total, question) => {
      return total + this.getScoreByWeight(question.weight);
    }, 0);
  }

  private getScoreByWeight(weight: WeightEnum) {
    return ScoreByWeight[weight];
  }

  private getGuessPercentage() {

    let sum = this.exam.exam.questions.reduce((total, question) => {

      let score = this.getScoreByWeight(question.weight);
      let guessScore = score * (1 / question.answers.length);

      return total + guessScore;

    }, 0);

    return sum / this.exam.exam.questions.length;
  }

  private getScore() {

    return this.exam.answers.reduce((total, selected) => {

      let correct = selected.answers.filter(answer => true === answer.correct).length > 0;
      return total + (true === correct ? this.getScoreByWeight(selected.question.weight) : 0);

    }, 0);
  }

  private calculateGrade(maxScore: number, score: number, guessPercentage: number) {

    let guessScore = maxScore * guessPercentage;
    let remainderScore = maxScore - guessScore
    let threshold = 0.5;
    let under = 4.5;
    let over = 5.5;
    let scorePercentage = score / (maxScore / 100);
    let normPercentage = (

      (

        (remainderScore * threshold) + guessScore

      ) / (maxScore / 100)
    );

    let grade = 0;

    if (scorePercentage >= normPercentage) {

      grade = (
        (
          (scorePercentage - normPercentage) * (under / (100 - normPercentage))
        ) + over
      );

    } else {

      grade = (
        (
          scorePercentage * (under / normPercentage)
        ) + 1
      );
    }

    return grade;
  }
};
