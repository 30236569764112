import { CaseItemState } from "./case_item.state";
import { AnswerState } from "./answer.state";

export class CaseState {

  action: string;
  active: CaseItemState;
  done: number[];
  correct: number[];
  progress: number;
  selected: AnswerState[];

  case: {

    id: number;
    course_id: number;
    course_module_id: number;
    rank: number;
    cases: CaseItemState[];
    created_at: Date;
    updated_at: Date;
  };
}
