import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';

@Injectable()
export class LoadingStore extends Store<boolean> {

  constructor() {
    super(false);
  }

  show() {

    if (this.state === true) {
      return;
    }

    this.setState(true);
  }

  hide() {

    if (this.state === false) {
      return;
    }

    this.setState(false);
  }
}
