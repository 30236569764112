import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TransitionService } from '../shared/services/transition.service';
import { ExpiredSubscriptionsModalState } from './expired-subscriptions-modal.state';
import { ExpiredSubscriptionsModalStore } from './expired-subscriptions-modal.store';

@Component({
  selector: 'app-expired-subscriptions-modal',
  templateUrl: './expired-subscriptions-modal.component.html',
  styleUrls: ['./expired-subscriptions-modal.component.scss']
})
export class ExpiredSubscriptionsModalComponent implements OnInit, OnDestroy {

  state: ExpiredSubscriptionsModalState = new ExpiredSubscriptionsModalState();
  private subscriptions: Subscription = new Subscription();

  constructor(private store: ExpiredSubscriptionsModalStore, private transitionService: TransitionService) {}

  ngOnInit(): void {

    this.subscriptions.add(

      this.store.state$.subscribe(state => {
        this.state = state;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  confirm() {
    this.store.confirm();
  }
}
