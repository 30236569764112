export class CompanyCreditsState {

  course_id: number;
  course_name: string;
  credits: number;
}

export class CompanyUserProgressState {

  course_id: number;
  course_name: string;
  done: number;
  total: number;
  percentage: number;
  completed: boolean;
}

export class CompanyUserState {

  user_id: number;
  full_name: string;
  initials: string;
  email: string;
  last_login: string;
  progress: CompanyUserProgressState[];
}

export class CompanyState {

  name: string;
  credits: CompanyCreditsState[];
  users: CompanyUserState[];
}

export class CompanyStoreState {

  action: string;
  company: CompanyState;
}
