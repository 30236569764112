import { Injectable } from "@angular/core";
import { ConnectionService } from "ngx-connection-service";
import { Store } from '../shared/stores/store';

@Injectable({
  providedIn: 'root'
})
export class ConnectionStore extends Store<boolean> {

  private firstConnectionMessageReceived: boolean = false;

  constructor(private connectionService: ConnectionService) {

    super(null);

    this.connectionService.monitor().subscribe(connectionStatus => {

      // on page load "hasInternetAccess" is false because heartbeat url wasn't called yet
      // so we ignore first subscription message by using "firstConnectionMessageReceived" flag
      // to prevent the no connection modal from appearing on page load
      if (true === this.firstConnectionMessageReceived) {
        this.setStatus(connectionStatus.hasInternetAccess && connectionStatus.hasNetworkConnection);
      }

      if (false === this.firstConnectionMessageReceived) {
        this.firstConnectionMessageReceived = true;
      }
    })
  }

  setStatus(status: boolean) {
    this.setState(status);
  }
}
