import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(protected http: HttpClient) {}

  get(path: string, params?: HttpParams | {[param: string]: string | string[]}) {

    return this.http
      .get(this.generatePath(path), {params: params});
  }

  post(path: string, data: {}) {

    return this.http
      .post(this.generatePath(path), data);
  }

  destroy(path: string, data: {}) {

    return this.http
      .delete(this.generatePath(path), {
        params: data
      });
  }

  put(path: string, data: {}) {

    return this.http
      .put(this.generatePath(path), data);
  }

  generatePath(path: string): string {
    return environment.api_url + path;
  }
}
