import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';
import { ReaderState } from "./states/reader.state";
import { ReaderService } from "./reader.service";
import { PageBlockType, ReaderPageState } from "./states/reader_page.state";
import { PageGalleryBlockState } from "./states/page_gallery_block.state";
import { PageTextBlockState } from "./states/page_text_block.state";
import { PageImageBlockState } from "./states/page_image_block.state";
import { PageIllustrationBlockState } from "./states/page_illustration_block.state";
import { PageTreatmentsBlockState } from "./states/page_treatments_block.state";
import { TransitionService } from '../shared/services/transition.service';
import { PageState } from './states/page.state';

@Injectable()
export class ReaderStore extends Store<ReaderState> {

  constructor(private readerService: ReaderService, private transitionService: TransitionService) {
    super(new ReaderState());
  }

  fetch(id: number, currentPageId: number) {

    let fetch = this.readerService
      .fetch(id)
      .subscribe((data: any) => {

        fetch.unsubscribe();

        if (data.reader && data.reader.pages) {

          let kidx          = 0;
          let keywords      = {};
          let preloadImages = {};

          let addPreloadImage = (page: PageState, src: string) => {

            preloadImages[page.id].push({
              src,
              loaded: false
            });
          }

          data.reader.pages.forEach((page: ReaderPageState) => {

            preloadImages[page.page.id] = [];

            page.blocks.forEach(block => {

              if (this.isImageBlock(block)) {
                addPreloadImage(page.page, block.image);
              }

              if (this.isIllustrationBlock(block)) {
                addPreloadImage(page.page, block.illustration);
              }

              if (this.isTreatmentsBlock(block)) {
                block.treatments.forEach(treatment => addPreloadImage(page.page, treatment.image));
              }

              if (this.isGalleryBlock(block)) {
                block.images.forEach(galleryItem => addPreloadImage(page.page, galleryItem.image));
              }

              if (this.isTextBlock(block)) {

                // removing lsep character, that shows up because of copy-paste
                block.text = block.text.replace(/[\u0000\u001F\u007F\u009F\u2028]/g, '');

                for (let keyword in block.help_keywords) {

                  // (?!(\\s+)?\<\/(h1|h2|h3)\>) regex part is used to ignore all the headings
                  block.text     = block.text.replace(new RegExp(keyword + '(?!(\\s+)?\<\/(h1|h2|h3)\>)', 'gim'), `<a class="info-message-link" data-info-block-id="${kidx}">${keyword}</a>`);
                  keywords[kidx] = block.help_keywords[keyword];

                  kidx += 1;
                }
              }

            });

          });

          let total = data.reader.pages.length;

          if (total > 0) {

            this.setState({

              ...this.state,
              action: 'initial',
              reader: data.reader,
              total: total,
              active: null,
              next_case: data.next_case,
              next_quiz: data.next_quiz,
              next_exam: data.next_exam,
              keywords: keywords,
              preloadImages: preloadImages,
            });

            // activating page
            this.activatePageById(currentPageId);
          }
        }
      });
  }

  preloadImages() {

    if (!(this.state.active.page.id in this.state.preloadImages)) {

      // no images to preload
      this.imagesLoaded();
      return;
    }

    // loading current state of preloads for the active page
    let preloads = [...this.state.preloadImages[this.state.active.page.id]];

    if (preloads.length === 0) {

      // no images to preload
      this.imagesLoaded();
      return;
    }

    // checking whether images are all loaded
    let markAsLoaded = (idx) => {

      // mark image as loaded
      preloads[idx].loaded = true;

      // checking whether all the images on the page are loaded
      // filtering out all the non loaded images
      if (preloads.filter(preload => false === preload.loaded).length === 0) {

        // all images are loaded
        this.imagesLoaded();
      }
    }

    preloads.forEach((_, idx) => {

      let image = new Image();

      image.onload = () => markAsLoaded(idx);
      image.onerror = () => markAsLoaded(idx);
      image.onabort = () => markAsLoaded(idx);

      image.src = preloads[idx].src;
    });
  }

  imagesLoaded() {

    this.setState({

      ...this.state,
      action: 'images-loaded',
    });
  }

  nextPage() {

    if (this.state.current >= (this.state.total - 1)) {

      // already on final page
      return;
    }

    let next = this.state.current + 1;

    if (next === (this.state.total - 1)) {

      // activating final page, adding user reader completed progress
      this.setState({

        ...this.state,
        action: 'reader-completed'
      });
    }

    this.activatePage(this.state.reader.pages[next]);
  }

  previousPage() {

    if (this.state.current <= 0) {

      // already on first page
      return;
    }

    let previous = this.state.current - 1;

    this.activatePage(this.state.reader.pages[previous]);
  }

  activatePageById(pageId: number) {

    if (null !== this.state.active && this.state.active.page.id === pageId) {

      // activating page is already active
      return;
    }

    let idx = this.state.reader.pages.findIndex(page => {
      return page.page.id === pageId;
    });

    if (idx === -1) {
      return;
    }

    this.activatePage(this.state.reader.pages[idx]);
  }

  activatePage(page: ReaderPageState) {

    let pidx = this.state.reader.pages.findIndex(readerPage => {
      return readerPage.page.id === page.page.id;
    });

    if (pidx === -1) {

      // page was not found
      return;
    }

    // resetting active image to first image
    page.blocks.forEach(block => {

      if (this.isGalleryBlock(block)) {

        block.images.forEach((image, idx) => {

          // if only 2 images or less are available, make all of them active
          // or else only activate the first image
          image.active = block.images.length <= 2;

          if (idx === 0) {
            image.active = true;
          }
        });

      }
    });

    this.setState({

      ...this.state,
      action: 'activate-page',
      active: this.state.reader.pages[pidx],
      current: pidx,
      progress: (pidx + 1) / (this.state.total) * 100,
    });

    this.transitionService.navigate([`reader/${this.state.reader.id}/${this.state.reader.pages[pidx].page.id}`]);
  }

  isFinalPage() {
    return this.state.current === (this.state.total - 1);
  }

  isFirstPage() {
    return this.state.current === 0;
  }

  hasNextPage() {
    return this.state.current < (this.state.total - 1);
  }

  isImageBlock(block: PageBlockType): block is PageImageBlockState {
    return (block as PageImageBlockState).block === 'image';
  }

  isIllustrationBlock(block: PageBlockType): block is PageIllustrationBlockState {
    return (block as PageIllustrationBlockState).block === 'illustration';
  }

  isTreatmentsBlock(block: PageBlockType): block is PageTreatmentsBlockState {
    return (block as PageTreatmentsBlockState).block === 'treatments';
  }

  isGalleryBlock(block: PageBlockType): block is PageGalleryBlockState {
    return (block as PageGalleryBlockState).block === 'gallery';
  }

  isTextBlock(block: PageBlockType): block is PageTextBlockState {
    return (block as PageTextBlockState).block === 'text';
  }
}
