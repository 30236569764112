import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '../shared/services/notification.service';
import { NotificationStore } from '../shared/stores/notification.store';
import { NotificationState } from '../shared/stores/states/notification.state';
import { UserStore } from '../shared/stores/user.store';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {

  public notifications: NotificationState[] = [];

  constructor(private notificationStore: NotificationStore, private notificationService: NotificationService, private userStore: UserStore) { }

  ngOnInit(): void {

    // getting initial notifications
    this.notifications = this.notificationStore.getNotifications();

    // fetching user notifications
    let sessionToken = this.userStore.getSessionToken();

    if (null === sessionToken) {

      // user isn't logged in, no use in fetching notifications
      return;
    }

    this.notificationService.fetch(sessionToken)
      .subscribe(
        (data: {notifications: NotificationState[]}) => {

          // merging user notifications
          this.notifications = [...this.notifications, ...data.notifications];

          // and clearing user notifications
          this.notificationService.clear(sessionToken);
        },
        _ => {
          // api error
        }
      );
  }

  ngOnDestroy(): void {
    this.notificationStore.clear();
  }
}
