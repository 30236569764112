import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService extends ApiService {

  forgotPassword(email: string) {
    return this.post('/sessions/forgot_password', {email});
  }
}
