import { DatePickerOptions } from "src/app/shared/datepicker.options";
import { ENVIRONMENTS } from "src/app/shared/environments";

export const environment = {
  production: true,
  environment: ENVIRONMENTS.STAGING,
  backend_url: 'https://stag-backend.qcare.nl',
  api_url: 'https://stag-backend.qcare.nl/api',
  heart_beat_url: 'https://stag-backend.qcare.nl/heartbeat',
  instrumentation_key: '',
  datepicker_options: DatePickerOptions,
};
