import { Injectable } from "@angular/core";
import { Store } from './store';
import { SlidersState } from './states/sliders.state';

@Injectable()
export class SlidersStore extends Store<SlidersState> {

  constructor() {

      super(new SlidersState());
      this.setInitialDashboardPositions();
      this.setInitialTreatmentsPositions();
      this.setInitialQuizPositions();
      this.setInitialQuizReviewPositions();
      this.setInitialExamPositions();
      this.setInitialCasePositions();
  }

  setInitialDashboardPositions() {

    this.setState({

      ...this.state,
      dashboard: {

        position: 0,
        index: 0,
        positions: [0]
      }
    });
  }

  setInitialTreatmentsPositions() {

    this.setState({

      ...this.state,
      treatments: {

        position: 0,
        index: 0,
        positions: [0]
      }
    });
  }

  setInitialQuizPositions() {

    this.setState({

      ...this.state,
      quiz: {

        position: 0,
        index: 0,
        positions: [0]
      }
    });
  }

  setInitialQuizReviewPositions() {

    this.setState({

      ...this.state,
      quizreview: {

        position: 0,
        index: 0,
        positions: [0]
      }
    });
  }

  setInitialExamPositions() {

    this.setState({

      ...this.state,
      exam: {

        position: 0,
        index: 0,
        positions: [0]
      }
    });
  }

  setInitialCasePositions() {

    this.setState({

      ...this.state,
      case: {

        position: 0,
        index: 0,
        positions: [0]
      }
    });
  }

  setDashboardPositions(positions: number[]) {

    this.setState({

      ...this.state,
      dashboard: {

        ...this.state.dashboard,
        positions: positions
      }
    });
  }

  nextDashboardPage() {

    let nextPage = this.state.dashboard.index + 1;
    let pages    = this.state.dashboard.positions.length - 1;

    this.setState({

      ... this.state,
      dashboard: {

        ...this.state.dashboard,
        index: nextPage > pages ? (this.state.dashboard.positions.length - 1) : nextPage,
      }
    });
  }

  previousDashboardPage() {

    let previousPage = this.state.dashboard.index - 1;

    this.setState({

      ... this.state,
      dashboard: {

        ...this.state.dashboard,
        index: previousPage < 0 ? 0 : previousPage,
      }
    });
  }

  setTreatmentsPositions(positions: number[]) {

    this.setState({

      ...this.state,
      treatments: {

        ...this.state.treatments,
        positions: positions
      }
    });
  }

  nextTreatmentsPage() {

    let nextPage = this.state.treatments.index + 1;
    let pages    = this.state.treatments.positions.length - 1;

    this.setState({

      ... this.state,
      treatments: {

        ...this.state.treatments,
        index: nextPage > pages ? (this.state.treatments.positions.length - 1) : nextPage,
      }
    });
  }

  previousTreatmentsPage() {

    let previousPage = this.state.treatments.index - 1;

    this.setState({

      ... this.state,
      treatments: {

        ...this.state.treatments,
        index: previousPage < 0 ? 0 : previousPage,
      }
    });
  }

  setQuizPositions(positions: number[]) {

    this.setState({

      ...this.state,
      action: 'quiz',
      quiz: {

        ...this.state.quiz,
        positions: positions
      }
    });
  }

  nextQuizQuestion() {

    let nextQuestion = this.state.quiz.index + 1;
    let questions    = this.state.quiz.positions.length - 1;

    this.setState({

      ... this.state,
      action: 'quiz',
      quiz: {

        ...this.state.quiz,
        index: nextQuestion >= questions ? questions : nextQuestion,
      }
    });
  }

  previousQuizQuestion() {

    let previousQuizQuestion = this.state.quiz.index - 1;

    this.setState({

      ... this.state,
      action: 'quiz',
      quiz: {

        ...this.state.quiz,
        index: previousQuizQuestion < 0 ? 0 : previousQuizQuestion,
      }
    });
  }

  setQuizReviewPositions(positions: number[]) {

    this.setState({

      ...this.state,
      action: 'quizreview',
      quizreview: {

        ...this.state.quizreview,
        positions: positions
      }
    });
  }

  nextQuizReviewQuestion() {

    let nextQuestion = this.state.quizreview.index + 1;
    let questions    = this.state.quizreview.positions.length - 1;

    if (nextQuestion > questions) {
      this.showQuizReviewMenu();
    } else {

      this.setState({

        ... this.state,
        action: 'quizreview',
        quizreview: {

          ...this.state.quizreview,
          index: nextQuestion >= questions ? questions : nextQuestion,
        }
      });
    }
  }

  showQuizReviewMenu() {

    this.setState({

      ... this.state,
      action: 'quizreviewshowmenu',
    });
  }

  showQuizReviewSucceededMenu() {

    this.setState({

      ... this.state,
      action: 'quizreviewshowsucceededmenu',
    });
  }

  hideQuizReviewMenu() {

    this.setState({

      ... this.state,
      action: 'quizreviewhidemenu',
    });
  }

  previousQuizReviewQuestion() {

    this.hideQuizReviewMenu();

    let previousQuizQuestion = this.state.quizreview.index - 1;

    this.setState({

      ... this.state,
      action: 'quizreview',
      quizreview: {

        ...this.state.quizreview,
        index: previousQuizQuestion < 0 ? 0 : previousQuizQuestion,
      }
    });
  }

  setExamPositions(positions: number[]) {

    this.setState({

      ...this.state,
      action: 'exam',
      exam: {

        ...this.state.exam,
        positions: positions
      }
    });
  }

  nextExamQuestion() {

    let nextQuestion = this.state.exam.index + 1;
    let questions    = this.state.exam.positions.length - 1;

    this.setState({

      ... this.state,
      exam: {

        ...this.state.exam,
        index: nextQuestion >= questions ? questions : nextQuestion,
      }
    });
  }

  previousExamQuestion() {

    let previousExamQuestion = this.state.exam.index - 1;

    this.setState({

      ... this.state,
      exam: {

        ...this.state.exam,
        index: previousExamQuestion < 0 ? 0 : previousExamQuestion,
      }
    });
  }

  setCasePositions(positions: number[]) {

    this.setState({

      ...this.state,
      case: {

        ...this.state.case,
        positions: positions
      }
    });
  }

  nextCaseSlide() {

    let nextSlide = this.state.case.index + 1;
    let pages    = this.state.case.positions.length - 1;

    this.setState({

      ... this.state,
      case: {

        ...this.state.case,
        index: nextSlide > pages ? (this.state.case.positions.length - 1) : nextSlide,
      }
    });
  }

  previousCaseSlide() {

    let previousSlide = this.state.case.index - 1;

    this.setState({

      ... this.state,
      case: {

        ...this.state.case,
        index: previousSlide < 0 ? 0 : previousSlide,
      }
    });
  }
}
