<div class="disable-pointer-events reader-loading" [class.reader-loading--hidden]="loaderHidden">
  <div class="reader-loading-wrapper">
    <div class="reader-loading__circle"></div>
    <svg class="reader-loading__circle" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" r="32" stroke="rgb(240, 240, 240)" stroke-width="10" fill="none"></circle>
      <circle class="reader-loading-animation__circle__stroke" cx="50" cy="50" r="32" stroke="#d1d1d1" stroke-dasharray="50 100" stroke-width="10" stroke-linecap="square" fill="none" transform="rotate(497.745 50 50)"></circle>
    </svg>
  </div>
</div>
<div class="nav-bar" [class.nav-bar--hidden]="navbarHidden">
  <div class="nav-bar-section-wrapper">
    <section class="nav-bar-menu">
      <a class="nav-icon nav-icon--qcare"></a>
      <div class="button-container">
        <a class="nav-text" (click)="goToDashboard()">Lesmodules</a>
        <a class="nav-text nav-text--active">Naslagwerk</a>
      </div>
      <div class="button-container">
        <a class="nav-icon nav-icon--account" (click)="goToAccount()"></a>
        <a class="nav-icon nav-icon--settings" (click)="goToSettings()"></a>
      </div>
    </section>
  </div>
</div>
<section class="treatments-menu" *ngIf="treatments && isLoaded">
  <div class="menu-content-wrapper">
    <div class="menu-section" *ngFor="let category of treatments.treatments">
      <h4 class="menu-section__title">
        {{ category.name }}
      </h4>
      <div class="menu-treatment-wrapper">
        <a class="menu-treatment" *ngFor="let treatment of category.treatments" (click)="goToTreatment(treatment)">
          <div class="menu-treatment__image-wrapper">
            <img class="menu-treatment__image" src="{{ treatment.image }}">
          </div>
          <div class="menu-treatment__title-wrapper">
            <p class="menu-treatment__title">{{ treatment.name }}</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
