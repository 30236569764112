<div class="disable-pointer-events reader-loading" [class.reader-loading--hidden]="loaderHidden">
  <div class="reader-loading-wrapper">
    <div class="reader-loading__circle"></div>
    <svg class="reader-loading__circle" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" r="32" stroke="rgb(240, 240, 240)" stroke-width="10" fill="none"></circle>
      <circle class="reader-loading-animation__circle__stroke" cx="50" cy="50" r="32" stroke="#d1d1d1" stroke-dasharray="50 100" stroke-width="10" stroke-linecap="square" fill="none" transform="rotate(497.745 50 50)"></circle>
    </svg>
  </div>
</div>
<div class="nav-bar" [class.nav-bar--hidden]="navbarHidden">
  <div class="nav-bar-section-wrapper">
    <section class="nav-bar-menu" *ngIf="!treatment || !treatment.page">
      <a class="nav-icon nav-icon--qcare"></a>
      <div class="button-container">
        <a class="nav-text" (click)="goToDashboard()">Lesmodules</a>
        <a class="nav-text" (click)="goToTreatments()">Naslagwerk</a>
      </div>
      <div class="button-container">
        <a class="nav-icon nav-icon--account" (click)="goToAccount()"></a>
        <a class="nav-icon nav-icon--settings" (click)="goToSettings()"></a>
      </div>
    </section>
    <section class="nav-bar-reader" *ngIf="treatment && treatment.page">
      <div class="button-container">
        <a class="nav-icon nav-icon--border nav-icon--prev nav-icon--prev--disabled"></a>
        <a class="nav-icon nav-icon--border nav-icon--next nav-icon--next--disabled"></a>
        <div class="nav-bar-item nav-section">{{ treatment.page.name }}</div>
      </div>
      <a class="nav-icon nav-icon--close" (click)="closeTreatment()"></a>
    </section>
  </div>
</div>
<section class="treatment-page" *ngIf="treatment && isLoaded">
  <div class="reader-content-wrapper">
    <div class="reader-treatment">
      <div class="reader-treatment__wrapper">
        <div class="reader-treatment__image-wrapper">
          <img class="reader-treatment__image" src="{{ treatment.treatment.image }}">
        </div>
        <div class="reader-treatment__info-wrapper">
          <p class="reader-treatment__title">{{ treatment.treatment.name }}</p>
          <p class="reader-treatment__info-text" [innerHtml]="treatment.treatment.indication"></p>
        </div>
      </div>
      <div class="reader-treatment__full-text" [innerHtml]="treatment.treatment.description"></div>
      <a class="reader-treatment__more-button" (click)="closeTreatment()">terug gaan</a>
    </div>
  </div>
</section>
