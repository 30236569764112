<div class="nav-bar" [class.nav-bar--hidden]="null === exam || null === exam.question">
    <div class="nav-bar-progressbar">
      <div class="nav-bar-progressbar__percentage" [ngStyle]="getExamPercentageStyle()"></div>
    </div>
    <div class="nav-bar-section-wrapper">
      <section class="nav-bar-exam" *ngIf="isQuestionActive()">
        <div class="button-container">
          <a class="nav-bar-item nav-section nav-section--clock">
            <app-timer seconds="{{ savedTime }}" (done)="validateExam()" (ticker)="timerTick($event)" #timer></app-timer>
          </a>
          <a class="nav-icon nav-icon--border nav-icon--prev" (click)="goToPreviousQuestion()" [ngClass]="getPreviousQuestionLinkClass()"></a>
          <a class="nav-icon nav-icon--border nav-icon--next" (click)="goToNextQuestion()" [ngClass]="getNextQuestionLinkClass()"></a>
          <a class="nav-icon nav-icon--border nav-icon--admin" (click)="goToBackend()" *ngIf="userIsAdmin == true"></a>
          <div class="nav-bar-item nav-section nav-section--light">{{ getCurrentQuestionLabel() }}</div>
        </div>
      </section>
    </div>
  </div>
  <section class="main-exam" *ngIf="exam && isLoaded">
    <div class="exam-content-wrapper">
      <div class="exam-items-container">
        <div class="exam-item exam-item--message exam-item--active">
          <div class="question-container question-container--message">
            <h2>Dit examen bevat {{ getTotalQuestions() }} vragen. U heeft {{ getExamDurationLabel() }} de tijd voor dit examen. De klok start met lopen wanneer u op start drukt.<br /><br />U mag nog {{ exam.remaining_tries }} pogingen wagen.</h2>
            <a class="round-button" (click)="startExam()">start examen</a>
          </div>
        </div>
        <div class="exam-item exam-item--question" *ngFor="let question of exam.exam.questions; let idx = index">
          <div class="question-container">
            <div class="question-wrapper">
              <div class="exam-question">
                <h1>{{ question.question }}</h1>
              </div>
              <ul class="exam-answers">
                <li *ngFor="let answer of question.answers" [ngClass]="getAnswerClass(question, answer)" (click)="markAnswer(question, answer)">
                  <a>
                    {{ answer.answer }}
                    <ng-template [ngIf]="userIsAdmin == true && answer.correct == true">
                      <br  />
                      <small>(correct answer)</small>
                    </ng-template>
                  </a>
                </li>
              </ul>
              <a class="round-button" (click)="goToNextQuestion(true)" [ngClass]="{ 'round-button--hidden': hideConfirmButton(question) }">bevestigen</a>
            </div>
          </div>
        </div>
        <div class="exam-item exam-item--message">
          <div class="question-container question-container--message">
            <h2>U heeft alle vragen beantwoord. Druk op bevestigen om uw antwoorden te verzenden.</h2>
            <div class="flex-row">
              <a class="round-button round-button--cancel" (click)="goBackToExam()">terug gaan</a>
              <a class="round-button" (click)="validateExam()">bevestigen</a>
            </div>
          </div>
        </div>
        <div class="exam-item exam-item--message">
          <div class="question-container question-container--message">
            <div class="flex-row">
              <div class="exam-result-badge" [ngClass]="getExamBadgeClass()">{{ getExamBadge() }}</div>
              <div class="exam-result-badge" [ngClass]="getExamBadgeClass()">{{ getExamGrade() }}</div>
            </div>
            <h2>{{ getExamResult() }}</h2>
            <div class="flex-row">
              <a class="round-button" (click)="goToDashboard()">naar hoofdmenu</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
