import { Injectable } from '@angular/core';
import { ApplicationInsights, IExceptionTelemetry, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { UserState } from '../stores/states/user.state';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { ENVIRONMENTS } from '../environments';

export enum ApmEvents {

  UNSUPPORTED_BROWSER                       = 'qcare.unsupported_browser',
  APP_START                                 = 'qcare.app.start',
  DASHBOARD_START                           = 'qcare.dashboard.start',
  LOGIN_START                               = 'qcare.login.start',
  LOGIN_SUCCESS                             = 'qcare.login.success',
  LOGIN_FAILED                              = 'qcare.login.failed',
  LOGIN_REDIRECT_DASHBOARD                  = 'qcare.login.redirect.dashboard',
  LOGIN_REDIRECT_FORGOT_PASSWORD            = 'qcare.login.redirect.forgot_password',
  READER_START                              = 'qcare.reader.start',
  READER_ACTIVATE_PAGE                      = 'qcare.reader.activate_page',
  READER_COMPLETED                          = 'qcare.reader.completed',
  QUIZ_START                                = 'qcare.quiz.start',
  QUIZ_INTRODUCTION                         = 'qcare.quiz.introduction',
  QUIZ_ACTIVATE_QUESTION                    = 'qcare.quiz.activate_question',
  QUIZ_ACTIVATE_CONFIRMATION                = 'qcare.quiz.activate_confirmation',
  QUIZ_ACTIVATE_ANSWERS                     = 'qcare.quiz.activate_answers',
  QUIZ_COMPLETED                            = 'qcare.quiz.completed',
  EXAM_START                                = 'qcare.exam.start',
  EXAM_RESTORE_STATE                        = 'qcare.exam.restore_state',
  EXAM_INTRODUCTION                         = 'qcare.exam.introduction',
  EXAM_ACTIVATE_QUESTION                    = 'qcare.exam.activate_question',
  EXAM_ACTIVATE_CONFIRMATION                = 'qcare.exam.activate_confirmation',
  EXAM_COMPLETED                            = 'qcare.exam.completed',
  CASE_START                                = 'qcare.case.start',
  CASE_ACTIVATE_CASE                        = 'qcare.case.activate_case',
  CASE_ADD_CORRECT                          = 'qcare.case.add_correct',
  CASE_COMPLETED                            = 'qcare.case.completed',
};

@Injectable({
  providedIn: 'root'
})
export class ApmAppService {

  private apm: ApplicationInsights = null;
  private clickPlugin: ClickAnalyticsPlugin = null;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
  }

  initialize() {

    if (environment.environment !== ENVIRONMENTS.PRODUCTION) {
      return;
    }

    if (null !== this.apm) {
      return;
    }

    this.clickPlugin = new ClickAnalyticsPlugin();

    this.apm = new ApplicationInsights({
      config: {

        appId: 'qcare-app',
        instrumentationKey: environment.instrumentation_key,
        disableFetchTracking: false,
        enableCorsCorrelation: true,
        enableAutoRouteTracking: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        extensions: [this.clickPlugin],
        extensionConfig: {
          [this.clickPlugin.identifier]: {
            autoCapture: true,
            urlCollectHash: true,
            urlCollectQuery: true,
            dataTags: {
              useDefaultContentNameOrId: true
            }
          }
        }
      }
    });

    this.apm.loadAppInsights();
    this.loadCustomTelemetryProperties();
    this.createRouterSubscription();
  }

  loadCustomTelemetryProperties() {

    if (null === this.apm) {
      return;
    }

    this.apm.addTelemetryInitializer(envelope => {

      let item = envelope.baseData;
      item.properties = item.properties || {};
      item.properties['ApplicationPlatform'] = 'Web';
      item.properties['ApplicationName'] = 'qcare-app';
    });

    this.trackEvent(ApmEvents.APP_START);
  }

  logPageView(name ?: string, uri ?: string) {

    if (null === this.apm) {
      return;
    }

    this.apm.trackPageView({

      name,
      uri
    });
  }

  createRouterSubscription() {

    if (null === this.apm) {
      return;
    }

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {

        let loggablePage = ApmAppService.getLastChild(this.activatedRoute.firstChild);

        if (loggablePage) {
          this.logPageView(loggablePage.snapshot.data.title, event.urlAfterRedirects);
        }
      });
  }

  private static getLastChild(child: ActivatedRoute): ActivatedRoute {
    return child.firstChild ? ApmAppService.getLastChild(child.firstChild) : child;
  }

  setUserContext(user: UserState) {

    if (null === this.apm) {

      // apm wasn't initialized, ignore
      return;
    }

    this.apm.setAuthenticatedUserContext(`apm.context.${user.id}`);
  }

  removeUserContext() {

    if (null === this.apm) {

      // apm wasn't initialized, ignore
      return;
    }

    this.apm.clearAuthenticatedUserContext();
  }

  logException(error: Error) {

    if (null === this.apm) {
      return;
    }

    let exception: IExceptionTelemetry = {
      exception: error
    };

    this.apm.trackException(exception);
  }

  trackEvent(name: ApmEvents, props ?: {}) {

    if (null === this.apm) {
      return;
    }

    this.apm.trackEvent({
      name,
      properties: props
    });
  }
}
