import { QuestionState } from "./question.state";
import { AnswerState } from "./answer.state";
import { ResultState } from "./result.state";

export class QuizState {

  action: string;
  slide: number;
  slides: number;
  confirmed: boolean;
  question: QuestionState;
  result: ResultState;
  resultQuestion: QuestionState;
  answers: {

    question: QuestionState;
    answers: AnswerState[];
  }[];

  quiz: {
    id: number;
    course_id: number;
    course_module_id: number;
    rank: number;
    questions: QuestionState[];
    created_at: Date;
    updated_at: Date;
  };
}
