import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { UserState } from '../shared/stores/states/user.state';

@Injectable({
  providedIn: 'root'
})
export class CompanyNewUserService extends ApiService {

  fetch(session_token: string) {
    return this.get('/company', {session_token});
  }

  addUser(user: UserState, subscriptions: number[], session_token: string) {

    return this.post('/company/users', {

      session_token: session_token,
      new_user: {

        title: user.title,
        initials: user.initials,
        last_name: user.last_name,
        email: user.email,
        subscriptions: subscriptions,
      }
    });
  }
}
