import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TransitionService } from '../shared/services/transition.service';
import { TreatmentsStore } from './treatments.store';
import { TreatmentsState } from './treatments.state';
import { TreatmentState } from './treatment.state';
import { LoadingStore } from '../loading/loading.store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-treatments',
  templateUrl: './treatments.component.html',
  styleUrls: ['./treatments.component.scss'],
  providers: [TreatmentsStore],
})
export class TreatmentsComponent implements OnInit, OnDestroy {

  treatments: TreatmentsState = null;
  isLoaded: boolean = false;
  navbarHidden: boolean = true;
  loaderHidden: boolean = true;

  private subscriptions: Subscription = new Subscription();
  private navigatedFromDashboard: boolean = false;

  constructor(private treatmentsStore: TreatmentsStore, private loadingStore: LoadingStore, private transitionService: TransitionService, private router: Router) {

    let currentNavigation = this.router.getCurrentNavigation();

    if (currentNavigation && currentNavigation.extras.state && currentNavigation.extras.state.navigatedFromDashboard) {

      this.navbarHidden = false;
      this.navigatedFromDashboard = true;
    }
  }

  ngOnInit() {

    if (false === this.navigatedFromDashboard) {

      // if navigation is not from dashboard show green loading screen
      this.loadingStore.show();

    } else {

      // navigated from dashboard, show grey loader
      this.loaderHidden = false;
    }

    this.subscriptions.add(

      this.treatmentsStore.state$.subscribe(data => {

        if (data.action === 'initial') {

          this.treatments = data;

          window.setTimeout(() => {

            this.navbarHidden = false;

            if (true === this.navigatedFromDashboard) {

              // navigated from dashboard show grey loader
              this.loaderHidden = true;
            }

            this.loadingStore.hide();
            this.isLoaded = true;

          }, 2000);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  dump(data) {
    // console.log(data);
  }

  goToDashboard() {

    this.transitionService
      .navigate(['dashboard'], {state: {navigatedFromTreatments: true}});
  }

  goToTreatment(treatment: TreatmentState) {

    this.transitionService
      .navigate(['treatments/' + treatment.id]);
  }

  goToAccount() {

    this.transitionService
      .navigate(['account']);
  }

  goToSettings() {

    this.transitionService
      .navigate(['settings']);
  }
}
