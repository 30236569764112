export class SlidersState {
  action: string;

  dashboard: {
    position: number;
    index: number;
    positions: number[];
  };

  treatments: {

    position: number;
    index: number;
    positions: number[];
  };

  quiz: {

    position: number;
    index: number;
    positions: number[];
  };

  quizreview: {

    position: number;
    index: number;
    positions: number[];
  };

  exam: {

    position: number;
    index: number;
    positions: number[];
  };

  case: {

    position: number;
    index: number;
    positions: number[];
  };
}
