import { Pipe, PipeTransform } from '@angular/core';
import { NotificationState } from '../stores/states/notification.state';

@Pipe({
  name: 'notificationFilter'
})
export class NotificationFilterPipe implements PipeTransform {

  // filter out expiring notifications if priority
  // notifications (e.g. the green/red ones) are available
  transform(notifications: NotificationState[]): NotificationState[] {

    let priorityNotifications = notifications.filter(notfication => notfication.kind !== 'expiring');

    return priorityNotifications.length > 0 ? priorityNotifications : notifications;
  }
}
