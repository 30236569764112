<div class="disable-pointer-events reader-loading" [class.reader-loading--hidden]="loaderHidden">
  <div class="reader-loading-wrapper">
    <div class="reader-loading__circle"></div>
    <svg class="reader-loading__circle" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" r="32" stroke="rgb(240, 240, 240)" stroke-width="10" fill="none"></circle>
      <circle class="reader-loading-animation__circle__stroke" cx="50" cy="50" r="32" stroke="#d1d1d1" stroke-dasharray="50 100" stroke-width="10" stroke-linecap="square" fill="none" transform="rotate(497.745 50 50)"></circle>
    </svg>
  </div>
</div>
<div class="nav-bar" [class.nav-bar--hidden]="navbarHidden">
  <div class="nav-bar-section-wrapper">
    <section class="nav-bar-menu">
      <a class="nav-icon nav-icon--qcare"></a>
      <div class="button-container">
        <a class="nav-text nav-text--active">Lesmodules</a>
        <a class="nav-text" [attr.data-navigation-id]="'qcare.nav.dashboard.treatments'" (click)="goToTreatments()">Naslagwerk</a>
      </div>
      <div class="button-container">
        <a class="nav-icon nav-icon--account" [attr.data-navigation-id]="'qcare.nav.dashboard.account'" (click)="goToAccount()"></a>
        <a class="nav-icon nav-icon--settings" [attr.data-navigation-id]="'qcare.nav.dashboard.settings'" (click)="goToSettings()"></a>
      </div>
    </section>
  </div>
</div>
<section class="menu-subscription-message" *ngIf="dashboard && progress && isLoaded && dashboard.chapters.length === 0">
  <div class="menu-subscription-message__head">Geen cursussen gevonden</div>
  <div class="menu-subscription-message__body">De toegang tot uw eerder gevolgde cursussen is verlopen.<br>Schaf een nieuwe cursus aan in de webshop of via uw werkgever.</div>
</section>
<section class="main-menu" *ngIf="dashboard && progress && isLoaded && dashboard.chapters.length > 0">
  <div class="menu-content-wrapper" [class.menu-content-wrapper--modal]="modalActive">
    <app-notifications></app-notifications>
    <ng-template ngFor let-chapter let-isFirstSection="first" [ngForOf]="dashboard.chapters" *ngIf="dashboard">
      <div class="menu-section">
        <h4 class="menu-section__title">
          {{ chapter.name }}
        </h4>
        <div class="menu-chapter-wrapper">
          <ng-template ngFor let-course let-isFirstCourse="first" [ngForOf]="chapter.modules">
            <div class="menu-chapter" [attr.data-navigation-id]="'qcare.nav.dashboard.toggle-chapter'" [attr.data-navigation-chapter]="course.id" (click)="toggleChapter(course.id)" [ngClass]="progress['modules'][course.id].classes" [style.background-image]="course.image ? 'url(' + course.image + ')' : null">
              <div class="menu-chapter__content">
                <div class="menu-chapter__info">
                  <div class="menu-chapter__title">{{ getCourseLabel(course) }}</div>
                  <div class="menu-chapter__duration">{{ formatTime(course.duration_hours, course.duration_minutes) }}</div>
                </div>
                <div class="menu-chapter__overlay">
                  <div class="menu-chapter__description">{{ course.description }}</div>
                  <div class="menu-chapter__button-wrapper">
                    <ng-template ngFor let-course_module_item [ngForOf]="getCourseModuleItems(course)">
                      <a class="menu-chapter__button" [ngClass]="progress['items'][course_module_item.id].classes" *ngIf="course_module_item.item_type == 'Exam'" [attr.data-navigation-id]="'qcare.nav.dashboard.exam'" [attr.data-navigation-exam]="course_module_item.item_id" (click)="goToExam(course.id, course_module_item)">
                        <div>examen</div>
                        <div class="menu-chapter__score" *ngIf="progress['items'][course_module_item.id].grade != null">{{ progress['items'][course_module_item.id]['grade'] | number:'1.0-1' }}</div>
                      </a>
                      <a class="menu-chapter__button" [ngClass]="progress['items'][course_module_item.id].classes" *ngIf="course_module_item.item_type == 'Reader'" [attr.data-navigation-id]="'qcare.nav.dashboard.reader'" [attr.data-navigation-reader]="course_module_item.item_id" (click)="goToReader(course.id, course_module_item)"><div>lesmodule</div></a>
                      <a class="menu-chapter__button" [ngClass]="progress['items'][course_module_item.id].classes" *ngIf="course_module_item.item_type == 'Case'" [attr.data-navigation-id]="'qcare.nav.dashboard.case'" [attr.data-navigation-case]="course_module_item.item_id" (click)="goToCase(course.id, course_module_item)"><div>praktijktraining</div></a>
                      <a class="menu-chapter__button" [ngClass]="progress['items'][course_module_item.id].classes" *ngIf="course_module_item.item_type == 'Quiz'" [attr.data-navigation-id]="'qcare.nav.dashboard.quiz'" [attr.data-navigation-quiz]="course_module_item.item_id" (click)="goToQuiz(course.id, course_module_item)">
                        <div>overhoring</div>
                        <div class="menu-chapter__score" *ngIf="progress['items'][course_module_item.id].grade != null">{{ progress['items'][course_module_item.id]['grade'] | number:'1.0-1' }}</div>
                      </a>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="menu-chapter__bottom-bar">
                <div class="menu-chapter__locked">
                  <div class="menu-chapter__locked__lock">
                  </div>
                </div>
                <div class="menu-chapter__progress">
                  <div class="menu-chapter__progress__container">
                    <div class="menu-chapter__progress__percentage" [ngStyle]="{width: progress['modules'][course.id].percentage + '%'}">
                    </div>
                  </div>
                </div>
                <div class="menu-chapter__complete">
                  <p class="menu-chapter__complete__text">voltooid</p>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-template>
  </div>
</section>
