import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DashboardStore } from './dashboard.store';
import { DashboardState, ExpiringSubscription } from './dashboard.state';
import { TransitionService } from '../shared/services/transition.service';
import { CourseState, CourseKind } from '../shared/stores/states/course.state';
import { UserState } from '../shared/stores/states/user.state';
import { UserStore } from '../shared/stores/user.store';
import { LoadingStore } from '../loading/loading.store';
import { CourseModuleItemState } from '../shared/stores/states/course-module-item.state';
import { ModalStore } from '../shared/stores/modal.store';
import { Router } from '@angular/router';
import { ApmAppService, ApmEvents } from '../shared/services/apm_app.service';
import { NotificationStore } from '../shared/stores/notification.store';

export class BundledSub {

  [remaining_days: number]: string[]
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DashboardStore],
})
export class DashboardComponent implements OnInit, OnDestroy {

  course_ranks = {};
  progress = null;
  dashboard: DashboardState = null;
  user: UserState = null;
  navbarHidden: boolean = true;
  isLoaded: boolean = false;
  isMobile: boolean = false;
  modalActive: boolean = false;
  loaderHidden: boolean = true;

  private subscriptions: Subscription = new Subscription();
  private navigatedFromTreatments: boolean = false;

  constructor(private dashboardStore: DashboardStore, private transitionService: TransitionService, private userStore: UserStore, private loadingStore: LoadingStore, private modalStore: ModalStore, private deviceService: DeviceDetectorService, private router: Router, private apm: ApmAppService, private notificationStore: NotificationStore) {

    let currentNavigation = this.router.getCurrentNavigation();

    if (currentNavigation && currentNavigation.extras.state && currentNavigation.extras.state.navigatedFromTreatments) {

      this.navbarHidden = false;
      this.navigatedFromTreatments = true;
    }
  }

  ngOnInit() {

    if (false === this.navigatedFromTreatments) {

      // if navigation is not from dashboard show green loading screen
      this.loadingStore.show();

    } else {

      // navigated from dashboard, show grey loader
      this.loaderHidden = false;
    }

    this.isMobile = this.deviceService.isMobile();

    this.user = this.userStore.getUser();

    this.subscriptions.add(
      this.modalStore.state$.subscribe(status => this.modalActive = status)
    );

    this.subscriptions.add(

      this.dashboardStore.state$.subscribe(data => {

        if (data.action === 'initial') {

          this.dashboard = data;
          this.addExpiringNotifications(this.dashboard.expiring);

          let course_rank = 0;

          for (let chapter of this.dashboard.chapters) {

            for (let module of chapter.modules) {

              course_rank += 1;
              this.course_ranks[module.id] = course_rank;
            }
          }

          this.progress = this.getProgress();

          window.setTimeout(() => {

            this.loadingStore.hide();
            this.isLoaded = true;
            this.navbarHidden = false;

            if (true === this.navigatedFromTreatments) {

              // navigated from dashboard show grey loader
              this.loaderHidden = true;
            }

          }, 2000);
        }
      })
    );

    this.dashboardStore.fetch();

    this.apm.trackEvent(ApmEvents.DASHBOARD_START);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  addExpiringNotifications(expiring: ExpiringSubscription[]) {

    if (expiring.length === 0) {
      return;
    }

    let bundled = {};
    expiring.forEach(subscription => {

      if (!bundled[subscription.remaining_days]) {
        bundled[subscription.remaining_days] = [];
      }

      bundled[subscription.remaining_days].push(subscription.name);
    });

    let days = Object.keys(bundled);
    if (days.length === 1) {

      // only 1 day bundled
      let remaining_days = days[0];

      this.notificationStore.addExpiringNotification(`Over ${remaining_days} dag${parseInt(remaining_days, 0) === 1 ? '' : 'en'} verloopt uw toegang tot de cursus${bundled[remaining_days].length === 1 ? '' : 'en'} ${bundled[remaining_days].join(', ')}.`);

    } else {

      // multiple days bundled
      let messages = [];

      // adding first flag to make first word capitalized because typescript sucks and doesn't have capitalize function
      let first = true;

      for (let remaining_days in bundled) {

        let names = bundled[remaining_days];
        messages.push(`${first === true ? 'O' : 'o'}ver ${remaining_days} dag${parseInt(remaining_days, 0) === 1 ? '' : 'en'} verloopt uw toegang tot de cursus${names.length === 1 ? '' : 'sen'} ${names.join(', ')}`);

        first = false;
      }

      this.notificationStore.addExpiringNotification(messages.join(', ') + '.');
    }
  }

  getCourseLabel(course: CourseState) {
    return this.course_ranks[course.id] ? `${this.course_ranks[course.id]} - ${course.name}` : course.name;
  }

  getProgress() {

    let firstModule        = true;
    let previousModule     = null;
    let moduleProgressData = {modules: {}, items: {}};
    let userProgress       = this.dashboard.progress;
    let grades             = this.dashboard.grades;

    for (let chapter of this.dashboard.chapters) {

      for (let module of chapter.modules) {

        // setting chapter to locked by default
        // if this is the first chapter, unlock it
        moduleProgressData['modules'][module.id] = {

          classes: [firstModule === true ? 'menu-chapter--state-progress' : 'menu-chapter--state-locked'],
          percentage: 0,
          previous: previousModule,
          inProgress: firstModule === true,
          completed: false,
        };

        // no longer the first
        firstModule = false;

        // checking whether previous chapter is completed
        if (null !== previousModule && previousModule.completed === true) {

          // if it is complete, open this module
          moduleProgressData['modules'][module.id].classes    = ['menu-chapter--state-progress'];
          moduleProgressData['modules'][module.id].inProgress = true;
        }

        let moduleItems = this.getCourseModuleItems(module);
        let firstItem: boolean = true;

        // initializing module item classes
        for (let item of moduleItems) {

          let itemInProgress = true === moduleProgressData['modules'][module.id].inProgress && true === firstItem;

          moduleProgressData['items'][item.id] = {

            classes: [itemInProgress ? 'menu-chapter__button--ready' : 'menu-chapter__button--locked'],
            grade: null,
            inProgress: itemInProgress
          };

          // no longer first module item
          firstItem = false;
        }

        // checking user progress
        if (userProgress[module.course_id] && userProgress[module.course_id][module.id] && userProgress[module.course_id][module.id].done.length > 0) {

          // user has progress
          moduleProgressData['modules'][module.id].classes    = ['menu-chapter--state-progress'];
          moduleProgressData['modules'][module.id].inProgress = true;

          // calculating percentage
          let moduleProgress = userProgress[module.course_id][module.id];

          let percentage = (moduleProgress.done.length / moduleProgress.modules.length) * 100;
              percentage = percentage > 100 ? 100 : percentage;

          // saving percentage
          moduleProgressData['modules'][module.id].percentage = percentage;

          if (moduleProgress.done.length === moduleProgress.modules.length) {

            // module is done
            moduleProgressData['modules'][module.id].classes   = ['menu-chapter--state-complete'];
            moduleProgressData['modules'][module.id].completed = true;
          }

          let previousItemCompleted: boolean = false;

          // calculating module item classes
          for (let item of moduleItems) {

            // checking if previous item is completed
            if (true === previousItemCompleted) {

              // marking item as ready to be started
              moduleProgressData['items'][item.id]['classes']    = ['menu-chapter__button--ready'];
              moduleProgressData['items'][item.id]['inProgress'] = true;

              // and resetting
              previousItemCompleted = false;
            }

            // module item is complete
            if (userProgress[module.course_id][module.id].done.includes(item.id)) {

              // marking item as complete
              moduleProgressData['items'][item.id]['classes'] = ['menu-chapter__button--complete'];

              // marking this item (for the next loop seq) as completed
              previousItemCompleted = true;
            }

            // checking grade
            if (grades[item.id]) {

              moduleProgressData['items'][item.id]['classes'] = [`menu-chapter__button--${grades[item.id]['graduated'] ? 'score' : 'fail'}`];
              moduleProgressData['items'][item.id]['grade']   = grades[item.id]['grade'];
            }
          }
        }

        // saving module progress data for next
        previousModule = moduleProgressData['modules'][module.id];

        if (module.kind === CourseKind.LANDSCAPE) {
          moduleProgressData['modules'][module.id].classes.push('menu-chapter--wide');
        }
      }
    }

    return moduleProgressData;
  }

  getCourseModuleItems(course: CourseState): CourseModuleItemState[] {

    let modules = {};

    course.course_module_items.forEach(item => {

      if (item.item_type === 'Reader') {
        modules[0] = item;
      }

      if (item.item_type === 'Case') {
        modules[1] = item;
      }

      if (item.item_type === 'Quiz') {
        modules[2] = item;
      }

      if (item.item_type === 'Exam') {
        modules[3] = item;
      }
    });

    return Object.values(modules);
  }

  isChapterHoverEnabled(id: number) {

    if (this.progress && this.progress['modules'] && this.progress['modules'][id]) {

      let idx = this.progress['modules'][id].classes.findIndex((cls: string) => cls === 'menu-chapter--hover');
      return idx !== -1;
    }

    return false;
  }

  // toggling chapter for mobile
  toggleChapter(id: number) {

    if (false === this.isMobile) {

      // not mobile, so toggling chapter is not necessary
      return;
    }

    if (this.progress && this.progress['modules'] && this.progress['modules'][id]) {

      let idx = this.progress['modules'][id].classes.findIndex((cls: string) => cls === 'menu-chapter--hover');

      if (idx === -1) {

        // hover isn't enabled, enable it
        this.progress['modules'][id].classes.push('menu-chapter--hover');

      } else {

        // hover enabled, disabling it
        this.progress['modules'][id].classes.splice(idx, 1);
      }
    }
  }

  goToTreatments() {

    this.transitionService
      .navigate(['treatments'], {state: {navigatedFromDashboard: true}});
  }

  isModuleLocked(course_id: number, module_id: number) {
    return false === this.userIsAdmin() && (false === this.progress['modules'][course_id].inProgress || false === this.progress['items'][module_id].inProgress);
  }

  goToReader(course_id: number, course_module_item: { id: number, item_id: number, first_reader_page: number }) {

    if (true === this.isMobile && false === this.isChapterHoverEnabled(course_id)) {

      // on mobile and hover is disabled, ignore click
      return;
    }

    if (true === this.isModuleLocked(course_id, course_module_item.id)) {

      // locked
      return;
    }

    // navigate to reader
    this.navigateWithLoadingScreen('reader/' + course_module_item.item_id + '/' + course_module_item.first_reader_page);
  }

  goToQuiz(course_id: number, course_module_item: { id: number, item_id: number }) {

    if (true === this.isMobile && false === this.isChapterHoverEnabled(course_id)) {

      // on mobile and hover is disabled, ignore click
      return;
    }

    if (true === this.isModuleLocked(course_id, course_module_item.id)) {

      // locked
      return;
    }

    this.navigateWithLoadingScreen('quiz/' + course_module_item.item_id);
  }

  goToExam(course_id: number, course_module_item: { id: number, item_id: number }) {

    if (true === this.isMobile && false === this.isChapterHoverEnabled(course_id)) {

      // on mobile and hover is disabled, ignore click
      return;
    }

    if (true === this.isModuleLocked(course_id, course_module_item.id)) {

      // locked
      return;
    }

    this.navigateWithLoadingScreen('exam/' + course_module_item.item_id);
  }

  goToCase(course_id: number, course_module_item: { id: number, item_id: number }) {

    if (true === this.isMobile && false === this.isChapterHoverEnabled(course_id)) {

      // on mobile and hover is disabled, ignore click
      return;
    }

    if (true === this.isModuleLocked(course_id, course_module_item.id)) {

      // locked
      return;
    }

    this.navigateWithLoadingScreen('case/' + course_module_item.item_id);
  }

  navigateWithLoadingScreen(route: string) {

    // hiding navbar for transition
    this.navbarHidden = true;

    // show loader
    this.loadingStore.show();

    // adding timeout to allow fade out of dashboard
    window.setTimeout(() => {

      this.transitionService
        .navigate([route]);

    }, 2000);
  }

  goToAccount() {

    this.transitionService
      .navigate(['account']);
  }

  goToSettings() {

    this.transitionService
      .navigate(['settings']);
  }

  formatTime(hours: number, minutes: number) {

    var text    = '';

    if (hours !== 0) {
      text += hours + ' uur';
    }

    if (minutes !== 0) {
      text += (hours !== 0 ? ', ' : '') + minutes + ' min';
    }

    return text;
  }

  getBackgroundImageStyle(url: string) {

    if (!url) {
      return {};
    }

    return {
      'background-image': `url(${url})`
    };
  }

  userIsAdmin() {
    return this.user.roles.indexOf('admin') > 1;
  }

  dump(data: any) {
    // console.log(data);
  }

}
