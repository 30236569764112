import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';
import { QuizState } from "./states/quiz.state";
import { QuizService } from "./quiz.service";
import { QuestionState } from "./states/question.state";
import { AnswerState } from "./states/answer.state";
import { QuizValidatorService } from "./quiz_validator.service";

@Injectable()
export class QuizStore extends Store<QuizState> {

  constructor(private quizService: QuizService) {
    super(new QuizState());
  }

  fetch(id: number) {

    let fetch = this.quizService
      .fetch(id)
      .subscribe((data: any) => {

        fetch.unsubscribe();

        this.setState({

          ...this.state,
          action: 'initial',
          slide: -1,
          // slides:
          // data.quiz.questions.length * 2 = (questions + overview of your answers)
          // + 4 slides                     = introduction + confirmation + (failed or succeeded) + completion
          slides: (data.quiz.questions.length * 2) + 4,
          question: null,
          answers: [],
          result: null,
          resultQuestion: null,
          confirmed: false,
          quiz: data.quiz
        });
      });
  }

  activateIntroduction() {

    this.setState({

      ...this.state,
      action: 'activate-introduction',
      slide: 0,
      question: null
    });
  }

  activateQuestion(question: QuestionState) {

    this.setState({

      ...this.state,
      action: 'activate-question',
      slide: this.state.slide + 1,
      question: question
    });
  }

  activatePreviousQuestion(question: QuestionState) {

    this.setState({

      ...this.state,
      action: 'activate-question',
      slide: this.state.slide - 1,
      question: question
    });
  }

  activatePreviousResultQuestion(resultQuestion: QuestionState) {

    this.setState({

      ...this.state,
      action: 'activate-result-question',
      slide: this.state.slide - 1,
      resultQuestion: resultQuestion,
    });
  }

  activateNextResultQuestion(resultQuestion: QuestionState) {

    this.setState({

      ...this.state,
      action: 'activate-result-question',
      slide: this.state.slide + 1,
      resultQuestion: resultQuestion,
    });
  }

  goBackToQuiz() {

    let idx = this.state.quiz.questions.length - 1;

    this.setState({

      ...this.state,
      action: 'activate-question',
      slide: this.state.slide - 1,
      question: this.state.quiz.questions[idx],
      resultQuestion: null,
    });
  }

  startQuiz() {

    this.setState({

      ...this.state,
      action: 'activate-question',
      slide: this.state.slide + 1,
      question: this.state.quiz.questions[0]
    });
  }

  activateConfirmation() {

    this.setState({

      ...this.state,
      action: 'activate-confirmation',
      slide: this.state.slide + 1,
      question: null,
      confirmed: true,
    });
  }

  activateAnswers() {

    this.setState({

      ...this.state,
      action: 'activate-answers',
      slide: this.state.slide + 1,
      resultQuestion: this.state.quiz.questions[0],
    });
  }

  activateCompleted() {

    this.setState({

      ...this.state,
      action: 'activate-completed',
      slide: 3 + (this.state.quiz.questions.length * 2),
      resultQuestion: null,
    });
  }

  isAnswered(question: QuestionState) {

    let selectedAnswerIdx = this.state.answers.findIndex(selected => {
      return selected.question.id === question.id;
    });

    if (selectedAnswerIdx === -1) {
      return false;
    }

    return this.state.answers[selectedAnswerIdx].answers.length > 0;
  }

  markAnswer(question: QuestionState, answer: AnswerState) {

    let currentAnswers    = this.state.answers;
    let selectedAnswerIdx = currentAnswers.findIndex(selected => {
      return selected.question.id === question.id;
    });

    if (selectedAnswerIdx === -1) {

      // question not found
      currentAnswers.push({

        question: question,
        answers: [answer]
      });

    } else {

      let marked = currentAnswers[selectedAnswerIdx].answers.findIndex(selected => {
        return selected.id === answer.id;
      });

      if (marked === -1) {

        // comment next line if you want to enable multiple answers
        currentAnswers[selectedAnswerIdx].answers = [];

        // wasn't marked, let's mark it
        currentAnswers[selectedAnswerIdx].answers.push(answer);

      } else {

        // already marked, unmark it
        currentAnswers[selectedAnswerIdx].answers.splice(marked, 1);
      }
    }

    this.setState({

      ...this.state,
      action: 'mark-answer',
      answers: currentAnswers
    });
  }

  validateQuiz() {

    let validator = new QuizValidatorService(this.state);
    this.setState({

      ...this.state,
      action: 'quiz-validated',
      slide: this.state.slide + 1,
      result: validator.validate()
    });
  }
}
