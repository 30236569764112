import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() seconds: number;
  @Output() ticker  = new EventEmitter();
  @Output() done  = new EventEmitter();

  clock: number;

  constructor() {}

  ngOnInit() {
  }

  ngAfterViewInit() {

    this.clock = window.setInterval(() => {

      this.seconds -= 1;

      this.ticker.emit(this.seconds);
      if (this.seconds <= 0) {

        this.clearTimer();
        this.timerFinished();
      }

    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.clock);
  }

  clearTimer() {
    clearInterval(this.clock);
  }

  timerFinished() {
    this.done.emit();
  }
}
