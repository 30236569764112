import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends ApiService {

  authenticate(email: string, password: string) {
    return this.post('/sessions', {email, password});
  }

  checkSession(session_token: string) {
    return this.post('/sessions/check', {session_token: session_token});
  }
}
