import { Injectable, QueryList, ElementRef } from "@angular/core";
import { PageGalleryBlockState } from "./states/page_gallery_block.state";
import { GalleryItemState } from "./states/gallery_item.state";

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  private windowResizeHandler: number;

  setGalleryActiveFlags(gallery: PageGalleryBlockState, image: GalleryItemState) {

    gallery.images.forEach(image => {

      // if gallery has 2 images or less
      // then activate image, or else only activate
      // the clicked gallery image
      image.active = gallery.images.length <= 2;
    });

    image.active = true;
  }

  activateImage(element: HTMLDivElement) {

    let windowWidth  = $(window).width();
    let viewport     = windowWidth / 2;
    let $element     = $(element);
    let index        = $element.index();
    let $wrapper     = $element.parent();
    let $children    = $wrapper.children();
    let wrapperWidth = 0;

    // removing visible flag
    $children.removeClass('gallery-image-container--visible');

    for (let i = 0; i < $children.length; i++) {
      wrapperWidth += $($children[i]).outerWidth(true);
    }

    if (wrapperWidth < windowWidth) {

      // gallery items are less than size of window, center images
      let margin = ((windowWidth - wrapperWidth) / 2);

      // adding visible flag
      $children.addClass('gallery-image-container--visible');

      $wrapper.css({
        marginLeft: `${margin}px`
      });

      return;
    }

    let margin  = 0;
        margin -= $element.outerWidth(true) / 2;
        margin += viewport;

    // first image
    if (index === 0) {
      margin = 0;
    }

    // launches when 2nd till last image is clicked.
    for (let i = 0; i < index; i++) {
      margin -= $($children[i]).outerWidth(true);
    }

    // launches when last image is clicked.
    if (index === ($children.length - 1)) {

      margin += $element.outerWidth(true) / 2;
      margin -= $element.outerWidth(true);
      margin += viewport;
    }

    // sets active classes and animates wrapper.
    $wrapper.animate({
      marginLeft: margin + 'px'
    }, 500);
  }

  resetPositionGallery(galleryImageElements: QueryList<ElementRef>) {

    if (this.windowResizeHandler) {

      clearTimeout(this.windowResizeHandler);
      this.windowResizeHandler = null;
    }

    if (galleryImageElements.length === 0) {
      return;
    }

    this.windowResizeHandler = window.setTimeout(() => {

      let $element = $(galleryImageElements.first.nativeElement);
      let $wrapper = $element.parent();

      $wrapper.css({
        marginLeft: 0,
      });

    }, 0);
  }

  repositionActiveImage(galleryImageElements: QueryList<ElementRef>) {

    if (this.windowResizeHandler) {

      clearTimeout(this.windowResizeHandler);
      this.windowResizeHandler = null;
    }

    if (galleryImageElements.length === 0) {
      return;
    }

    this.windowResizeHandler = window.setTimeout(() => {

      galleryImageElements.forEach(galleryImageElement => {

        if (galleryImageElement.nativeElement.classList.contains('gallery-image-container--active')) {
          this.activateImage(galleryImageElement.nativeElement);
        }
      });

    }, 600);
  }
}
