import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';
import { CompanyAccountState } from "./company-account.state";
import { CompanyAccountService } from "./company-account.service";
import { UserStore } from "../shared/stores/user.store";
import { UserState } from "../shared/stores/states/user.state";
import { CompanyState } from "../shared/stores/states/company.state";

@Injectable()
export class CompanyAccountStore extends Store<CompanyAccountState> {

  constructor(private companyAccountService: CompanyAccountService, private userStore: UserStore) {
    super(new CompanyAccountState());
  }

  fetch() {

    let fetch = this.companyAccountService
      .fetch(this.userStore.getSessionToken())
      .subscribe((data: any) => {

        fetch.unsubscribe();
        this.setState({

          ...this.state,
          action: 'initial',
          company: data.company,
          user: data.user,
          errors: [],
          error_fields: [],
        });
      });
  }

  save(company: CompanyState, user: UserState) {

    let save = this.companyAccountService
      .update(company, user, this.userStore.getSessionToken())
      .subscribe((data: any) => {

        save.unsubscribe();

        this.userStore.setUser(data.user);
        this.setState({

          ...this.state,
          action: 'saved-company-account',
          errors: [],
          error_fields: [],
        });

      }, (error) => {

        save.unsubscribe();

        this.setState({

          ...this.state,
          action: 'error',
          errors: error.error.errors,
          error_fields: error.error.error_fields,
        });
      });
  }
}
