<div class="disable-pointer-events page-loading" [ngClass]="{'page-loading--hidden': showHiddenClass}">
  <div class="page-loading-wrapper">
    <div class="page-loading-animation">
      <div class="page-loading-animation__icon"></div>
      <svg class="page-loading-animation__circle" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" r="32" stroke="rgb(240, 240, 240)" stroke-width="6" fill="none"></circle>
        <circle class="page-loading-animation__circle__stroke" cx="50" cy="50" r="32" stroke="rgb(72, 220, 122)" stroke-dasharray="50 100" stroke-width="6" stroke-linecap="square" fill="none" transform="rotate(497.745 50 50)"></circle>
      </svg>
    </div>
    <div class="page-loading-animation__text"></div>
  </div>
</div>
