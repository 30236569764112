import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { UserState } from '../shared/stores/states/user.state';

@Injectable({
  providedIn: 'root'
})
export class NewUserService extends ApiService {

  check(new_user_token: string) {
    return this.get(`/v2/new_user/${new_user_token}`);
  }

  setNewUser(new_user_token: string, user: UserState, password: string, password_confirmation: string) {

    return this.post(`/v2/new_user/${new_user_token}`, {

      new_user: user,
      reset_password: {

        password,
        password_confirmation
      }
    });
  }
}
