import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { UserState } from '../shared/stores/states/user.state';
import { CompanyState } from '../shared/stores/states/company.state';

@Injectable({
  providedIn: 'root'
})
export class CompanyAccountService extends ApiService {

  fetch(session_token: string) {

    return this.get('/company/account', {
      session_token: session_token
    });
  }

  update(company: CompanyState, user: UserState, session_token: string) {

    return this.put('/company/account', {

      session_token: session_token,
      user: {

        title: user.title,
        initials: user.initials,
        last_name: user.last_name,
        phone: user.phone,
      },
      company: {

        name: company.name,
        street: company.street,
        housenumber: company.housenumber,
        housenumber_suffix: company.housenumber_suffix,
        postalcode: company.postalcode,
        city: company.city,
        country: company.country,
      }
    });
  }
}
