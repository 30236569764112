<section class="settings-main settings-account">
  <div class="settings-wrapper">
    <form class="settings-form">
      <div class="personalia-item" *ngIf="status == 'form'">
        <div class="personalia-item personalia-item--error" *ngIf="reset_password.errors.length > 0">
          <ul>
            <li *ngFor="let error of reset_password.errors">{{ error }}</li>
          </ul>
        </div>
        <div class="settings-form__item">
          <h2>Wachtwoord Instellen</h2>
          <h3>Vul hier een wachtwoord in waarmee u in wilt loggen.</h3>
        </div>
        <div class="settings-form__item settings-form__item--locked">
          <p>E-mailadres</p>
          <input type="text" placeholder="{{ reset_password.email }}" class="login-input" disabled>
        </div>
        <div class="settings-form__item" [class.settings-form__item--error]="reset_password.error_fields.includes('password')">
          <p>Wachtwoord*</p>
          <input type="password" placeholder="Vul hier uw nieuwe wachtwoord in" class="login-input" #password>
        </div>
        <div class="settings-form__item" [class.settings-form__item--error]="reset_password.error_fields.includes('password_confirmation')">
          <p>Herhaal wachtwoord*</p>
          <input type="password" placeholder="Vul hier opnieuw uw nieuwe wachtwoord in" class="login-input" #password_confirmation>
        </div>
        <div class="settings-form__item">
          <input type="submit" value="opslaan" (click)="resetPassword(password.value, password_confirmation.value)">
        </div>
      </div>
      <div class="personalia-item" *ngIf="status == 'success'">
        <div class="settings-form__item">
          <h2>Wachtwoord gewijzigd</h2>
          <h3>Uw wachtwoord is succesvol gewijzigd. Klik <a (click)="goToDashboard()">hier</a> om naar uw dashboard te gaan.</h3>
        </div>
      </div>
    </form>
  </div>
</section>
