import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { UserStore } from '../shared/stores/user.store';
import { ExamState } from './states/exam.state';

@Injectable({
  providedIn: 'root'
})
export class ExamService extends ApiService {

  constructor(protected http: HttpClient, private userStore: UserStore) {
    super(http);
  }

  fetch(id: number) {

    return this.get(`/v2/exams/${id}`, {
      session_token: this.userStore.getSessionToken(),
    });
  }

  createState(exam: ExamState) {

    let sessionToken = this.userStore.getSessionToken();
    let examState    = {...exam};
    examState.restoring_action = null;

    return this.post(`/v2/exam_state/${exam.exam.id}`, {

      session_token: sessionToken,
      exam_state: JSON.stringify(examState),
    });
  }

  commitState(exam: ExamState) {

    let sessionToken = this.userStore.getSessionToken();
    let examState    = {...exam};
    examState.restoring_action = null;

    return this.put(`/v2/exam_state/${exam.exam.id}`, {

      session_token: sessionToken,
      exam_state: JSON.stringify(examState),
    });
  }
}
