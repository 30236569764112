import { CompanyState } from "./company.state";
import { ProgressState } from "./progress.state";
import { SubscriptionState } from "./subscription.state";
import { NotificationState } from "./notification.state";

export class UserState {

  id: number;
  company: CompanyState;
  title: string;
  initials: string;
  full_name: string;
  last_name: string;
  birthday: Date;
  email: string;
  phone: string;
  registration_kind: string;
  registration_number: string = '';
  logged_in_at: Date;
  roles: string[];
  progress: ProgressState;
  grades: {
    [course_module_item_id: number]: number
  };
  subscriptions: SubscriptionState[];
  notifications: NotificationState[] = [];
  terms: boolean = false;
};
