import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CompanyStore } from './company.store';
import { CompanyState } from './company.state';
import { TransitionService } from '../shared/services/transition.service';
import { UserState } from '../shared/stores/states/user.state';
import { UserStore } from '../shared/stores/user.store';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  providers: [CompanyStore]
})
export class CompanyComponent implements OnInit, OnDestroy {

  public currentUser: UserState = null;
  public company: CompanyState        = null;
  public topMessageState: boolean     = false;

  private subscriptions: Subscription = new Subscription();

  constructor(private companyStore: CompanyStore, private userStore: UserStore, private transitionService: TransitionService) {}

  ngOnInit(): void {

    this.subscriptions.add(
      this.companyStore.state$.subscribe(data => {

        if (data.action === 'initial') {

          this.currentUser = this.userStore.getUser();
          this.company = data.company;
        }
      })
    );

    this.companyStore.fetch();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  goToDashboard() {

    return this.transitionService
      .navigate(['dashboard']);
  }

  goToCompanyAccount() {

    this.transitionService
      .navigate(['company/account']);
  }

  goToSettings() {

    this.transitionService
      .navigate(['settings']);
  }

  goToNewUserForm() {

    this.transitionService
      .navigate(['company/users/new']);
  }

  goToEditUserForm(user_id: number) {

    this.transitionService
      .navigate([`company/users/${user_id}`]);
  }

  toggleTopMessage() {

    let element = $(`[data-role="top-message"]`);

    if (false === this.topMessageState) {

      this.topMessageState = true;
      element.slideDown('slow');

    } else {

      this.topMessageState = false;
      element.slideUp('slow');
    }
  }

  p(d) {
    console.log(d);
  }
}
