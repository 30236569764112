import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';
import { CaseState } from "./states/case.state";
import { CaseService } from "./case.service";
import { AnswerState } from "./states/answer.state";

@Injectable()
export class CaseStore extends Store<CaseState> {

  constructor(private caseService: CaseService) {
    super(new CaseState());
  }

  fetch(id: number) {

    let fetch = this.caseService
      .fetch(id)
      .subscribe((data: any) => {

        fetch.unsubscribe();

        if (data.case && data.case.cases) {

          let total = data.case.cases.length;
          if (total > 0) {

            this.setState({

              ...this.state,
              action: 'initial',
              active: data.case.cases[0],
              progress: (1 / total) * 100,
              selected: [],
              correct: [],
              done: [],
              case: data.case,
            });
          }
        }
      });
  }

  getActiveCaseTitle() {

    if (null === this.state.active) {
      return '';
    }

    let idx = this.state.case.cases.findIndex(_case => {
      return _case.id === this.state.active.id;
    });

    if (idx === -1) {
      return '';
    }

    return `Casus nr. ${idx + 1}`;
  }

  toggleAnswer(answer: AnswerState) {

    let selected = [...this.state.selected];
    let found    = selected.findIndex(el => {
      return el.treatment.id === answer.treatment.id;
    });

    if (found > -1) {
      selected.splice(found, 1);
    } else {
      selected.push(answer);
    }

    this.setState({

      ...this.state,
      action: 'toggle-answer',
      selected: selected
    });
  }

  markActiveCaseAsDone() {

    let done = [...this.state.done];
    done.push(this.state.active.id);

    this.setState({

      ...this.state,
      action: 'add-done',
      selected: [],
      done: done
    });
  }

  markActiveCaseAsCorrect() {

    let correct = [...this.state.correct];
    correct.push(this.state.active.id);

    this.setState({

      ...this.state,
      action: 'add-correct',
      selected: [],
      correct: correct
    });
  }

  activateNextCase() {

    let done = [...this.state.done];

    if (this.state.done.length >= this.state.case.cases.length) {

      // all cases are done, copying only correct cases
      done = [...this.state.correct];
    }

    let nextCase = null;

    this.state.case.cases.forEach(_case => {

      if (null === nextCase && done.indexOf(_case.id) === -1 && this.state.correct.indexOf(_case.id) === -1) {
        nextCase = _case;
      }
    });

    if (null !== nextCase) {

      this.setState({

        ...this.state,
        action: 'activate-case',
        active: nextCase,
        selected: [],
        done: done,
      });
    }
  }
}
