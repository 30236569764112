import { Injectable } from "@angular/core";
import { Store } from '../shared/stores/store';
import { UserStore } from "../shared/stores/user.store";
import { UserState } from "../shared/stores/states/user.state";
import { CompanyEditUserState } from "./company-edit-user.state";
import { CompanyEditUserService } from "./company-edit-user.service";

@Injectable()
export class CompanyEditUserStore extends Store<CompanyEditUserState> {

  constructor(private companyEditUserService: CompanyEditUserService, private userStore: UserStore) {
    super(new CompanyEditUserState());
  }

  reset() {
    this.setState(new CompanyEditUserState());
  }

  fetch(user_id: number) {

    let fetch = this.companyEditUserService
      .fetch(user_id, this.userStore.getSessionToken())
      .subscribe((data: any) => {

        fetch.unsubscribe();
        this.setState({

          ...this.state,
          action: 'initial',
          user: data.user,
          subscriptions: data.subscriptions,
          credits: data.credits,
        });
      });
  }

  updateUser(company_edit_user: CompanyEditUserState) {

    let updateUser = this.companyEditUserService
      .updateUser(company_edit_user, this.userStore.getSessionToken())
      .subscribe((data: any) => {

        updateUser.unsubscribe();

        this.setState({

          ...this.state,
          action: 'user-updated',
          credits_left: data.credits_left,
        });

      }, (error) => {

        updateUser.unsubscribe();

        this.setState({

          ...this.state,
          action: 'user-error',
          errors: error.error.errors,
        });
      });
  }
}
